import React from "react";
import SwitchCard from "./SwitchCard";
import CustomButton from "@components/core-components/button/CustomButton";

function Marketing() {
  const [recieveSms, setRecieveSms] = React.useState(false);
  const [recieveEmail, setRecieveEmail] = React.useState(false);
  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="pt-0 text-white text-[15px]">MARKETING</p>

        <div className="flex py-[20px] flex-col">
          <SwitchCard
            title="Receive email offers from us"
            description="Choose if you wish to hear from us via email"
            isChecked={recieveEmail}
            onToggle={(checked) => setRecieveEmail(checked)}
            bgColor="bg-gray-400"
          />
          <SwitchCard
            title="Receive sms offers from us"
            description="Choose if you wish to hear from us via sms"
            isChecked={recieveSms}
            onToggle={(checked) => setRecieveSms(checked)}
            bgColor="bg-gray-500"
          />
        </div>
      </div>
      <div className="flex justify-end h-full gap-6w-full">
        <CustomButton
          classname="max-w-[175px]"
          text="Save"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default Marketing;
