import React from "react";
import UploadSection from "../components/UploadSection";
import Textfield from "@components/core-components/input/Textfield";

const uploadSections = [
  {
    title: "Upload Front Side",
    description: "Following file types are accepted: .png, .jpg, .pdf",
  },
  {
    title: "Upload Back Side",
    description: "Following file types are accepted: .png, .jpg, .pdf",
  },
];

const VerifyLevel2 = () => (
  <div className="lg:max-w-[740px]">
    <span className="text-white text-xl font-black">Level 2</span>
    <p className="text-gray-200 text-sm font-normal">
      Please upload your identification. This step will unlock more capabilities
      such as higher betting limits and enhanced account security.
    </p>
    <div className="w-full my-8">
      <Textfield type="text" placeholder="Document Type" id="document-type" />
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
      {uploadSections.map((section, index) => (
        <UploadSection
          key={index}
          title={section.title}
          description={section.description}
        />
      ))}
    </div>
    <p className="w-full mt-[26px] text-gray-200 text-sm font-normal">
      * Following file types are accepted: .png, .jpg, .pdf
    </p>
  </div>
);

export default VerifyLevel2;
