import React, { useState, useEffect } from "react";
import Modal from "@components/core-components/modal";
import CustomButton from "../button/CustomButton";
import Icon from "../icon";
import TabsComponent from "../TabsComponent";
import CustomSelect from "../input/CustomSelect";
import InputWithButton from "../input/InputWithButton";
import CustomInput from "../input/CustomInput";
import Textfield from "../input/Textfield";

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({ isOpen, onClose }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [activeTab, setActiveTab] = useState<string>("crypto");
  const [selectedNetwork, setSelectedNetwork] = useState<string>("ETH");
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [inputAmount, setInputAmount] = useState<string>("");
  const [step, setStep] = useState("form");
  const [emailCode, setEmailCode] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankBranchAddress, setBankBranchAddress] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");

  const currencies = [
    {
      id: 1,
      name: "BTC",
      fullName: "Bitcoin",
      amount: "54.2904748 BTC",
      equivalent: "$54.34 USD",
      icon: "btc24",
    },
    {
      id: 2,
      name: "INR",
      fullName: "Indian Rupee",
      amount: "12.00 INR",
      equivalent: "$0.14 USD",
      icon: "inr24",
    },
  ];

  const networkOptions = [
    { label: "ETH", value: "ETH" },
    { label: "BSC", value: "BSC" },
    { label: "TRX", value: "TRX" },
  ];

  const handleWithdrawClick = () => {
    setStep("confirmation");
  };

  const handleLocalWithdrawClick = () => {
    setShowDetails(true);
  };

  const handleAmountClick = (amount: number) => {
    setSelectedAmount(amount);
    setInputAmount(`€${amount.toLocaleString()}`);
  };

  const handleMaxButtonClick = () => {
    setInputAmount("54.2904748"); // Example total amount
  };

  useEffect(() => {
    if (!isOpen) {
      // Reset state when modal is closed
      setWalletAddress("");
      setActiveTab("crypto");
      setSelectedNetwork("ETH");
      setShowDetails(false);
      setSelectedAmount(null);
      setInputAmount("");
      setStep("form");
      setEmailCode("");
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-[540px]"
      title={
        <div className="flex justify-center items-center w-full">
          <span className="text-lg font-semibold text-white">WITHDRAW</span>
        </div>
      }
      content={
        <>
          <div className={"px-4 md:px-10 pt-5 w-full"}>
            <TabsComponent
              tabs={[
                { id: "crypto", label: "CRYPTO" },
                { id: "localCurrency", label: "LOCAL CURRENCY" },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              classNames="mb-4 bg-gray-900 text-body-reg-13 font-black h-12 p-2 rounded-lg gap-2"
              activeTabColor="bg-primary"
              buttonPaddingX="px-0"
            />
          </div>
          {activeTab === "crypto" ? (
            step === "form" ? (
              <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-4">
                <div className="flex flex-col gap-4">
                  {currencies
                    .filter((currency) => currency.id === 1)
                    .map((currency) => (
                      <div
                        key={currency.id}
                        className="flex justify-between items-center bg-gray-400 p-4 rounded-md"
                      >
                        <div className="flex items-center gap-4">
                          <Icon
                            iconName={currency.icon}
                            svgProps={{ width: 24, height: 24 }}
                          />
                          <div className="flex flex-col">
                            <span className="text-white font-bold text-sm">
                              {currency.name}
                            </span>
                            <span className="text-gray-200 text-xs font-normal">
                              {currency.fullName}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col text-right">
                          <span className="text-white font-bold text-sm">
                            {currency.amount}
                          </span>
                          <span className="text-gray-200 text-xs font-normal">
                            {currency.equivalent}
                          </span>
                        </div>
                      </div>
                    ))}
                  <div>
                    <label className="block text-gray-200 text-body-reg-13 mb-2">
                      Network
                    </label>
                    <CustomSelect
                      options={networkOptions}
                      value={selectedNetwork}
                      onChange={(value) => setSelectedNetwork(value)}
                      placeholder="Select Network"
                    />
                  </div>
                  <InputWithButton
                    label="Amount to withdraw*"
                    amount="$100.00"
                    inputValue={inputAmount}
                    onInputChange={setInputAmount}
                    buttonText="MAX"
                    onButtonClick={handleMaxButtonClick}
                    iconName="btc24"
                    placeholder="0.00000000"
                  />
                  <InputWithButton
                    label="Withdraw to*"
                    amount=""
                    inputValue={walletAddress}
                    onInputChange={setWalletAddress}
                    buttonIcon="copyWhite"
                    onButtonClick={() => {}}
                    placeholder="Enter wallet address"
                  />
                </div>
                <div className="text-xs text-gray-100 font-normal text-start">
                  Minimum withdrawal is 2.500000000 BTC. Your withdrawal will
                  have 1.00000000 BTC subtracted from your remaining balance to
                  cover the fee required to process the transaction.
                </div>
                <CustomButton
                  text="Withdraw"
                  buttonType="primary"
                  onClick={handleWithdrawClick}
                />
              </div>
            ) : (
              <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-5">
                <InputWithButton
                  label="Email Code*"
                  amount=""
                  inputValue={emailCode}
                  onInputChange={setEmailCode}
                  buttonIcon="copyWhite"
                  onButtonClick={() => {}}
                  placeholder="Email Code"
                />
                <CustomButton text="Submit" buttonType="primary" />
                <button
                  className="text-sm text-white mt-9 underline font-semibold"
                  onClick={() => alert("Code sent")}
                >
                  Request a New Code
                </button>
              </div>
            )
          ) : (
            <>
              {showDetails ? (
                <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-4">
                  <div className="text-gray-100 text-base font-semibold text-left my-2">
                    Bank Account (INR)
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {[500, 1000, 2500, 5000, 10000, 50000].map((amount) => (
                      <button
                        key={amount}
                        onClick={() => handleAmountClick(amount)}
                        className={`w-[70px] h-[30px] text-xs font-semibold rounded-lg items-center justify-center ${
                          selectedAmount === amount
                            ? "bg-yellow text-gray-600"
                            : "bg-gray-400 text-white hover:bg-gray-500"
                        }`}
                      >
                        €{amount.toLocaleString()}
                      </button>
                    ))}
                  </div>
                  <div>
                    <Textfield
                      label="Enter amount"
                      value={inputAmount}
                      onChange={(e) => setInputAmount(e.target.value)}
                      placeholder="Enter amount"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <Textfield
                      label="IFSC Code"
                      value={ifscCode}
                      onChange={(e) => setIfscCode(e.target.value)}
                      placeholder="IFSC Code"
                    />
                    <Textfield
                      label="Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      placeholder="Account Number"
                    />
                    <Textfield
                      label="Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      placeholder="Bank Name"
                    />
                    <Textfield
                      label="Bank Branch Address"
                      value={bankBranchAddress}
                      onChange={(e) => setBankBranchAddress(e.target.value)}
                      placeholder="Bank Branch Address"
                    />
                  </div>
                  <Textfield
                    label="Bank Branch Name"
                    value={bankBranchName}
                    onChange={(e) => setBankBranchName(e.target.value)}
                    placeholder="Bank Branch Name"
                  />
                  <div className="text-body-reg-13 text-gray-200 text-start font-normal">
                    You can withdraw between €200 and €100,000.
                  </div>
                  <CustomButton
                    text="Set Amount"
                    buttonType="primary"
                    onClick={() => {}}
                  />
                </div>
              ) : (
                <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-4">
                  <div className="flex flex-row gap-4 items-start justify-start bg-gray-700 rounded-md p-5">
                    <Icon
                      iconName="warning"
                      svgProps={{ width: 18, height: 18 }}
                    />
                    <span className="text-gray-200 text-sm font-normal">
                      For INR withdrawals, you must use a bank account in your
                      personal name. Attempting to withdraw through another bank
                      account not in your name can led to INR withdrawals being
                      revoked.
                    </span>
                  </div>
                  {currencies
                    .filter((currency) => currency.id === 2)
                    .map((currency) => (
                      <div
                        key={currency.id}
                        className="flex justify-between items-center bg-gray-400 p-4 rounded-md"
                      >
                        <div className="flex items-center gap-4">
                          <Icon
                            iconName={currency.icon}
                            svgProps={{ width: 24, height: 24 }}
                          />
                          <div className="flex flex-col">
                            <span className="text-white font-bold text-sm">
                              {currency.name}
                            </span>
                            <span className="text-gray-200 text-xs font-normal">
                              {currency.fullName}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col text-right">
                          <span className="text-white font-bold text-sm">
                            {currency.amount}
                          </span>
                          <span className="text-gray-200 text-xs font-normal">
                            {currency.equivalent}
                          </span>
                        </div>
                      </div>
                    ))}
                  <CustomButton
                    text="Withdraw"
                    buttonType="primary"
                    onClick={handleLocalWithdrawClick}
                  />
                </div>
              )}
            </>
          )}
          <div className="flex flex-col gap-6 px-4 md:px-10 pt-9">
            <div className="text-xs text-gray-200 font-semibold text-center">
              Improve your account security with Two-Factor Authentication
            </div>
            <CustomButton text="Enable 2FA" buttonType="secondary" />
          </div>
        </>
      }
    />
  );
};

export default WithdrawModal;
