import React, { useState, useEffect } from "react";
import TabsComponent from "@components/core-components/TabsComponent";
import RegisterForm from "@components/auth-components/RegisterForm";
import ConfirmForm from "@components/auth-components/ConfirmForm";
import ForgotPasswordForm from "@components/auth-components/ForgotPassword";
import LoginForm from "@components/auth-components/LoginForm";
import Modal from "./modal";
import Icon from "./icon";
import { useDispatch, useSelector } from "react-redux";
import { setInitialWindow, setOpenModal } from "store/auth/authenticationSlice";

const AuthModal: React.FC = () => {
  const initialWindow = useSelector((state: any) => state.auth.initialWindow);

  const isOpen = useSelector((state: any) => state.auth.openModal);
  const dispatch = useDispatch();
  const [registerEmail, setRegisterEmail] = useState<string | null>(null);
  const onClose = () => {
    dispatch(setInitialWindow("signIn"));
    dispatch(setOpenModal(false));
  };
  useEffect(() => {
    dispatch(setInitialWindow(initialWindow));
  }, [initialWindow]);

  const renderContent = () => {
    switch (initialWindow) {
      case "register":
        return (
          <RegisterForm
            onSuccess={(email) => {
              setRegisterEmail(email);
              dispatch(setInitialWindow("confirm"));
            }}
          />
        );
      case "signIn":
        return (
          <LoginForm
            onClose={onClose}
            onForgotPassword={() =>
              dispatch(setInitialWindow("forgotPassword"))
            }
          />
        );
      case "confirm":
        return (
          <div className="py-4">
            {" "}
            <ConfirmForm
              email={registerEmail || ""}
              onSuccess={onClose}
              onBack={() => dispatch(setInitialWindow("register"))}
            />
          </div>
        );
      case "forgotPassword":
        return (
          <ForgotPasswordForm
            onBack={() => dispatch(setInitialWindow("signIn"))}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex flex-row gap-1 justify-center items-center w-full max-w-[484px]">
          <Icon
            iconName="pixupYellowLogo"
            svgProps={{ width: 34, height: 23 }}
            className="w-[34px] h-[23px] justify-start"
          />
          <Icon
            iconName="pixupClientText"
            svgProps={{ width: 85, height: 14 }}
            className="w-[85px] h-[14px] justify-start"
          />
        </div>
      }
      content={
        <>
          {initialWindow !== "forgotPassword" &&
            initialWindow !== "confirm" && (
              <div className={"px-4 md:px-6 pt-5 w-full md:w-[484px]"}>
                <TabsComponent
                  tabs={[
                    { id: "register", label: "CREATE AN ACCOUNT" },
                    { id: "signIn", label: "SIGN IN" },
                  ]}
                  classNames="mb-4 bg-gray-900 h-12 p-2 rounded-lg gap-2"
                  activeTabColor="bg-primary"
                  activeTab={initialWindow}
                  setActiveTab={(tab) => dispatch(setInitialWindow(tab))}
                  buttonPaddingX="px-0"
                />
              </div>
            )}
          <div className={"px-4 md:px-6 w-full md:w-[484px]"}>
            {renderContent()}
          </div>
        </>
      }
    />
  );
};

export default AuthModal;
