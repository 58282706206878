import CustomButton from "@components/core-components/button/CustomButton";
import { useState } from "react";
import LandingPageBgImg from "../../../assets/images/desktop-slider-bg.avif";
import Games from "../../../assets/images/desktop-slider-games.avif";
import BasketballPlayer from "../../../assets/images/desktop-slider-basket.avif";
import Icon from "@components/core-components/icon";
import Woman from "../../../assets/images/girl.avif";
import SportsbookFootballer from "../../../assets/images/sportsbook-player.avif";
import Exchange from "../../../assets/images/exchange-players.avif";
import CategoryCard from "./components/CategoryCard";
import Crypto from "../../../assets/images/crypto-01.avif";
import CryptoBitcoin from "../../../assets/images/crypto-02.avif";
import TabsComponent from "@components/core-components/TabsComponent";
import MobileCasinoTable from "pages/private/casino/components/MobileCasinoTable";
import CasinoTable from "pages/private/casino/components/CasinoTable";
import QuestionItem from "@components/QuestionItem";
import { useDispatch } from "react-redux";
import { setInitialWindow } from "store/auth/authenticationSlice";

function LandingPage() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("casino");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const openRegisterModal = () => {
    dispatch(setInitialWindow("register"));
  };

  const tabs = [
    { id: "casino", label: "CASINO" },
    { id: "sports", label: "SPORTS" },
  ];
  const brandIcons = [
    { name: "drake", width: 100, height: 14 },
    { name: "ufc", width: 78, height: 26 },
    { name: "billeJean", width: 100, height: 43 },
    { name: "juventude", width: 49, height: 56 },
    { name: "teamSecret", width: 70, height: 34 },
    { name: "davisCup", width: 100, height: 38 },
    { name: "everton", width: 55, height: 56 },
  ];

  const cardsData = [
    {
      iconName: "bonusBoost",
      svgProps: { width: 46, height: 52 },
      title: "Bonus Boosts",
      description:
        "Weekly boosts and monthly bonuses mean you’ll get rewarded the more you play top casino games and bet on your favourite sports.",
    },
    {
      iconName: "ticket",
      svgProps: { width: 48, height: 35 },
      title: "Raffles & Races",
      description:
        "Get in the draw to win 75K each week. Plus score big on daily races.",
    },
    {
      iconName: "promotions",
      svgProps: { width: 41, height: 43 },
      title: "Promotions",
      description:
        "Make the most from our casino promotions and sports betting bonuses which are updated weekly.",
    },
    {
      iconName: "dart",
      svgProps: { width: 45, height: 45 },
      title: "High Returns-to-Player",
      description:
        "With enhanced RTP percentages, you stand a chance to win big every time you play.",
    },
  ];

  const questionsData = [
    {
      question: "Who is Pixup?",
      answer:
        "Leading the online gambling industry since 2017, Stake.com offers a wide variety of online casino and sports betting options, operating globally in 15 different languages. With a reputable and secure platform, Pixup Casino is home to worldwide local currencies and crypto betting options for online slot games, Pixup Originals and live casino games. Pixup Sportsbook offers unbeatable odds on all major sporting events including a range of eSport leagues.",
    },
    {
      question: "Is Pixup licensed?",
      answer:
        "Yes, Pixup is licensed and operates under international regulations. Our platform is secure and trusted by players worldwide. Pixup is also a proud member of the Crypto Gambling Foundation.",
    },
    {
      question: "Is betting on Pixup safe?",
      answer:
        "Yes, Pixup uses advanced encryption and security protocols to ensure a safe betting experience. Our platform is licensed and regulated, providing a secure environment for all players. Pixup is also a proud member of the Crypto Gambling Foundation.",
    },
    {
      question: "What currencies can I bet with?",
      answer:
        "You can bet with USD, EUR, GBP, BTC, and several other cryptocurrencies. We also offer betting in local currencies. Please check our terms and conditions for the full list of supported currencies.",
    },
    {
      question: "What types of casino games can I play?",
      answer:
        "You can enjoy online slot games, live casino games, table games, and eSports betting options. Pixup offers a wide variety of games for all types of players. Check out our casino games page for more information.",
    },
  ];

  return (
    <div className="px-3 lg:px-6 xl:px-[60px]">
      <section
        className="relative lg:mt-6 mb-[37px] w-screen lg:w-full h-auto md:min-h-[604px] min-h-[562px] max-h-[604px] bg-cover bg-center bg-no-repeat flex items-center justify-center overflow-hidden -mx-3 lg:-mx-0"
        style={{
          backgroundImage: `url(${LandingPageBgImg})`,
        }}
      >
        <div className="absolute -left-52 md:-left-32 lg:left-10 top-1/2 transform -translate-y-1/2">
          <img
            src={Games}
            alt="Casino Games"
            className="w-auto md:w-auto md:h-[600px] lg:w-[500px] h-[550px] object-bottom"
          />
        </div>
        <div className="flex flex-col w-[90%] md:w-[50%] lg:max-w-[850px] h-auto py-12 px-6 bg-gray-600 bg-opacity-85 rounded-[10px] z-10">
          <div className="text-white text-center flex flex-col items-center gap-6">
            <span className="text-2xl md:text-3xl 2xl:text-heading-50 font-black leading-tight">
              AN UNRIVALLED ONLINE EXCHANGE, CASINO & SPORTSBOOK
            </span>
            <p className="text-sm 2xl:text-body-reg-15 font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum
              vestibulum tellus ut auctor.
            </p>
            <div className="flex w-[200px] md:w-[283px]">
              <CustomButton
                text="Register Now"
                onClick={openRegisterModal}
                buttonType="primary"
                height="h-[50px]"
              />
            </div>
          </div>
        </div>
        <div className="absolute -right-44 md:-right-32 lg:right-4 -top-1/3 md:top-12 lg:top-20 transform translate-y-1/2 md:translate-y-0">
          <img
            src={BasketballPlayer}
            alt="Basketball Player"
            className="w-auto md:w-auto md:h-[600px] lg:w-[440px] h-[550px] object-bottom"
          />
        </div>
      </section>
      <section className="bg-gray-600 py-3 -mx-3 md:-mx-6 lg:-mx-[60px]">
        <div className="overflow-hidden relative max-w-7xl justify-center items-center self-center mx-auto">
          <div className="flex animate-marqueeMobile sm:animate-marqueeMobile md:animate-marqueeTablet lg:animate-marquee whitespace-nowrap">
            {brandIcons.map((brand, index) => (
              <div
                key={`brand-${index}`}
                className="w-full min-w-[150px] max-w-[200px] h-[94px] flex justify-center items-center bg-gray-700 rounded-lg mx-2"
              >
                <Icon
                  iconName={brand.name}
                  svgProps={{ width: brand.width, height: brand.height }}
                  className="object-contain m-3"
                />
              </div>
            ))}
            {/* For infinite effect */}
            {brandIcons.map((brand, index) => (
              <div
                key={`brand-duplicate-${index}`}
                className="w-full min-w-[150px] max-w-[200px] h-[94px] flex justify-center items-center bg-gray-700 rounded-lg mx-2"
              >
                <Icon
                  iconName={brand.name}
                  svgProps={{ width: brand.width, height: brand.height }}
                  className="object-contain m-3"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-[69px] mt-[67px]">
        <CategoryCard
          bgGradient="bg-custom-gradient-purple-to-pink"
          title="Spin, Play, and Win: The Ultimate Casino Adventure Awaits"
          description="This motto invites players to experience a thrilling and rewarding journey in the casino, highlighting the excitement and possibility of big wins."
          buttonText="Learn More"
          imageSrc={Woman}
          imageAlt="casino"
        />
        <CategoryCard
          bgGradient="bg-custom-gradient-blue"
          title="Bet Smarter, Win Bigger: Your Sportsbook, Your Victory"
          description="Focused on sports enthusiasts, this motto emphasizes strategic betting and the excitement of victory, encouraging users to engage with confidence."
          buttonText="Learn More"
          imageSrc={SportsbookFootballer}
          imageAlt="sportbook"
        />
        <CategoryCard
          bgGradient="bg-custom-gradient-green"
          title="Trade the Odds: Your Exchange, Your Game"
          description="This motto highlights the dynamic nature of the exchange service, drawing attention to the unique aspect of trading odds, empowering users to control their betting outcomes like a market."
          buttonText="Learn More"
          imageSrc={Exchange}
          imageAlt="exchange"
        />
      </section>
      <section className="flex flex-col gap-12 mt-[147px] px-0 2xl:px-20">
        <div className="container flex items-center justify-start">
          <span className="text-3xl 2xl:text-6xl font-black leading-tight text-white max-w-6xl">
            ENJOY THE BEST ODDS WITH UP TO 99.5% RETURN-TO-PLAYER GAMES
          </span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 gap-4">
          {cardsData.map((card, index) => (
            <div
              key={index}
              className="flex flex-col w-full h-auto bg-gray-500 rounded-lg justify-start items-center px-6 py-7"
            >
              <div className="flex w-[103px] h-[103px] bg-gray-700 rounded-full justify-center items-center border-2 border-yellow mb-6">
                <Icon iconName={card.iconName} svgProps={card.svgProps} />
              </div>
              <span className="text-white text-2xl font-black mb-4">
                {card.title}
              </span>
              <p className="text-gray-200 text-base font-normal text-center">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className="flex flex-col lg:flex-row mt-[147px] lg:-mx-[60px] gap-1">
        <img
          src={Crypto}
          alt="crypto"
          className="w-full 2xl:w-[701px] h-auto max-h-[500px] md:max-h-[650px] lg:max-h-[705px] md:-mx-6 -mx-3 object-cover"
        />
        <div className="container flex flex-col items-start justify-start max-w-4xl">
          <span className="text-3xl 2xl:text-6xl font-black leading-tight text-white">
            PEACE OF MIND ACROSS MULTIPLE CRYPTO AND LOCAL CURRENCIES
          </span>
          <p className="text-gray-200 text-base font-normal mt-6">
            Experience safe deposits and instant withdrawals across 20 crypto
            currencies and 7 local currencies. All with no minimums, flexible
            payment methods and a secure online vault.
          </p>
          <img
            src={CryptoBitcoin}
            alt="bitcoin"
            className="w-full h-full max-w-[864px] max-h-[481px] mt-[72px] lg:min-h-[437px] object-cover"
          />
        </div>
      </section>
      <section className="flex flex-col mt-[106px] px-0 2xl:px-20">
        <span className="text-3xl 2xl:text-6xl font-black leading-tight text-white mt-6">
          WHAT THE BETTING ACTION HAPPENING RIGHT NOW
        </span>
        <p className="text-gray-200 text-base font-normal mt-6">
          Check out the live bets on popular casino games and sports to discover
          how much you could win.
        </p>
        <div className="hidden md:block">
          <TabsComponent
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            classNames={`bg-[#1D243E] rounded-lg p-2 w-full max-w-[752px] h-12 mt-8 font-black gap-1`}
            buttonWidth={"w-full"}
            activeTabColor="bg-primary"
          />
        </div>
        <div className="bg-gray-600 rounded-xl mt-11">
          <div className="md:pt-4 2xl:pt-10 pb-0 w-full">
            <div className="block md:hidden">
              <MobileCasinoTable games={[]} showHeader={true} />
            </div>
            <div className="hidden md:block">
              <CasinoTable showHeader={false} />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row mt-[113px] px-0 2xl:px-20 justify-between gap-6">
        <div className="flex flex-col items-start justify-start w-full lg:w-[45%]">
          <span className="text-3xl 2xl:text-heading-50 font-black leading-tight text-white">
            Still have questions?
          </span>
          <p className="text-gray-200 text-base font-normal mt-4 max-w-xl">
            From beginners and casual players to high rollers and expert
            players, our community love to support one another. Get tips and
            tricks through our online chat, forums and more.
          </p>
          <div className="flex mt-6 w-full max-w-[300px]">
            <CustomButton
              text="Read Our Guide"
              onClick={() => console.log("Join the Community")}
              buttonType="primary"
              height="h-[50px]"
            />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-[50%]">
          {questionsData.map((item, index) => (
            <QuestionItem
              key={index}
              question={item.question}
              answer={item.answer}
              isActive={activeIndex === index}
              onClick={() =>
                setActiveIndex(activeIndex === index ? null : index)
              }
            />
          ))}
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
