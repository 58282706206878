import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "./private";
import Game from "./private/casino/components/Game";
import Main from "./private/casino/components/Main";
import { useAppSelector } from "hooks/redux";
import GamePlay from "./private/casino/pages/gamePlay";
import LandingPage from "./public/landing";
import PublicLayout from "./public";
import { Helmet } from "react-helmet";
import logoYellow from "../assets/images/logo-yellow.png";
import LandingPrivate from "./private/landing";
import VipClub from "./public/vip";
import Promotions from "./public/promotions";
import ResponsibleGamings from "./public/responsibleGaming";
import Sponsorships from "./public/sponsorships";
import MyBets from "./private/casino/pages/myBets";
import CategoryDetails from "./public/categoryDetails";
import Settings from "./private/profile";
import LoadingOverlay from "@components/layout-components/LoadingOverlay";
import Exchange from "./public/exchange";
import GameInformation from "./public/exchange/pages/GameInformation";

const PrivateRoute = () => {
  const { isAuthenticated, loading } = useAppSelector((state) => state.auth);

  if (loading) {
    return <LoadingOverlay />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
function Pages() {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return (
    <>
      <Helmet>
        <title>Pixupplay</title>
        <link rel="icon" type="image/png" href={logoYellow} />
      </Helmet>
      <Routes>
        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route
            path="/"
            element={isAuthenticated ? <LandingPrivate /> : <LandingPage />}
          />
          <Route path="/lounge" element={<Main />} />
          <Route path="/lounge/game/:gameId/:gameName" element={<Game />} />
          <Route path="/lounge/:categoryName/*" element={<CategoryDetails />} />
          <Route path="/exchange/" element={<Exchange />} />
          <Route path="/exchange/info" element={<GameInformation />} />
          <Route path="/vip-club" element={<VipClub />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route
            path="/responsible-gambling"
            element={<ResponsibleGamings />}
          />
          <Route path="/sponsorship" element={<Sponsorships />} />
          <Route
            path="*"
            element={isAuthenticated ? <LandingPrivate /> : <LandingPage />}
          />
        </Route>
        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<PrivateLayout />}>
            <Route path="/settings" element={<Settings />} />
            <Route
              path="/lounge/game/:gameId/:gameName/play"
              element={<GamePlay />}
            />
            <Route path="/my-bets" element={<MyBets />} />
            <Route
              path="/lounge/:categoryName/*"
              element={<CategoryDetails />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default Pages;
