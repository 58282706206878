import React, { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Textfield from "@components/core-components/input/Textfield";
import SelectInput from "@components/core-components/input/SelectInput";
import DatePicker from "@components/core-components/input/DatePicker";

interface FormValues {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  residentialAddress: string;
  occupation: string;
  country: string;
  occupationIndustry: string;
  placeOfBirth: string;
  city: string;
}

const VerifyLevel1 = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const countryOptions = [
    { label: "Turkiye", value: "Turkiye" },
    { label: "United States", value: "USA" },
    { label: "United Kingdom", value: "UK" },
    { label: "Germany", value: "Germany" },
    { label: "France", value: "France" },
    { label: "India", value: "India" },
  ];

  const placeOfBirthOptions = [
    { label: "Istanbul", value: "Istanbul" },
    { label: "Ankara", value: "Ankara" },
    { label: "Izmir", value: "Izmir" },
    { label: "Antalya", value: "Antalya" },
    { label: "Bursa", value: "Bursa" },
  ];

  const cityOptions = [
    { label: "Istanbul", value: "Istanbul" },
    { label: "Ankara", value: "Ankara" },
    { label: "Izmir", value: "Izmir" },
    { label: "Antalya", value: "Antalya" },
    { label: "Bursa", value: "Bursa" },
  ];

  const occupationIndustryOptions = [
    { label: "Technology", value: "Technology" },
    { label: "Finance", value: "Finance" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Education", value: "Education" },
    { label: "Real Estate", value: "Real Estate" },
  ];

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };

  return (
    <div>
      <span className="text-white text-xl font-black">Level 1</span>
      <p className="text-gray-200 text-sm font-normal">
        Please fill in your details & confirm your identity to unlock additional
        services. All information is private & secure.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-8"
      >
        <Textfield
          type="text"
          placeholder="First name*"
          id="first-name"
          register={register}
          watch={watch}
          error={errors.firstName}
          required
        />
        <Textfield
          type="text"
          placeholder="Last name*"
          id="last-name"
          register={register}
          watch={watch}
          error={errors.lastName}
          required
        />
        <Controller
          name="country"
          control={control}
          defaultValue=""
          rules={{ required: "Country selection is required" }}
          render={({ field }) => (
            <SelectInput
              options={countryOptions}
              value={field.value}
              onChange={field.onChange}
              id="country"
              placeholder="Select a Country"
              background="bg-white"
              color="text-gray-900"
            />
          )}
        />
        <Controller
          name="placeOfBirth"
          control={control}
          defaultValue=""
          rules={{ required: "Place of Birth selection is required" }}
          render={({ field }) => (
            <SelectInput
              options={placeOfBirthOptions}
              value={field.value}
              onChange={field.onChange}
              placeholder="Place of Birth*"
              id="place-of-birth"
              background="bg-white"
              color="text-gray-900"
            />
          )}
        />
        <Controller
          name="dateOfBirth"
          control={control}
          defaultValue=""
          rules={{ required: "Date of Birth is required" }}
          render={() => (
            <DatePicker placeholder="Date of Birth*" maxDate={new Date()} />
          )}
        />
        <Textfield
          type="text"
          placeholder="Residential Address*"
          id="residential-address"
          register={register}
          watch={watch}
          error={errors.residentialAddress}
          required
        />
        <Controller
          name="city"
          control={control}
          defaultValue=""
          rules={{ required: "City selection is required" }}
          render={({ field }) => (
            <SelectInput
              options={cityOptions}
              id="city"
              value={field.value}
              onChange={field.onChange}
              placeholder="City*"
              background="bg-white"
              color="text-gray-900"
            />
          )}
        />
        <Controller
          name="occupationIndustry"
          control={control}
          defaultValue=""
          rules={{ required: "Occupation Industry selection is required" }}
          render={({ field }) => (
            <SelectInput
              options={occupationIndustryOptions}
              value={field.value}
              id="occupation-industry"
              onChange={field.onChange}
              placeholder="Occupation Industry*"
              background="bg-white"
              color="text-gray-900"
            />
          )}
        />
        <Textfield
          type="text"
          placeholder="Occupation*"
          id="occupation"
          register={register}
          watch={watch}
          error={errors.occupation}
          required
        />
      </form>
    </div>
  );
};

export default VerifyLevel1;
