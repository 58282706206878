import React from "react";

interface CustomSwitchProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ isChecked, onChange }) => {
  const handleToggle = () => {
    onChange(!isChecked);
  };

  return (
    <div
      className="relative pr-[22px] flex items-center justify-center cursor-pointer"
      onClick={handleToggle}
    >
      {isChecked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="46"
          viewBox="0 0 46 46"
          className="absolute"
        >
          <path
            d="M31.6248 9.58301H14.3748C7.494 9.58301 1.9165 15.1605 1.9165 22.0413C1.9165 28.9222 7.494 34.4997 14.3748 34.4997H31.6248C38.5057 34.4997 44.0832 28.9222 44.0832 22.0413C44.0832 15.1605 38.5057 9.58301 31.6248 9.58301Z"
            fill="#17C653"
          />
          <path
            d="M31.6252 32.5833C37.4472 32.5833 42.1668 27.8637 42.1668 22.0417C42.1668 16.2197 37.4472 11.5 31.6252 11.5C25.8032 11.5 21.0835 16.2197 21.0835 22.0417C21.0835 27.8637 25.8032 32.5833 31.6252 32.5833Z"
            fill="#E8F5E9"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="46"
          viewBox="0 0 46 46"
          className="absolute"
        >
          <path
            d="M14.3752 9.58301H31.6252C38.506 9.58301 44.0835 15.1605 44.0835 22.0413C44.0835 28.9222 38.506 34.4997 31.6252 34.4997H14.3752C7.49433 34.4997 1.91683 28.9222 1.91683 22.0413C1.91683 15.1605 7.49433 9.58301 14.3752 9.58301Z"
            fill="#525C7C"
          />
          <path
            d="M14.3748 32.5833C8.55284 32.5833 3.83317 27.8637 3.83317 22.0417C3.83317 16.2197 8.55284 11.5 14.3748 11.5C20.1968 11.5 24.9165 16.2197 24.9165 22.0417C24.9165 27.8637 20.1968 32.5833 14.3748 32.5833Z"
            fill="#E8F5E9"
          />
        </svg>
      )}
    </div>
  );
};

export default CustomSwitch;
