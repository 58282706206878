import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseService } from "api/core/baseService";
import { RootState } from "store";

export interface GameResponse {
  category: string;
  provider_name: string;
  game_id: string;
  game_name: string;
  sub_provider_name: string;
  status: string;
  url_thumb: string;
  game_code: string;
  id: string;
}

export interface LayoutSettings {
  mobileDepositOpened: boolean;
  mobileGameStarted: boolean;
  isLandscape: boolean;
}

interface casinoState {
  games: GameResponse[];
  layoutSettings: LayoutSettings;
  loading: boolean;
  error: string | null;
}

const initialState: casinoState = {
  games: [],
  layoutSettings: {
    mobileDepositOpened: false,
    mobileGameStarted: false,
    isLandscape: false,
  },
  loading: false,
  error: null,
};

export const fetchGames = createAsyncThunk<GameResponse[]>(
  "players/games",
  async (_, { rejectWithValue }) => {
    try {
      const res = await baseService.getAll<GameResponse[]>("players/games");
      //this game does not have a thumbnail
      const filtered = res.filter((game) => game.game_name !== "Mega Fishing");
      return filtered;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch games");
    }
  }
);

export const casinoSlice = createSlice({
  name: "casino",
  initialState,
  reducers: {
    setMobileDeposOpened: (state, action) => {
      state.layoutSettings.mobileDepositOpened = action.payload;
    },
    setMobileGameStarted: (state, action) => {
      state.layoutSettings.mobileGameStarted = action.payload;
    },
    setIsMobileLandScape: (state, action) => {
      state.layoutSettings.isLandscape = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchGames.fulfilled,
        (state, action: PayloadAction<GameResponse[]>) => {
          state.loading = false;
          state.games = action.payload;
        }
      )
      .addCase(fetchGames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  setMobileDeposOpened,
  setMobileGameStarted,
  setIsMobileLandScape,
} = casinoSlice.actions;
export const casinoGames = (state: RootState) => state.casino.games;
export default casinoSlice.reducer;
