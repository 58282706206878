import React from "react";

interface CustomButtonProps {
  text: string;
  classname?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  buttonType?: "primary" | "secondary" | "success" | "danger";
  type?: "button" | "submit" | "reset";
  height?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  classname = "",
  onClick,
  buttonType = "primary",
  type = "button",
  height = "h-10",
}) => {
  const buttonStyles: Record<string, string> = {
    primary:
      "bg-gradient-to-b from-[#FFE458] to-[#E1BD00] border-2 border-[#E1BD00] text-gray-400",
    secondary: "bg-transparent border border-white text-white",
    success: "bg-success text-white",
    danger: "bg-red-500 text-white",
  };

  const hoverStyles = "hover:opacity-80";
  const selectedStyles = buttonStyles[buttonType] || buttonStyles.primary;

  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex items-center justify-center rounded-[5px] py-2 w-full transition-transform duration-200 min-w-[75px] md:min-w-[100px] ${height} ${classname} ${selectedStyles} ${hoverStyles}`}
    >
      <span className="font-semibold text-sm">{text}</span>
    </button>
  );
};

export default CustomButton;
