import Icon from "@components/core-components/icon";
import { useState, useEffect } from "react";
import bitcoin from "../../assets/images/bitcoin-gray.png";
import dash from "../../assets/images/dash-gray.png";
import entropay from "../../assets/images/entropay-gray.png";
import neosurf from "../../assets/images/neosurf-gray.png";
import neteller from "../../assets/images/neteller-gray.png";
import payeer from "../../assets/images/payeer-gray.png";
import skrill from "../../assets/images/skrill-gray.png";
import sticpay from "../../assets/images/sticpay-gray.png";
import tron from "../../assets/images/tron-gray.png";

const data = [
  {
    heading: "Casino",
    id: 1,
    subItems: [
      "Casino Games",
      "Slots",
      "Live Casino",
      "Roulette",
      "Blackjack",
      "Providers",
      "Promos & Competitions",
    ],
  },
  {
    heading: "Sport",
    id: 2,
    subItems: [
      "Sportsbook",
      "Live Sports",
      "Soccer",
      "Basketball",
      "Tennis",
      "eSports",
      "Bet Bonuses",
      "Sports Rules",
      "Racing Rules",
    ],
  },
  {
    heading: "Exchange",
    id: 3,
    subItems: [
      "Help Center",
      "Fairness",
      "Gambling Helpline",
      "Live Support",
      "Self Exclusion",
    ],
  },
  {
    heading: "Support",
    id: 4,
    subItems: [
      "Help Center",
      "Fairness",
      "Gambling Helpline",
      "Live Support",
      "Self Exclusion",
    ],
  },
  {
    heading: "About Us",
    id: 5,
    subItems: [
      "VIP Club",
      "Affiliate",
      "Privacy Policy",
      "AML Policy",
      "Terms of Service",
    ],
  },
  {
    heading: "Payment Info",
    id: 6,
    subItems: [
      "Deposit & Withdrawals",
      "Currency Guide",
      "Crypto Guide",
      "Supported Crypto",
      "How to Use the Vault",
      "How Much to Bet With",
    ],
  },
  {
    heading: "How-to Guides",
    id: 7,
    subItems: [
      "Hot-to Guides",
      "Online Casino Guide",
      "Sports Betting Guide",
      "How to Live Stream",
      "Sports",
      "Stake VIP Guide",
      "House Edge Guide",
    ],
  },
];

const logos = [
  { src: bitcoin, alt: "bitcoin" },
  { src: dash, alt: "dash" },
  { src: tron, alt: "tron" },
  { src: neosurf, alt: "neosurf" },
  { src: payeer, alt: "payeer" },
  { src: neteller, alt: "neteller" },
  { src: sticpay, alt: "sticpay" },
  { src: entropay, alt: "entropay" },
  { src: skrill, alt: "skrill" },
];

function Footer() {
  function generateItems() {
    const [activeItem, setActiveItem] = useState<number | null>(null);

    const handleAccordionToggle = (id: number) => {
      setActiveItem(activeItem === id ? null : id);
    };

    return (
      <div className="w-full">
        {/* `xl` ve üstü için yatay liste */}
        <div className="hidden xl:flex justify-between">
          {data.map((item) => (
            <div className="flex flex-col items-start" key={item.id}>
              <div className="font-black text-heading-16 text-white mb-6">
                {item.heading}
              </div>
              <div className="flex flex-col gap-4">
                {item.subItems.map((subItem) => (
                  <a
                    key={subItem}
                    className="text-gray-100 font-normal text-body-reg-14 cursor-pointer"
                  >
                    {subItem}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* `xl` altı için accordion stili */}
        <div className="xl:hidden flex flex-col gap-2">
          {data.map((item) => (
            <div
              key={item.id}
              className="bg-gray-600 rounded-lg shadow-lg overflow-hidden"
            >
              <button
                className="flex justify-between items-center px-6 py-4 w-full text-left text-white font-semibold text-sm"
                onClick={() => handleAccordionToggle(item.id)}
              >
                {item.heading}
                <Icon
                  iconName="downArrow"
                  className={`transition-transform duration-500 ease-in-out ${
                    activeItem === item.id ? "rotate-180" : ""
                  }`}
                />
              </button>
              <div
                className={`transition-[max-height] duration-700 ease-in-out overflow-hidden ${
                  activeItem === item.id ? "max-h-[500px]" : "max-h-0"
                }`}
              >
                <div className="px-6 pb-4">
                  {item.subItems.map((subItem) => (
                    <a
                      key={subItem}
                      className="block text-gray-200 font-normal text-sm py-2"
                    >
                      {subItem}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <footer className="flex mt-32 2xl:-mx-[60px] h-auto overflow-y-auto main-no-scrollbar main-no-scrollbar::-webkit-scrollbar">
      <div className="flex flex-col h-full w-full bg-gray-900 shadow-xl px-4 lg:px-12 2xl:px-[140px]  pt-10 pb-20">
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col md:flex-row gap-1 justify-center items-start md:items-end">
            <div className="flex flex-row justify-center items-center">
              <Icon
                iconName="pixupYellowLogo"
                svgProps={{ width: 34, height: 23 }}
                className="w-[41px] h-[27px] justify-start"
              />
              <Icon
                iconName="pixupClientText"
                svgProps={{ width: 85, height: 14 }}
                className="w-[101px] h-[17px] justify-start"
              />
            </div>
            <span className="text-gray-100 text-sm font-normal md:ml-4">
              © 2024 Pixup.com | All Rights Reserved.
            </span>
          </div>
          <div className="flex flex-row w-[73px] justify-between">
            <Icon
              iconName="facebook"
              className="cursor-pointer"
              svgProps={{ width: 24, height: 24 }}
            />
            <Icon
              iconName="whatsapp"
              className="cursor-pointer"
              svgProps={{ width: 24, height: 24 }}
            />
          </div>
        </div>
        <hr className="w-full my-8 border-gray-300" />
        <div className="w-full flex flex-row justify-between">
          {generateItems()}
        </div>
        <hr className="w-full my-8 border-gray-300" />
        <div className="grid grid-cols-3 md:grid-cols-9 lg:grid-cols-9 w-full items-center self-center justify-between">
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className="h-auto w-full min-h-[38px] min-w-[85px] max-h-[57px] max-w-[129px] object-contain"
            />
          ))}
        </div>
        <hr className="w-full my-8 border-gray-300" />
        <div className="flex flex-col 2xl:flex-row gap-4 w-full justify-between ">
          <div className="flex flex-col gap-2 max-w-5xl">
            <p className="text-gray-200 text-sm font-normal">
              Pixup is committed to responsible gambling, for more information
              visit
              <a
                href="https://www.gamblingtherapy.org"
                className="underline ml-1"
              >
                Gamblingtherapy.org
              </a>
            </p>
            <p className="text-gray-200 text-sm font-normal">
              Pixup is owned and operated by Medium Rare N.V., registration
              number: 145353, registered address: Korporaalweg 10, Willemstad,
              Curaçao. Contact us at support@Pixup.com. Payment agent company is
              Medium Rare Limited with address 7-9 Riga Feraiou, LIZANTIA COURT,
              Office 310, Agioi Omologites, 1087 Nicosia, Cyprus and
              Registration number: HE 410775
            </p>
            <p className="text-gray-200 text-sm font-normal">
              Support support@Pixup.com | Partners partners@Pixup.com | Press
              press@Pixup.com
            </p>
          </div>
          <div className="flex flex-row gap-3 pb-8">
            <button className="h-[46px] w-[157px] bg-gray-600 gap-3 rounded-lg px-4 flex flex-row justify-between items-center text-body-reg-14 font-semibold text-white">
              English <Icon iconName="downArrow" className="mt-[5px]" />
            </button>
            <button className="h-[46px] w-[157px] bg-gray-600  gap-3 rounded-lg px-4 flex flex-row justify-between items-center text-body-reg-14 font-semibold text-white">
              Decimal <Icon iconName="downArrow" className="mt-[5px]" />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
