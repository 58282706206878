import React from "react";
import PragmaticPlayLogo from "../../../assets/images/pragmatic-play-logo-white.png";
import PromotionsBg from "../../../assets/images/desktop-zeus.avif";
import CustomButton from "@components/core-components/button/CustomButton";
import Breadcrumb from "@components/core-components/breadcrumb";

function Promotions() {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Promotions", href: "/promotions" },
  ];

  return (
    <div className="px-4 lg:px-10 xl:px-[63px] mt-3 md:pt-6 text-white">
      <div className="hidden md:block">
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <section className="flex flex-col 2xl:flex-row items-start 2xl:items-center justify-between mt-0 md:mt-[35px] lg:mt-[52px]">
        <div className="flex-shrink-0 order-1 2xl:order-2">
          <img
            src={PromotionsBg}
            alt="Zeus Pragmatic Play"
            className="w-screen h-auto 2xl:max-w-[687px] 2xl:max-h-[386px] object-cover"
          />
        </div>
        <div className="flex-1 order-2 2xl:order-1 mt-3 md:mt-6 2xl:mt-0">
          <div className="flex flex-col gap-5">
            <img
              src={PragmaticPlayLogo}
              alt="Pragmatic Play Logo"
              className="w-24 md:w-[201px] lg:w-[193px] h-11 md:h-[90px]"
            />
            <h1 className="text-2xl md:text-6xl font-black">
              Pragmatic Drops & Wins Slots
            </h1>
            <p className="text-gray-100 text-sm md:text-base">
              Be rewarded for enjoying Pragmatic’s range of slots games on
              Stake! <br />
              Every month, we award $2,000,000 in random drops to Pragmatic slot
              players!
            </p>
            <p className="text-gray-100 text-sm md:text-base">
              Join for Daily Prize Drops and Daily Tournaments! <br />
              For a full list of Pragmatic’s eligible games, click the link
              below.
            </p>
          </div>
        </div>
      </section>
      <section className="mt-7 md:mt-12 2xl:mt-20">
        <h2 className="text-2xl lg:text-6xl font-black mb-6">
          Terms & Conditions
        </h2>
        <ul className="text-gray-100 text-sm md:text-base space-y-2 list-disc list-inside">
          <li>
            This Network Promotion is operated by Pragmatic Play. As such, the
            prize pool is shared across the network and not specific to one
            casino.
          </li>
          <li>
            To participate in any Weekly Tournament or Daily Prize Drop in the
            Network Promotion, players must open any of the participating games
            and join/opt in.
          </li>
          <li>
            The Network Promotion prizes may be won by players in the form of a
            fixed cash amount or multiplier amount.
          </li>
          <li>
            If the winning spin exceeds 1 EUR or currency equivalent, the prize
            will be paid out based on a bet of that amount in the relevant
            currency.
          </li>
          <li>
            If the participating game does not have a bet value of 1 EUR or
            currency equivalent available, the Free Round Bonus will be paid out
            at the next available higher bet in the participating game.
          </li>
          <li>
            Prizes won in this Network Promotion will be automatically credited
            to player balances and will be reflected in the multiplier of the
            player's most recent bet on any participating Pragmatic game.
          </li>
          <li>
            Games will change on a regular basis and some will not be eligible
            for the Network Promotion after a certain period of time. For
            example, a game will be a part of the promotion between January 1
            and January 31, but this same promotion will alternate games for
            February 1 to February 28.
          </li>
          <li>
            More information on the Drops and Wins can be easily found in the
            top left-hand corner of any participating Pragmatic game.
          </li>
          <li>
            Pragmatic Play reserves the right to amend, suspend or cancel the
            Network Promotion, Daily Tournaments and/or Daily Prize Drops,
            including any related terms and conditions, at any time. Any such
            amendment will not affect players that have opted-in unless the
            amendment is required to manage/prevent fraud and other unlawful
            behaviour.
          </li>
        </ul>
      </section>
      <div className="mt-7 md:mt-12 lg:mt-24 flex justify-center">
        <CustomButton
          text="Drops & Wins Games!"
          onClick={() => {}}
          buttonType="primary"
          height="h-[50px] md:max-w-[436px]"
        />
      </div>
    </div>
  );
}

export default Promotions;
