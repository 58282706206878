import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../store/auth/authenticationSlice";
import casinoReducer from "../store/casino/casinoSlice";
import menuReducer from "../store/menu/menuSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  menu: menuReducer,
  casino: casinoReducer,
});

export default rootReducer;
