import React from "react";

interface ProviderCardProps {
  logo: string;
  name: string;
}

const ProviderCard = ({ logo, name }: ProviderCardProps) => (
  <div className="w-[90px] lg:w-[125px] 2xl:w-[145px]  h-12 md:h-20 2xl:h-[60px] bg-gray-600 flex items-center justify-center rounded-lg transition-transform duration-200 hover:scale-105">
    <img src={logo} alt={name} className="object-contain p-3" />
  </div>
);

export default ProviderCard;
