import React, { useEffect, useState } from "react";
import SearchBar from "@components/core-components/input/SearchBar";
import FullPageModal from "@components/core-components/modal/FullPageModal";
import SearchModal from "pages/private/casino/components/SearchModal";
import ExchangeCategoryList from "@components/core-components/ExchangeCategoryList";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sports: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [activeCategory, setActiveCategory] = useState("");
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  const [categories, setCategories] = useState<{ [key: string]: string }>({
    Cricket: "cricket",
    Tennis: "tennis",
    Football: "football",
    Jackpot: "jackpot",
    "Cricket casino": "cricketCasino",
    Kabaddi: "kabaddi",
  });

  const [dropdownButtons, setDropdownButtons] = useState<{
    [key: string]: { text1: string; text2: string; onClick: () => void }[];
  }>({
    Cricket: [
      {
        text1: "Guyana Amazon Warriors",
        text2: "Lahore Qalandars",
        onClick: () => console.log("Guyana Amazon Warriors clicked"),
      },
      {
        text1: "Lahore Qalandars",
        text2: "Guyana Amazon Warriors",
        onClick: () => console.log("Lahore Qalandars clicked"),
      },
    ],
    Tennis: [
      {
        text1: "Player 1",
        text2: "Player 2",
        onClick: () => console.log("Player 1 clicked"),
      },
      {
        text1: "Player 2",
        text2: "Player 1",
        onClick: () => console.log("Player 2 clicked"),
      },
    ],
    Football: [
      {
        text1: "Team A",
        text2: "Team B",
        onClick: () => console.log("Team A clicked"),
      },
      {
        text1: "Team B",
        text2: "Team A",
        onClick: () => console.log("Team B clicked"),
      },
    ],
    Jackpot: [
      {
        text1: "Jackpot 1",
        text2: "Jackpot 2",
        onClick: () => console.log("Jackpot 1 clicked"),
      },
      {
        text1: "Jackpot 2",
        text2: "Jackpot 1",
        onClick: () => console.log("Jackpot 2 clicked"),
      },
    ],
    "Cricket casino": [
      {
        text1: "Casino 1",
        text2: "Casino 2",
        onClick: () => console.log("Casino 1 clicked"),
      },
      {
        text1: "Casino 2",
        text2: "Casino 1",
        onClick: () => console.log("Casino 2 clicked"),
      },
    ],
    Kabaddi: [
      {
        text1: "Team X",
        text2: "Team Y",
        onClick: () => console.log("Team X clicked"),
      },
      {
        text1: "Team Y",
        text2: "Team X",
        onClick: () => console.log("Team Y clicked"),
      },
    ],
  });

  return (
    <FullPageModal title="SPORTS" isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full pt-3 overflow-x-hidden main-no-scrollbar">
        <div className="flex w-full items-center justify-between px-3">
          <SearchBar
            // onClick={handleOpenSearchModal}
            onClick={() => {}}
            value=""
            onChange={() => {}}
            preventFocus={true}
            bgColor="bg-gray-500"
            borderColor="border-gray-300"
          />
        </div>
        <SearchModal
          isOpen={isSearchModalVisible}
          onClose={() => setSearchModalVisible(false)}
        />
        <h2 className="text-base font-black mb-4 text-white px-3 pt-6">
          Sports
        </h2>
        <ExchangeCategoryList
          categories={categories}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          dropdownButtons={dropdownButtons}
        />
      </div>
    </FullPageModal>
  );
};

export default Sports;
