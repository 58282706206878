import React, { useEffect, useState } from "react";
import Modal from "@components/core-components/modal";
import Icon from "@components/core-components/icon";
import CustomButton from "@components/core-components/button/CustomButton";
import Game from "../../../../../../assets/images/sweet-bonanza.png";

interface GameDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const GameDetailModal: React.FC<GameDetailModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [value, setValue] = useState(34.6);

  useEffect(() => {
    if (!isOpen) {
      setValue(34.6);
    }
  }, [isOpen]);

  const details = [
    {
      label: "BET AMOUNT",
      value: "-15,00 USD",
      icon: "usdt",
      bgColor: "bg-gray-400",
      textColor: "text-white",
      fontWeight: "font-semibold",
    },
    {
      label: "MULTIPLIER",
      value: "0.00x",
      bgColor: "bg-gray-500",
      textColor: "text-white",
      fontWeight: "font-normal",
    },
    {
      label: "PAYOUT",
      value: "549,00 USD",
      icon: "usdt",
      bgColor: "bg-gray-400",
      textColor: "text-green-500",
      fontWeight: "font-semibold",
    },
    {
      label: "MULTIPLIER",
      value: "2.00",
      icon: "multiply",
      bgColor: "bg-gray-400",
      textColor: "text-white",
      fontWeight: "font-semibold",
    },
    {
      label: "ROLL OVER",
      value: "50.50",
      icon: "refresh",
      bgColor: "bg-gray-500",
      textColor: "text-white",
      fontWeight: "font-semibold",
    },
    {
      label: "WIN CHANCE",
      value: "49.50",
      icon: "percentage",
      bgColor: "bg-gray-400",
      textColor: "text-white",
      fontWeight: "font-semibold",
    },
  ];
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-[540px]"
      showTitleBorder={true}
      title={
        <div className="flex justify-center items-center w-full">
          <span className="text-lg font-semibold text-white">BET DETAILS</span>
        </div>
      }
      content={
        <div className={"px-4 md:px-10 pt-8 w-full"}>
          <div className="flex flex-col sm:flex-row items-start justify-between gap-4 sm:gap-8 pb-8">
            <img
              src={Game}
              alt="game"
              className="w-[100px] h-[134px] rounded-lg"
            />
            <div className="flex flex-col items-start w-full">
              <span className="text-white text-2xl font-black pb-2 w-full">
                Sweet Bonanza
              </span>
              <span className="text-gray-100 text-body-reg-15 font-normal pb-5 border-b border-gray-400 w-full">
                Placed by Camadan o 11/21/2024 at 9:41 AM
              </span>
              <div className="flex flex-row gap-3 w-full border-b border-gray-400 py-4">
                <span className="text-white text-body-reg-15 font-normal w-full">
                  <span className="font-semibold">BET ID:</span>{" "}
                  117673c45af68e44d56517f3aa
                </span>
                <button>
                  <Icon
                    iconName="copyWhite"
                    svgProps={{ width: 18, height: 18 }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-full">
            {details.slice(0, 3).map((detail, index) => (
              <div
                key={index}
                className={`flex flex-row items-center justify-between w-full px-4 py-[14px] ${detail.bgColor}`}
              >
                <span className="text-gray-200 font-black text-body-reg-15 w-full">
                  {detail.label}
                </span>
                <div className="flex flex-row items-center justify-end gap-[10px] w-full">
                  {detail.icon && (
                    <Icon
                      iconName={detail.icon}
                      svgProps={{ width: 18, height: 18 }}
                    />
                  )}
                  <span
                    className={`text-body-reg-15 ${detail.fontWeight} ${detail.textColor}`}
                  >
                    {detail.value}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center w-full py-7">
            <div className="flex justify-between w-full text-gray-100 text-lg font-normal">
              <span>0</span>
              <span>25</span>
              <span>50</span>
              <span>75</span>
              <span>100</span>
            </div>
            <div className="relative w-full h-14 flex items-center bg-gray-700 rounded-xl px-6 py-5">
              <div className="absolute top-1/2 transform -translate-y-1/2 w-[91%] h-5 py-2 rounded-xl bg-gray-300" />
              <div className="absolute top-1/2 transform -translate-y-1/2 w-[45%] h-3 bg-red-500 rounded-l-xl ml-1" />
              <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 w-[45%] h-3 bg-green-500 rounded-r-xl mr-1" />
              <div className="absolute items-center justify-center flex w-9 h-9 left-1/2 transform -translate-x-1/2">
                <Icon
                  iconName="sliderThumbStar"
                  svgProps={{ width: 39.3, height: 36 }}
                />
              </div>
              {/* Value Tooltip */}
              <div
                className="absolute bottom-full -mb-3 left-1/2 transform -translate-x-1/2 flex flex-col items-center"
                style={{ left: `${value}%` }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M32.0625 0H5.9375C2.65831 0 0 2.65831 0 5.9375V32.0625C0 35.3417 2.65831 38 5.9375 38H32.0625C35.3417 38 38 35.3417 38 32.0625V5.9375C38 2.65831 35.3417 0 32.0625 0Z"
                    fill={value < 50 ? "#EB454E" : "#22c55e"}
                  />
                  <path
                    opacity="0.2"
                    d="M38 5.9375V32.0625C37.9994 33.6371 37.3737 35.1469 36.2603 36.2603C35.1469 37.3737 33.6371 37.9994 32.0625 38H5.9375C4.36295 37.9994 2.85305 37.3737 1.73968 36.2603C0.626305 35.1469 0.000566532 33.6371 0 32.0625L0 27.5619C1.47572 30.0229 3.56422 32.0594 6.06172 33.4726C8.55922 34.8857 11.3804 35.6273 14.25 35.625H20.1875C22.2148 35.6251 24.2223 35.2258 26.0952 34.45C27.9682 33.6742 29.6701 32.5371 31.1036 31.1036C32.5371 29.6701 33.6742 27.9682 34.45 26.0952C35.2258 24.2223 35.6251 22.2148 35.625 20.1875V14.25C35.6273 11.3804 34.8857 8.55922 33.4726 6.06172C32.0594 3.56422 30.0229 1.47572 27.5619 0L32.0625 0C33.6371 0.000566532 35.1469 0.626305 36.2603 1.73968C37.3737 2.85305 37.9994 4.36295 38 5.9375Z"
                    fill="black"
                  />
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="white"
                    fontSize="16"
                    fontWeight="semibold"
                  >
                    {value.toFixed(1)}
                  </text>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  className="mt-1"
                >
                  <ellipse
                    cx="4"
                    cy="4"
                    rx="4"
                    ry="4"
                    fill={value < 50 ? "#EB454E" : "#22c55e"}
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-full pb-7">
            {details.slice(3, 6).map((detail, index) => (
              <div
                key={index}
                className={`flex flex-row items-center justify-between w-full px-4 py-[14px] ${detail.bgColor}`}
              >
                <span className="text-gray-200 font-black text-body-reg-15 w-full">
                  {detail.label}
                </span>
                <div className="flex flex-row items-center justify-end gap-[10px] w-full">
                  <span
                    className={`text-body-reg-15 ${detail.fontWeight} ${detail.textColor}`}
                  >
                    {detail.value}
                  </span>
                  {detail.icon && (
                    <Icon
                      iconName={detail.icon}
                      svgProps={{ width: 14, height: 14 }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <CustomButton
            text="Play Sweat Bonanza"
            onClick={() => console.log("Play Game")}
          />
        </div>
      }
    />
  );
};

export default GameDetailModal;
