import GameCard from "@components/core-components/card/GameCard";
import Icon from "@components/core-components/icon";
import Modal from "@components/core-components/modal";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import React, { useEffect, useState } from "react";
import {
  casinoGames,
  fetchGames,
  GameResponse,
} from "store/casino/casinoSlice";

interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialRecentSearches = [
  { id: 1, name: "Monopoly" },
  { id: 2, name: "Wild Wild Riches" },
  { id: 3, name: "Aviator" },
  { id: 4, name: "Seven Fire" },
];

const SearchModal: React.FC<SearchModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const games = useAppSelector(casinoGames);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCards, setFilteredCards] = useState(games);
  const [recentSearches, setRecentSearches] = useState(initialRecentSearches);

  // useEffect(() => {
  //   dispatch(fetchGames());
  // }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length >= 3) {
      const filtered = games.filter((card: GameResponse) =>
        String(card.game_name).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCards(filtered);
    }
  };

  const handleRemoveSearch = (id: number) => {
    setRecentSearches(recentSearches.filter((search) => search.id !== id));
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      contentClassNames="bg-none bg-transparent max-w-[1400px]"
      wrapperClassNames="w-full max-w-[1400px]"
      content={
        <div className="max-w-[1400px] w-full p-4">
          <div className="flex w-full items-center min-h-[50px] gap-4 h-full bg-gray-500 border border-gray-300 rounded-lg pl-4 py-3 mb-3 ">
            <Icon
              iconName="searchWhite"
              svgProps={{
                width: 16,
                height: 16,
              }}
            />
            <input
              type="text"
              placeholder="Search your game..."
              className="bg-gray-500 flex-1 text-sm font-medium text-gray-200 placeholder-gray-200 focus:outline-none"
              onChange={handleInputChange}
              style={{ fontSize: "16px" }}
            />
          </div>
          <div className="bg-gray-500 max-h-[484px] w-full rounded-lg">
            {searchTerm.length >= 3 ? (
              <div className="flex flex-row flex-wrap gap-6 bg-gray-500 max-h-[484px] w-full overflow-y-auto sm:px-[17px] px-11 py-6 rounded-lg">
                {filteredCards.length > 0 ? (
                  filteredCards.map((game: any) => (
                    <GameCard
                      key={game.game_id}
                      image={game.url_thumb}
                      id={game.game_id}
                      name={game.game_name}
                      altText={game.game_name}
                      notAvailable={game.status === "ACTIVE" ? false : true}
                    />
                  ))
                ) : (
                  <div className="h-full w-full justify-center items-center">
                    <p className="text-body-med-16 font-black text-white ">
                      No games found...
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-8 px-10 py-8">
                <span className="text-gray-200 text-body-med-16 font-semibold">
                  Search requires at least 3 characters.
                </span>
                <hr className="w-full bg-gray-300" />
                <span className="text-body-med-16 font-black text-gray-100 -mb-2">
                  Recent Searches
                </span>
                <div className="flex flex-row gap-2 flex-wrap">
                  {recentSearches.map((search) => (
                    <div
                      className="h-8 bg-gray-400 flex flex-row justify-center items-center px-3 py-2 rounded-lg cursor-default text-white gap-[10px]"
                      key={search.id}
                    >
                      <span className="text-body-reg-12">{search.name}</span>
                      <button
                        className="h-4 w-4"
                        onClick={() => handleRemoveSearch(search.id)}
                      >
                        <Icon
                          iconName="closeSearch"
                          svgProps={{
                            width: 16,
                            height: 16,
                          }}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

export default SearchModal;
