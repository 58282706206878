import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Icon from "../icon";
import { Link, useNavigate } from "react-router-dom";
import { sanitizeUrl } from "helper/SanitizeUrl";

interface SliderItem {
  id: string | number;
  [key: string]: any;
}

interface ReusableSliderProps {
  title: string;
  items: SliderItem[];
  showPagination?: boolean;
  height?: string;
  showViewAll?: boolean;
  renderItem: (item: SliderItem) => ReactNode;
  breakpoints?: {
    [key: string]: { slidesPerView: number; spaceBetween: number };
  };
}

const breakpointsConfig = {
  0: {
    slidesPerView: Math.floor(window.innerWidth / 114), // Mobile: 110px width + 4px gap
    spaceBetween: 4,
  },
  834: {
    slidesPerView: Math.floor(window.innerWidth / 138), // Ipad: 130px width + 8px gap
    spaceBetween: 8,
  },
  1400: {
    slidesPerView: Math.floor(window.innerWidth / 160), // Desktop: 150px width + 10px gap
    spaceBetween: 10,
  },
};

function CustomSwiper({
  title,
  height,
  items,
  showPagination = true,
  showViewAll = true,
  renderItem,
  breakpoints = breakpointsConfig,
}: ReusableSliderProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 834);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth > 834 && window.innerWidth <= 1400
  );
  const swiperRef = useRef<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  }, [items]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 834);
      setIsTablet(window.innerWidth > 834 && window.innerWidth <= 1400);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNext = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current;
      const slideWidth = swiper.slides[0]?.offsetWidth || 0;
      const visibleSlides = Math.floor(swiper.width / slideWidth);
      const newIndex = swiper.activeIndex + visibleSlides;

      if (newIndex < swiper.slides.length - visibleSlides) {
        swiper.slideTo(newIndex);
      } else {
        swiper.slideTo(swiper.slides.length - visibleSlides);
      }
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current;
      const slideWidth = swiper.slides[0]?.offsetWidth || 0;
      const visibleSlides = Math.floor(swiper.width / slideWidth);
      const newIndex = swiper.activeIndex - visibleSlides;

      if (newIndex >= 0) {
        swiper.slideTo(newIndex);
      } else {
        swiper.slideTo(0);
      }
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center text-white text-base md:text-lg font-black mb-2">
        <span>{title}</span>
        <div className="flex justify-end items-center text-white text-lg gap-6 font-semibold">
          {!isMobile && (
            <>
              {showPagination && (
                <div className="flex items-center gap-3">
                  <button
                    onClick={handlePrev}
                    disabled={isBeginning}
                    className={`w-9 h-9 flex justify-center items-center bg-gray-600 border border-gray-600 rounded-lg text-sm ${
                      isBeginning ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <Icon
                      iconName="sliderArrowLeft"
                      svgProps={{ width: 16.286, height: 14.476 }}
                    />
                  </button>
                  <button
                    onClick={handleNext}
                    disabled={isEnd}
                    className={`w-9 h-9 flex justify-center items-center bg-gray-600 border border-gray-600 rounded-lg text-sm ${
                      isEnd ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    <Icon
                      iconName="sliderArrowRight"
                      svgProps={{ width: 16.286, height: 14.476 }}
                    />
                  </button>
                </div>
              )}
            </>
          )}
          {showViewAll && (
            <button
              className="px-2 mr-3 md:mr-0 md:px-4 h-7 md:h-9 flex justify-center items-center bg-gray-600 border border-gray-600 rounded-lg text-xs md:text-sm"
              onClick={() => {
                navigate(`/lounge/${sanitizeUrl(title)}`);
              }}
            >
              View All
            </button>
          )}
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        slidesPerView="auto" // Automatically calculates how many slides fit
        spaceBetween={10} // Consistent spacing between slides
        watchSlidesProgress
        loop={false}
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={(swiper: any) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        onReachBeginning={() => setIsBeginning(true)}
        onReachEnd={() => setIsEnd(true)}
        onFromEdge={() => {
          setIsBeginning(false);
          setIsEnd(false);
        }}
        style={{
          height: height || "auto",
        }}
      >
        {items.map((item) => (
          <SwiperSlide
            key={item.id}
            style={{
              width: isMobile ? "100px" : isTablet ? "130px" : "150px",
              minWidth: isMobile ? "100px" : isTablet ? "130px" : "150px",
              maxWidth: isMobile ? "100px" : isTablet ? "130px" : "150px",
            }}
          >
            {renderItem(item)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default CustomSwiper;
