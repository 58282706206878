import React from "react";
import cx from "classnames";
import players from "@icons/players.svg";
import usersOcta from "@icons/user-octagon.svg";
import house from "@icons/house.svg";
import ReportIcon from "@icons/report.svg";
import payment from "@icons/payment.svg";
import promotion from "@icons/promotion.svg";
import bookOpen from "@icons/book-open.svg";
import categoryIcon2 from "@icons/category-2.svg";
import userPen from "@icons/user-pen.svg";
import rightArrow from "@icons/right-arrow.svg";
import leftArrow from "@icons/left-arrow.svg";
import calendar from "@icons/calendar.svg";
import close from "@icons/Popup-Close.svg";
import currency from "@icons/currencytree.svg";
import download from "@icons/download.svg";
import edit from "@icons/edit.svg";
import profile from "@icons/profile.svg";
import verified from "@icons/verified.svg";
import star from "@icons/star.svg";
import checkedstar from "@icons/checked-star.svg";
import filter from "@icons/filter.svg";
import cancel from "@icons/cancel.svg";
import twoChoice from "@icons/two-choice.svg";
import plus from "@icons/plus-sign.svg";
import minus from "@icons/minus-sign.svg";
import search from "@icons/search.svg";
import logo from "@icons/pixup-icon.svg";
import fileupload from "@icons/fileupload.svg";
import purpleArrow from "@icons/purple-arrow.svg";
import whiteArrow from "@icons/white-arrow.svg";
import closeCircle from "@icons/close-circle.svg";
import trash from "@icons/close-circle.svg";
import trashCan from "@icons/trash.svg";
import selected from "@icons/selected.svg";
import downArrow from "@icons/down-arrow.svg";
import whiteCancel from "@icons/cancel-white.svg";
import bonus from "@icons/bonus.svg";
import backIcon from "@icons/back-Icon.svg";
import checkbox from "@icons/Checkbox.svg";
import exchangeIcon from "@icons/exchange-icon.svg";
import casinoIcon from "@icons/casino-icon.svg";
import unCheckedbox from "@icons/uncheckbox.svg";
import indigoUpArrow from "@icons/indigo-up-arrow.svg";
import indigoDownArrow from "@icons/indigo-down-arrow.svg";
import move from "@icons/move.svg";
import ellipse10 from "@icons/ellipse10.svg";
import ellipse3 from "@icons/ellipse3.svg";
import UK from "@icons/united-kingdom.svg";
import indianRupee from "@icons/indianruppiee.svg";
import profileSite from "@icons/profile-site.svg";
import notification from "@icons/notification.svg";
import usdt from "@icons/usdt.svg";
import blueCheckbox from "@icons/BlueCheckbox.svg";
import searchWhite from "@icons/search-white.svg";
import avatarCircle from "@icons/avatar-circle.svg";
import ellipse20 from "@icons/ellipse20.svg";
import carouselRightIcon from "@icons/slider-arrow-right.svg";
import favorite from "@icons/favorite.svg";
import fullscreen from "@icons/fullscreen.svg";
import closeIcon from "@icons/close-icon.svg";
import pixupYellowLogo from "@icons/pixup-yellow-logo.svg";
import pixupClientText from "@icons/pixup-client-text.svg";
import facebook from "@icons/facebook.svg";
import usdt2 from "@icons/usdt2.svg";
import info from "@icons/info.svg";
import ghost from "@icons/ghost.svg";
import sliderArrowRight from "@icons/slider-arrow-right.svg";
import sliderArrowLeft from "@icons/slider-arrow-left.svg";
import support from "@icons/support.svg";
import region from "@icons/region.svg";
import check from "@icons/check.svg";
import checkSuccess from "@icons/check-success.svg";
import backSquare from "@icons/back-square.svg";
import gradientBgLogo from "@icons/gradient-background-logo.svg";
import filterBy from "@icons/filter-by.svg";
import sortBy from "@icons/sort-by.svg";
import affiliate from "@icons/affiliate.svg";
import blog from "@icons/blog.svg";
import sportsbookIcon from "@icons/sportbook.svg";
import forum from "@icons/forum.svg";
import gameShows from "@icons/game-shows.svg";
import crashGames from "@icons/crash-games.svg";
import liveCasino from "@icons/live-casino.svg";
import lobby from "@icons/lobby.svg";
import promotionIcon from "@icons/promotion-icon.svg";
import release from "@icons/release.svg";
import responsibleGambling from "@icons/responsible-gambling.svg";
import slots from "@icons/slots.svg";
import sponsorship from "@icons/sponsorship.svg";
import vipClub from "@icons/vip-club.svg";
import profileIcon from "@icons/profile-icon.svg";
import profileIconWhite from "@icons/profile-icon-white.svg";
import arrowRight from "@icons/arrow-right.svg";
import myBets from "@icons/my-bets.svg";
import settings from "@icons/settings.svg";
import logout from "@icons/logout.svg";
import pixupCasino from "@icons/pixup-casino.svg";
import pixupClient from "@icons/pixup-client.svg";
import pixupPink from "@icons/pixup-pink.svg";
import pixupYellow from "@icons/pixup-yellow.svg";
import categories from "@icons/categories.svg";
import chat from "@icons/chat.svg";
import favorites from "@icons/favorites.svg";
import platform from "@icons/platform.svg";
import searchMenu from "@icons/search-menu.svg";
import casino from "@icons/casino.svg";
import exchange from "@icons/exchange.svg";
import sportsbook from "@icons/sportsbook.svg";
import favoriteHeart from "@icons/favorite-heart.svg";
import onlinedot from "@icons/onlinedot.svg";
import tether from "@icons/tether.svg";
import withdrawableAmount from "@icons/withdrawable-amount.svg";
import bonusDependent from "@icons/bonus-dep.svg";
import bonusIcon from "@icons/bonus-icon.svg";
import inr from "@icons/inr.svg";
import jpy from "@icons/jpy.svg";
import lastPlays from "@icons/last-plays.svg";
import drake from "@icons/drake.svg";
import ufc from "@icons/ufc.svg";
import billeJean from "@icons/billie-jean-king-cup.svg";
import davisCup from "@icons/davis-cup.svg";
import everton from "@icons/everton.svg";
import juventude from "@icons/juventude.svg";
import teamSecret from "@icons/team-secret.svg";
import transparanLogo from "@icons/transparan-icon.svg";
import bonusBoost from "@icons/bonus-boost.svg";
import dart from "@icons/dart.svg";
import promotions from "@icons/promotions.svg";
import ticket from "@icons/ticket.svg";
import dropClose from "@icons/drop-close.svg";
import dropOpen from "@icons/drop-open.svg";
import customerSupport from "@icons/customer-support.svg";
import whatsapp from "@icons/whatsapp.svg";
import purpleVector from "@icons/vector-design-purple.svg";
import greenVector from "@icons/vector-design-green.svg";
import blueVector from "@icons/vector-design-blue.svg";
import vectorTriple from "@icons/vector-triple.svg";
import vectorTripleMobile from "@icons/vector-triple-mobile.svg";
import vipStars from "@icons/vip.svg";
import gameShowsVip from "@icons/game-shows-vip.svg";
import footballVip from "@icons/football-vip.svg";
import releaseVip from "@icons/release-vip.svg";
import vipStar1 from "@icons/level-1-star.svg";
import vipStar2 from "@icons/level-2-star.svg";
import vipStar3 from "@icons/level-3-star.svg";
import vipStar4 from "@icons/level-4-star.svg";
import vipStar5 from "@icons/level-5-star.svg";
import vipStar6 from "@icons/level-6-star.svg";
import vipStarBig1 from "@icons/vip-star-1.svg";
import vipStarBig2 from "@icons/vip-star-2.svg";
import vipStarBig3 from "@icons/vip-star-3.svg";
import vipStarBig4 from "@icons/vip-star-4.svg";
import vipStarBig5 from "@icons/vip-star-5.svg";
import vipStarBig6 from "@icons/vip-star-6.svg";
import vipStarDot1 from "@icons/vip-star-1-dot.svg";
import vipStarDot2 from "@icons/vip-star-2-dot.svg";
import vipStarDot3 from "@icons/vip-star-3-dot.svg";
import vipStarDot4 from "@icons/vip-star-4-dot.svg";
import vipStarDot5 from "@icons/vip-star-5-dot.svg";
import vipStarDot6 from "@icons/vip-star-6-dot.svg";
import benefitsCloudNetwork from "@icons/benefits-cloud-network.svg";
import benefitsLevelUp from "@icons/benefits-level-up.svg";
import benefitsPouch from "@icons/benefits-pouch.svg";
import benefitsRelease from "@icons/benefits-release.svg";
import benefitsShield from "@icons/benefits-shield.svg";
import vipArrowRight from "@icons/vip-arrow-right.svg";
import vipProgressGray from "@icons/vip-progress-gray.svg";
import vipProgressBlue from "@icons/vip-progress-blue.svg";
import vipProgressYellow from "@icons/vip-progress-yellow.svg";
import vipProgressOrange from "@icons/vip-progress-orange.svg";
import vipProgressPurple from "@icons/vip-progress-purple.svg";
import vipProgressRed from "@icons/vip-progress-red.svg";
import transactions from "@icons/transactions.svg";
import purpleCategory from "@icons/purple-category.svg";
import blueCategory from "@icons/blue-category.svg";
import greenCategory from "@icons/green-category.svg";
import closeSearch from "@icons/close.svg";
import loadMore from "@icons/load-more.svg";
import tick from "@icons/tick.svg";
import btc from "@icons/btc.svg";
import eth from "@icons/eth.svg";
import ltc from "@icons/ltc.svg";
import usdt26 from "@icons/usdt26.svg";
import copy from "@icons/copy.svg";
import whiteRightArrow from "@icons/white-right-arrow.svg";
import usd50 from "@icons/usd50.svg";
import btc24 from "@icons/btc24.svg";
import inr24 from "@icons/inr24.svg";
import copyWhite from "@icons/copy-white.svg";
import warning from "@icons/warning.svg";
import id from "@icons/id.svg";
import multiply from "@icons/multiply.svg";
import refresh from "@icons/refresh.svg";
import percentage from "@icons/percentage.svg";
import sliderThumbStar from "@icons/slider-thumb-star.svg";
import transparentPixupLogo from "@icons/transparent-pixup-logo.svg";
import clockGray from "@icons/clock-gray.svg";
import clockWhite from "@icons/clock-white.svg";
import tickWhite from "@icons/tick-white.svg";
import fileUpload from "@icons/file-upload.svg";
import warningYellow from "@icons/warning-yellow.svg";
import downArrowBlack from "@icons/down-arrow-black.svg";
import ios from "@icons/ios.svg";
import android from "@icons/android.svg";
import windows from "@icons/windows.svg";
import calendarLightGray from "@icons/calendar-lightgray.svg";
import pixupGreenMobile from "@icons/pixup-green-mobile.svg";
import pixupExchangeMobile from "@icons/pixup-exchange-mobile.svg";
import cricket from "@icons/cricket.svg";
import tennis from "@icons/tennis.svg";
import football from "@icons/football.svg";
import jackpot from "@icons/jackpot.svg";
import cricketCasino from "@icons/cricket-casino.svg";
import kabaddi from "@icons/kabaddi.svg";
import favoriteWhite from "@icons/favorite-white.svg";
import myMarket from "@icons/my-market.svg";
import sports from "@icons/sports.svg";
import exchangePlatform from "@icons/exchange-platform.svg";
import betslip from "@icons/betslip.svg";
import inPlaySquare from "@icons/in-play-square.svg";
import cricketSquare from "@icons/cricket-square.svg";
import tennisSquare from "@icons/tennis-square.svg";
import footballSquare from "@icons/football-square.svg";
import jackpotSquare from "@icons/jackpot-square.svg";
import cricketCasinoSquare from "@icons/cricket-casino-square.svg";
import kabaddiSquare from "@icons/kabaddi-square.svg";
import dollarIcon from "@icons/dollarIcon.svg";
import pixupLogo from "@icons/pixup-logo.svg";
import plusForButton from "@icons/plus.svg";
import minusForButton from "@icons/minus.svg";
import downArrowBig from "@icons/down-arrow-big.svg";
import close26 from "@icons/close-circle26.svg";
import cricket24 from "@icons/cricket24.svg";

type IconProps = {
  iconName: string;
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  iconclass?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

type IconMap = {
  [key: string]: string;
};

const icons: IconMap = {
  indianRupee,
  greenVector,
  players,
  vectorTriple,
  calendar,
  whiteCancel,
  blueVector,
  usersOcta,
  star,
  checkedstar,
  exchangeIcon,
  unCheckedbox,
  ios,
  android,
  windows,
  checkbox,
  purpleVector,
  whiteArrow,
  edit,
  casinoIcon,
  dollarIcon,
  sportsbookIcon,
  purpleArrow,
  transactions,
  indigoUpArrow,
  arrowRight,
  indigoDownArrow,
  verified,
  cancel,
  filter,
  blueCheckbox,
  download,
  bonus,
  profile,
  house,
  vectorTripleMobile,
  report: ReportIcon,
  close,
  info,
  currency,
  plus,
  minus,
  payment,
  promotion,
  bookOpen,
  settings,
  twoChoice,
  categoryIcon2,
  userPen,
  rightArrow,
  leftArrow,
  search,
  logo,
  fileupload,
  closeCircle,
  trash,
  trashCan,
  selected,
  downArrow,
  backIcon,
  move,
  ellipse10,
  ellipse3,
  UK,
  profileSite,
  notification,
  usdt,
  searchWhite,
  avatarCircle,
  ellipse20,
  carouselRightIcon,
  favorite,
  fullscreen,
  closeIcon,
  pixupYellowLogo,
  pixupClientText,
  facebook,
  usdt2,
  ghost,
  sliderArrowRight,
  sliderArrowLeft,
  support,
  region,
  check,
  checkSuccess,
  backSquare,
  gradientBgLogo,
  filterBy,
  sortBy,
  affiliate,
  blog,
  forum,
  gameShows,
  crashGames,
  liveCasino,
  lobby,
  promotionIcon,
  release,
  responsibleGambling,
  slots,
  sponsorship,
  vipClub,
  profileIcon,
  profileIconWhite,
  myBets,
  logout,
  pixupCasino,
  pixupClient,
  pixupPink,
  pixupYellow,
  categories,
  chat,
  favorites,
  platform,
  searchMenu,
  casino,
  exchange,
  sportsbook,
  favoriteHeart,
  onlinedot,
  tether,
  withdrawableAmount,
  bonusDependent,
  bonusIcon,
  inr,
  jpy,
  lastPlays,
  drake,
  ufc,
  billeJean,
  davisCup,
  everton,
  juventude,
  teamSecret,
  transparanLogo,
  bonusBoost,
  dart,
  promotions,
  ticket,
  dropClose,
  dropOpen,
  customerSupport,
  whatsapp,
  vipStars,
  gameShowsVip,
  footballVip,
  releaseVip,
  vipStar1,
  vipStar2,
  vipStar3,
  vipStar4,
  vipStar5,
  vipStar6,
  vipStarBig1,
  vipStarBig2,
  vipStarBig3,
  vipStarBig4,
  vipStarBig5,
  vipStarBig6,
  vipStarDot1,
  vipStarDot2,
  vipStarDot3,
  vipStarDot4,
  vipStarDot5,
  vipStarDot6,
  benefitsCloudNetwork,
  benefitsLevelUp,
  benefitsPouch,
  benefitsRelease,
  benefitsShield,
  vipArrowRight,
  vipProgressGray,
  vipProgressBlue,
  vipProgressYellow,
  vipProgressOrange,
  vipProgressPurple,
  vipProgressRed,
  purpleCategory,
  blueCategory,
  greenCategory,
  closeSearch,
  loadMore,
  tick,
  btc,
  eth,
  ltc,
  usdt26,
  copy,
  whiteRightArrow,
  usd50,
  btc24,
  inr24,
  copyWhite,
  warning,
  id,
  multiply,
  refresh,
  percentage,
  sliderThumbStar,
  transparentPixupLogo,
  clockGray,
  clockWhite,
  tickWhite,
  fileUpload,
  warningYellow,
  downArrowBlack,
  calendarLightGray,
  pixupGreenMobile,
  pixupExchangeMobile,
  cricket,
  tennis,
  football,
  jackpot,
  cricketCasino,
  kabaddi,
  favoriteWhite,
  myMarket,
  sports,
  exchangePlatform,
  betslip,
  inPlaySquare,
  cricketSquare,
  tennisSquare,
  footballSquare,
  jackpotSquare,
  cricketCasinoSquare,
  kabaddiSquare,
  pixupLogo,
  plusForButton,
  minusForButton,
  downArrowBig,
  close26,
  cricket24,
};

export const Icon: React.FC<IconProps> = ({
  iconName,
  svgProps = { width: 24, height: 24 },
  disabled,
  className,
  ...props
}) => {
  const classNames = cx(className, {
    "pointer-events-none": disabled,
    "cursor-pointer": props?.onClick,
  });

  const SvgIcon = icons[iconName];
  if (!SvgIcon) return null;
  return (
    <div
      className={`flex flex-row justify-center items-center ${classNames} shrink-0`}
      data-testid={iconName}
      {...props}
    >
      <SvgIcon {...svgProps} aria-hidden="true" />
    </div>
  );
};

export default Icon;
