import TabsComponent from "@components/core-components/TabsComponent";
import CustomButton from "@components/core-components/button/CustomButton";
import Icon from "@components/core-components/icon";
import Modal from "@components/core-components/modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VipStars from "../../../../assets/images/vip.png";

interface PixupVipModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PixupVipModal: React.FC<PixupVipModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<"progress" | "benefits">(
    "progress"
  );

  const handleAccordionToggle = (id: number) => {
    setActiveItem(activeItem === id ? null : id);
  };

  const data = [
    {
      star: "vipStar1",
      heading: "Level 1",
      id: 1,
      subItems: [
        "Bonus from Support in currency of your choice",
        "Rakeback enabled",
        "Weekly bonuses",
        "Monthly bonuses",
        "VIP Telegram channel access",
      ],
    },
    {
      star: "vipStar2",
      heading: "Level 2",
      id: 2,
      subItems: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
      ],
    },
    {
      star: "vipStar3",
      heading: "Level 3",
      id: 3,
      subItems: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
      ],
    },
    {
      star: "vipStar4",
      heading: "Level 4",
      id: 4,
      subItems: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
      ],
    },
    {
      star: "vipStar5",
      heading: "Level 5",
      id: 5,
      subItems: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
        "Daily Bonuses / Reload",
      ],
    },
    {
      star: "vipStar6",
      heading: "Level 6",
      id: 6,
      subItems: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
        "Daily Bonuses / Reload",
      ],
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<></>}
      showTitleBorder={false}
      content={
        <div className="flex flex-col w-full md:w-[484px] px-10">
          <div className="flex flex-col items-center">
            <div className="flex gap-2 justify-center">
              <img src={VipStars} alt="vip-stars" />
            </div>
            <h2 className="text-white text-xl font-black mt-6">PIXUP VIP</h2>
            <p className="text-gray-100 text-sm font-normal mt-2">
              Earn points as you play and benefit from VIP privileges
            </p>
          </div>
          <div className="pt-6">
            <TabsComponent
              tabs={[
                { id: "progress", label: "Progress" },
                { id: "benefits", label: "Benefits" },
              ]}
              classNames="mb-6 bg-gray-900 h-12 p-2 rounded-lg gap-2"
              activeTabColor="bg-primary"
              activeTab={activeTab}
              setActiveTab={(tab) => setActiveTab(tab as any)}
            />
            {activeTab === "progress" ? (
              <div>
                <h3 className="text-white text-lg font-semibold mb-4 pb-4 border-b border-gray-400">
                  Want to achieve the next level?
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center gap-4 pb-4 border-b border-gray-400">
                    <Icon
                      iconName="gameShowsVip"
                      svgProps={{ width: 39, height: 39 }}
                    />
                    <div>
                      <p className="text-white text-base font-semibold">
                        CASINO
                      </p>
                      <p className="text-gray-100 text-sm font-normal">
                        Play & wager on any casino games
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 pb-4 border-b border-gray-400">
                    <Icon
                      iconName="footballVip"
                      svgProps={{ width: 39, height: 39 }}
                    />
                    <div>
                      <p className="text-white text-base font-semibold">
                        BETS ON SPORTSBOOK
                      </p>
                      <p className="text-gray-100 text-sm font-normal">
                        Place bets on upcoming & live sports
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 pb-4 border-b border-gray-400">
                    <Icon
                      iconName="releaseVip"
                      svgProps={{ width: 39, height: 39 }}
                    />
                    <div>
                      <p className="text-white text-base font-semibold">
                        BETS ON SPORTSBOOK
                      </p>
                      <p className="text-gray-100 text-sm font-normal">
                        Place bets on upcoming & live sports
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  {data.map((item) => (
                    <div
                      key={item.id}
                      className="bg-gray-400 rounded-lg shadow-lg overflow-hidden"
                    >
                      <button
                        className="flex justify-between items-center px-6 py-5 w-full text-left text-white font-semibold text-base"
                        onClick={() => handleAccordionToggle(item.id)}
                      >
                        <div className="flex flex-row items-center justify-center self-center">
                          <Icon
                            iconName={item.star}
                            svgProps={{ width: 27, height: 23 }}
                            className="mr-2"
                          />
                          {item.heading}
                        </div>
                        {activeItem === item.id ? (
                          <Icon
                            iconName="dropClose"
                            svgProps={{ width: 28, height: 28 }}
                            className="transform rotate-180 opacity-100"
                          />
                        ) : (
                          <Icon
                            iconName="dropOpen"
                            svgProps={{ width: 28, height: 28 }}
                            className="transform rotate-0 opacity-75"
                          />
                        )}
                      </button>
                      <div
                        className={`transition-[max-height] duration-700 ease-in-out overflow-hidden ${
                          activeItem === item.id ? "max-h-[500px]" : "max-h-0"
                        }`}
                      >
                        <div className="p-6 pt-0">
                          <ul className="list-disc list-inside">
                            {item.subItems.map((subItem) => (
                              <li
                                key={subItem}
                                className="text-gray-200 font-normal text-sm pb-1"
                              >
                                {subItem}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex justify-center mt-6">
              <CustomButton
                text="Learn more about being a Pixup VIP"
                onClick={() => navigate("/vip-club")}
                buttonType="primary"
                height="h-[50px]"
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default PixupVipModal;
