import React, { useState } from "react";
import Icon from "@components/core-components/icon";
import FavoriteGameCards from "@components/core-components/FavoriteGameCards";
import { useSelector } from "react-redux";
import { casinoGames } from "store/casino/casinoSlice";
import TabsComponent from "@components/core-components/TabsComponent";
import ChatComponent from "pages/private/casino/components/ChatComponent";

function RightMenu({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [isChatVisible, setChatVisible] = useState(false);
  const games = useSelector(casinoGames);

  const lastPlaysGames = games.slice(0, 5);
  const favoritesGames = games.slice(1, 5);

  const gamesToShow =
    activeTab === "lastPlays" ? lastPlaysGames : favoritesGames;

  const tabs = [
    { id: "favorites", icon: "favorites" },
    { id: "lastPlays", icon: "lastPlays" },
    { id: "chat", icon: "chat" },
  ];

  const handleStartChat = () => {
    setChatVisible(true);
  };

  return (
    <aside
      style={activeTab == "chat" ? { width: "370px" } : { width: "270px" }}
      className={`bg-gray-900`}
    >
      <div className="flex justify-between items-center mx-2 my-[6px] rounded-lg">
        <TabsComponent
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          classNames="bg-[#1D243E] h-12 rounded-lg px-2 body-reg-15"
        />
      </div>
      <div className="h-full">
        {activeTab === "chat" ? (
          <>
            {isChatVisible ? (
              <ChatComponent />
            ) : (
              <div className="bg-gray-400 text-white p-6 mx-2 mt-2 rounded-lg flex flex-col items-center text-center">
                <div className="flex justify-center items-center mb-3">
                  <Icon
                    iconName="support"
                    svgProps={{ width: 32, height: 32 }}
                  />
                </div>
                <h3 className="text-base font-semibold mb-2">Pixup Chat</h3>
                <p className="text-sm font-normal text-gray-100 mb-5">
                  How can I help you?
                </p>
                <button
                  type="button"
                  className="flex items-center justify-center bg-gradient-to-b from-[#FFE458] to-[#E1BD00] border-2 border-[#E1BD00] rounded-[5px] w-full h-10 transition-transform duration-200 hover:scale-105"
                  onClick={handleStartChat}
                >
                  <span className="text-gray-400 font-semibold text-sm">
                    Start Chat
                  </span>
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col gap-2 mt-2 px-3 py-4 bg-gray-600 h-full">
            {gamesToShow.map((game) => (
              <FavoriteGameCards
                key={game.game_id}
                game={game}
                isFavorite={activeTab === "favorites"}
                toggleFavorite={() => {}}
              />
            ))}
          </div>
        )}
      </div>
    </aside>
  );
}

export default RightMenu;
