import React, { useEffect, useState } from "react";
import PixupCasinoLogo from "../../assets/images/pixup-casino-logo.png";
import DropdownButton from "../core-components/button/DropdownButton";
import Icon from "../core-components/icon";
import { Link } from "react-router-dom";
import CustomButton from "@components/core-components/button/CustomButton";
import { useAppSelector } from "hooks/redux";
import { useDispatch } from "react-redux";
import {
  logoutUser,
  setInitialWindow,
  setOpenModal,
} from "store/auth/authenticationSlice";
import { baseService } from "api/core/baseService";
import { Wallet } from "./types";
import WithdrawModal from "@components/core-components/modal/WithdrawModal";
import DepositModal from "@components/core-components/modal/DepositModal";
import WalletModal from "@components/core-components/modal/WalletModal";
import { useBaseQuery } from "api/core/useBaseQuery";
import LoadingOverlay from "./LoadingOverlay";
import PixupExchangeLogo from "../../assets/images/pixup-exchange-logo.png";

function Header() {
  const [playerData, setPlayerData] = useState<any>([]);
  const [selectedWallet, setSelectedWallet] = useState<any>([]);
  const { isAuthenticated, loading, userId } = useAppSelector(
    (state) => state.auth
  );
  const dispatch: any = useDispatch();
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  const {
    data: wallets,
    isLoading,
    refetch,
    error,
  } = useBaseQuery<Wallet[]>({
    endpoint: `users/wallet/${userId}`,
    queryKey: ["wallets", userId],
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (wallets) {
      setPlayerData(wallets);

      const activeWallet = wallets.find((wallet) => wallet.isActive);

      if (activeWallet) {
        setSelectedWallet(activeWallet);
      } else {
        setSelectedWallet(wallets[0] || null);
      }
    }
  }, [wallets]);

  if (isLoading) return <LoadingOverlay />;

  const updateActiveWallet = async (userId: string, walletId: string) => {
    try {
      await baseService.update(`users/wallet/update/${userId}`, {
        walletId,
        playerId: userId,
      });
      setSelectedWallet(
        playerData.find((wallet: any) => wallet._id === walletId)
      );
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const openSignInModal = () => {
    dispatch(setInitialWindow("signIn"));
    dispatch(setOpenModal(true));
  };

  const openRegisterModal = () => {
    dispatch(setInitialWindow("register"));
    dispatch(setOpenModal(true));
  };

  const openWithdrawModal = () => setIsWithdrawModalOpen(true);
  const closeWithdrawModal = () => setIsWithdrawModalOpen(false);
  const openDepositModal = () => setIsDepositModalOpen(true);
  const closeDepositModal = () => setIsDepositModalOpen(false);
  const openWalletModal = () => setIsWalletModalOpen(true);
  const closeWalletModal = () => setIsWalletModalOpen(false);
  const currencyIconMap: { [key: string]: string } = {
    USD: "usdt",
    JPY: "jpy",
    INR: "inr",
  };

  // const renderLogo = () => {
  //   if (location.pathname.includes("/lounge")) {
  //     return (
  //       <Link
  //         to={"/"}
  //         className="h-full w-[190px] p-5 items-center justify-center bg-gray-700"
  //       >
  //         <img
  //           src={PixupCasinoLogo}
  //           alt="Pixup Casino Logo"
  //           className="h-4 w-[152px] cursor-pointer"
  //         />
  //       </Link>
  //     );
  //   } else if (location.pathname.includes("/exchange")) {
  //     return (
  //       <Link
  //         className="flex flex-row w-[190px] pl-6 h-full items-center bg-gray-700"
  //         to={"/"}
  //       >
  //         <img
  //           src={PixupExchangeLogo}
  //           alt="Pixup Exchange Logo"
  //           className="h-4 w-[152px] cursor-pointer"
  //         />
  //       </Link>
  //     );
  //   } else {
  //     return (
  //       <Link
  //         className="flex flex-row w-[190px] pl-6 h-full items-center bg-gray-700"
  //         to={"/"}
  //       >
  //         <Icon
  //           iconName="pixupYellow"
  //           svgProps={{ width: 34, height: 24 }}
  //           className="mr-1"
  //         />
  //         <Icon iconName="pixupClient" svgProps={{ width: 85, height: 15 }} />
  //       </Link>
  //     );
  //   }
  // };

  return (
    <header className="z-50 bg-gray-900 h-[60px] w-full fixed top-0 flex items-center justify-between shadow-lg">
      {/* {renderLogo()} */}
      <Link
        to={"/"}
        className="flex w-[190px] pl-6 h-full items-center bg-gray-700"
      >
        <Icon iconName="pixupLogo" svgProps={{ width: 102, height: 18 }} />
      </Link>
      {loading && isLoading ? null : (
        <div
          className={`flex items-center h-full ${
            !isAuthenticated && !loading ? "pr-9" : ""
          }`}
        >
          <div className="flex items-center h-full">
            {isAuthenticated && !loading ? (
              selectedWallet && selectedWallet.currency && !loading ? (
                <>
                  <div
                    style={{ marginBottom: 10 }}
                    className="flex items-center justify-center h-full border-r border-l border-gray-300 duration-200 hover:bg-gray-400"
                  >
                    <DropdownButton
                      iconName={
                        currencyIconMap[selectedWallet?.currency[0]?.code] ||
                        "defaultIcon"
                      }
                      label={`${selectedWallet?.total?.toFixed(
                        selectedWallet?.currency[0]?.fixedValueCount ?? 2
                      )} ${selectedWallet?.currency[0]?.code}`}
                      iconWidth={17}
                      iconHeight={17}
                      options={playerData?.map((wallet: any) => ({
                        label: `${wallet?.currency[0]?.code}`,
                        onClick: () =>
                          updateActiveWallet(String(userId), wallet._id),
                        icon: wallet?.currency[0]?.icon,
                      }))}
                    />
                  </div>
                  <div className="flex items-center h-full gap-6 px-6">
                    <CustomButton
                      text="Deposit"
                      onClick={openWalletModal}
                      buttonType="primary"
                    />
                  </div>
                </>
              ) : null
            ) : null}
          </div>
          <DepositModal
            isOpen={isDepositModalOpen}
            onClose={closeDepositModal}
          />
          <WithdrawModal
            isOpen={isWithdrawModalOpen}
            onClose={closeWithdrawModal}
          />
          <WalletModal
            isOpen={isWalletModalOpen}
            onClose={closeWalletModal}
            onOpenDepositModal={openDepositModal}
            onOpenWithdrawModal={openWithdrawModal}
          />

          {/* Search Input */}
          <div className="flex items-center gap-4 h-full w-full min-w-[265px] max-w-4xl border-l border-gray-300 pr-6 pl-4">
            <Icon
              iconName="searchWhite"
              svgProps={{
                width: 16,
                height: 16,
              }}
            />
            <span className="text-white text-sm font-black">Search</span>
            <input
              type="text"
              placeholder="Casino games, sport, exchange..."
              className="bg-transparent w-full min-w-[265px] max-w-4xl text-sm font-medium text-gray-200 placeholder-gray-200 focus:outline-none"
            />
          </div>
          {/* Language Dropdown */}
          <div className="flex items-center h-full border-r border-l border-gray-300 duration-200 hover:bg-gray-400">
            <DropdownButton
              iconName="UK"
              label="EN"
              iconWidth={20}
              iconHeight={20}
              options={[
                { label: "English", onClick: () => console.log("English") },
                { label: "Français", onClick: () => console.log("Français") },
              ]}
            />
          </div>
          {isAuthenticated && !loading && (
            <>
              {/* Notification Button */}
              <button
                type="button"
                className="flex items-center justify-center h-full border-r border-gray-300 duration-200 hover:bg-gray-400 px-6 cursor-pointer"
                onClick={() => console.log("Notification")}
              >
                <Icon
                  iconName="notification"
                  svgProps={{
                    width: 14,
                    height: 17,
                  }}
                />
              </button>
            </>
          )}
          {!isAuthenticated ? (
            <div className="flex items-center h-full gap-6 px-6">
              <CustomButton
                text="Sign In"
                onClick={openSignInModal}
                buttonType="secondary"
              />
              <CustomButton
                text="Register"
                onClick={openRegisterModal}
                buttonType="primary"
              />
            </div>
          ) : (
            <div className="flex items-center h-full duration-200 hover:bg-gray-400">
              <DropdownButton
                iconName="profileIconWhite"
                label=""
                iconWidth={16}
                iconHeight={16}
                options={[
                  {
                    label: "My Bets",
                    icon: "myBets",
                    onClick: () => console.log("My Bets"),
                  },
                  {
                    label: "Settings",
                    icon: "settings",
                    onClick: () => console.log("Settings"),
                  },
                  {
                    label: "Logout",
                    icon: "logout",
                    onClick: () => dispatch(logoutUser()),
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
