import Icon from "@components/core-components/icon";
import React, { useEffect, useState } from "react";

interface CategoryCardProps {
  bgGradient: string;
  title: string;
  description: string;
  buttonText: string;
  imageSrc: string;
  imageAlt: string;
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  bgGradient,
  title,
  description,
  buttonText,
  imageSrc,
  imageAlt,
}) => {
  return (
    <div
      className={`w-full h-full lg:h-[410px] py-9 lg:py-0 px-2 relative md:px-12 2xl:px-20 ${bgGradient} flex flex-col-reverse lg:flex-row items-center lg:items-start justify-between rounded-xl relative`}
    >
      <div className="hidden lg:block absolute items-center justify-center pointer-events-none overflow-hidden bottom-0 left-20">
        <Icon
          iconName="transparanLogo"
          svgProps={{ width: 500, height: 300 }}
        />
      </div>
      <div className="text-white self-center w-full max-w-[720px] lg:max-w-[54%] relative  px-4 sm:px-6 md:px-8 lg:px-0 lg:pr-12 ">
        <h2 className="font-black mb-4 lg:text-left text-center leading-tight text-3xl 2xl:text-heading-50">
          {title}
        </h2>
        <p className="font-normal lg:text-left text-center pb-8 text-sm 2xl:text-base">
          {description}
        </p>
        <div className="relative w-full lg:max-w-[250px] h-[50px] flex items-center justify-center">
          <button className="absolute inset-0  text-base font-semibold bg-black bg-opacity-30 text-white rounded-[45px] hover:bg-opacity-50">
            {buttonText}
          </button>
        </div>
      </div>
      <div className="lg:absolute pr-4 lg:pr-0 below-1480:-right-6 lg:right-20 lg:bottom-[15px] lg:-mb-0 -mb-5 lg:z-20 h-auto max-w-full w-[80%] sm:w-[60%] md:w-[50%] lg:w-[589px] lg:h-[451px]">
        <img src={imageSrc} alt={imageAlt} />
      </div>
    </div>
  );
};

export default CategoryCard;
