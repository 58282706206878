import React, { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useAppSelector } from "hooks/redux";
import { useLocation } from "react-router-dom";
import useCheckUrl from "helper/useCheckUrl";
import Icon from "../icon";

interface FullPageModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  mt?: string;
  title?: string;
  showTitle?: boolean;
}

const FullPageModal: React.FC<FullPageModalProps> = ({
  isOpen,
  onClose,
  children,
  mt,
  title,
  showTitle = true,
}) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { activeTab } = useAppSelector((state) => state.menu);
  const location = useLocation();
  const isGameScreen = useCheckUrl(location.pathname, "game");

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const marginTop =
    isAuthenticated && !isGameScreen && activeTab !== "chat"
      ? "mt-[116px]"
      : mt || "mt-[60px]";

  return createPortal(
    <div
      className={`fixed inset-0 z-20 flex flex-col bg-gray-700 text-white ${marginTop} mb-[65px]`}
    >
      {showTitle === true ? (
        <button
          onClick={onClose}
          className="flex items-center justify-between px-6 py-4 border-b border-gray-300 bg-gray-700"
        >
          <span className="text-xl font-black">{title}</span>
          <Icon iconName="downArrowBig" svgProps={{ width: 17, height: 10 }} />
        </button>
      ) : null}
      <div className="flex-grow overflow-y-auto main-no-scrollbar">
        {children}
      </div>
    </div>,
    document.body
  );
};

export default FullPageModal;
