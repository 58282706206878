import React from "react";
interface InsightCardsProps {
  cartType: "back" | "lay";
  odds: number;
  amount: number;
}
const InsightCards: React.FC<InsightCardsProps> = ({
  cartType,
  odds,
  amount,
}) => {
  const cartTypeStyle = cartType === "back" ? "bg-back-bet" : "bg-lay-bet";
  return (
    <div
      className={`${cartTypeStyle} max-h-[52px] rounded   px-1 min-w-[52px] md:min-w-[64px] lg:min-w-[94px] flex flex-col items-center`}
    >
      <p className="font-bold m-0 p-0">{odds}</p>
      <span className="text-gray-400 text-xs m-0 p-0">{amount}</span>
    </div>
  );
};

export default InsightCards;
