import CustomSwitch from "@components/core-components/switch";
import React from "react";

interface CardProps {
  title: string;
  description: string;
  isChecked: boolean;
  onToggle: (checked: boolean) => void;
  bgColor?: string;
}

const SwitchCard: React.FC<CardProps> = ({
  title,
  description,
  isChecked,
  onToggle,
  bgColor = "bg-gray-800",
}) => {
  return (
    <div
      className={`flex justify-between items-center px-8 py-[14px] gap-[12px] shadow-md   ${bgColor}`}
    >
      <div>
        <h3 className="text-white font-semibold text-base">{title}</h3>
        <p className="text-xs pt-[6px] text-gray-200 leading-none ">
          {description}
        </p>
      </div>
      <div>
        {" "}
        <CustomSwitch isChecked={isChecked} onChange={onToggle} />
      </div>
    </div>
  );
};

export default SwitchCard;
