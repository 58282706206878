import React, { useState } from "react";
import VipClubBgImgSm from "../../../assets/images/mobile-vip-bg.avif";
import VipClubBgImgMd from "../../../assets/images/tablet-vip-bg.avif";
import VipClubBgImg from "../../../assets/images/desktop-vip-bg.avif";
import VipStars from "../../../assets/images/vip.png";
import Icon from "@components/core-components/icon";
import QuestionItem from "@components/QuestionItem";

function VipClub() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const cardsData = [
    {
      iconName: "vipStarBig1",
      title: "$10k",
      dot: "vipStarDot1",
      features: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
      ],
    },
    {
      iconName: "vipStarBig2",
      title: "$50k",
      dot: "vipStarDot2",
      features: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
      ],
    },
    {
      iconName: "vipStarBig3",
      title: "$75k",
      dot: "vipStarDot3",
      features: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
      ],
    },
    {
      iconName: "vipStarBig4",
      title: "$100k",
      dot: "vipStarDot4",
      features: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
      ],
    },
    {
      iconName: "vipStarBig5",
      title: "$250k",
      dot: "vipStarDot5",
      features: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
        "Daily Bonuses / Reload",
      ],
    },
    {
      iconName: "vipStarBig6",
      title: "$1m",
      dot: "vipStarDot6",
      features: [
        "Monthly Bonuses",
        "Level Up Bonuses",
        "Rakeback",
        "Weekly Bonuses",
        "Bonus Growth",
        "Daily Bonuses / Reload",
      ],
    },
  ];

  const benefits = [
    {
      iconName: "benefitsRelease",
      title: "Boost",
      description:
        "Every week and every month, expect a fresh bonus based on your recent games. The more you play, the higher the bonuses.",
    },
    {
      iconName: "benefitsCloudNetwork",
      title: "Dedicated VIP Host",
      description:
        "Receive your own dedicated VIP host who will support and cater to your betting needs.",
    },
    {
      iconName: "benefitsPouch",
      title: "Recent Play Bonuses",
      description:
        "Having a rough streak of luck? Stake offers money back on losses every time you level up.",
    },
    {
      iconName: "benefitsLevelUp",
      title: "Level-Ups",
      description:
        "Reach a new level and get paid. The level-ups get better the higher you go.",
    },
    {
      iconName: "benefitsShield",
      title: "Bespoke",
      description:
        "Work with your dedicated VIP host to tailor benefits to your gaming needs.",
    },
  ];

  const questionsData = [
    {
      question: "Why is Pixup’s VIP program the best?",
      answer:
        "Pixup’s VIP program is often rated as one of the best online casino experiences due to the amount of bonuses we give out to players. Additionally, our award winning support team is online 24/7 to answer any questions you have. We have the highest customer satisfaction rate out of many online casinos where we have amassed a community of some of the most passionate gamblers across the globe.",
    },
    {
      question: "How much has Pixup given out in bonuses?",
      answer:
        "Pixup has given out over $1 million in bonuses to our players. We have a wide range of bonuses available to players, including welcome bonuses, deposit bonuses, and free spins. Our VIP program is one of the most generous in the industry, offering exclusive bonuses to our most loyal players.",
    },
    {
      question: "How do I enter the $75,000 weekly raffle?",
      answer:
        "To enter the $75,000 weekly raffle, you need to be a VIP member at Pixup. Once you have reached VIP status, you will automatically be entered into the raffle. Winners are chosen at random every week, and the more you play, the higher your chances of winning.",
    },
    {
      question: "Where can I find the Pixup Telegram Channel?",
      answer:
        "You can find the Pixup Telegram Channel by searching for Pixup on Telegram. Our channel is updated regularly with the latest news, promotions, and bonuses. Join our channel to stay up to date with all the latest Pixup news.",
    },
    {
      question: "Where can I find the Pixup VIP Telegram channel?",
      answer:
        "You can find the Pixup VIP Telegram channel by searching for Pixup VIP on Telegram. Our VIP channel is updated regularly with exclusive promotions and bonuses for our VIP players. Join our VIP channel to stay up to date with all the latest VIP news.",
    },
  ];

  let screenWidth = window.innerWidth;
  let backgroundImage;

  if (screenWidth > 1024) {
    backgroundImage = `url(${VipClubBgImg})`;
  } else if (screenWidth >= 768 && screenWidth <= 1024) {
    backgroundImage = `url(${VipClubBgImgMd})`;
  } else if (screenWidth < 768) {
    backgroundImage = `url(${VipClubBgImgSm})`;
  }

  return (
    <div className="px-3 lg:px-6 xl:px-[60px]">
      <section
        className="relative mt-0 lg:mt-6 w-screen lg:w-full h-full min-h-[312px] xl:min-h-[447px] md:min-h-[330px] max-h-[447px] bg-cover  bg-center bg-no-repeat flex items-center justify-center overflow-hidden -mx-3 lg:-mx-0"
        style={{
          backgroundImage: backgroundImage,
        }}
      >
        <div className="flex flex-col w-[90%] md:w-[50%] lg:mt-0 lg:max-w-[777px] h-auto py-12 px-6 bg-gray-600 bg-opacity-85 rounded-[10px] z-10">
          <div className="text-white text-center flex flex-col items-center">
            <img src={VipStars} />
            <span className="text-2xl md:text-3xl 2xl:text-heading-50 font-black leading-tight mt-5 2xl:mt-[30px] mb-2 2xl:mb-3">
              PIXUP VIP
            </span>
            <p className="text-sm 2xl:text-base text-gray-100 font-normal">
              Earn points as you play and benefit from VIP privileges
            </p>
          </div>
        </div>
      </section>
      <section className="flex flex-col gap-[64px] mt-[54px]">
        <div className="container flex items-center justify-start">
          <span className="text-3xl 2xl:text-heading-40 font-black leading-tight text-white max-w-6xl">
            PIXUP VIP Ranking System
          </span>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-6 justify-between gap-x-2 md:gap-x-4 2xl:gap-md-5 gap-y-10">
          {cardsData.map((card, index) => (
            <div
              key={index}
              className="flex flex-col w-full bg-gray-500 rounded-lg justify-start items-center shadow-md"
            >
              <div className="flex w-[78px] h-[78px] -mt-6 rounded-full justify-center items-center border-2 border-gray-500 mb-6">
                <Icon iconName={card.iconName} svgProps={{ height: 78 }} />
              </div>
              <span className="text-white text-3xl font-black mb-2">
                {card.title}
              </span>
              <span className="text-gray-100 text-base font-normal mb-6">
                Wager Amount
              </span>
              <div className="w-full bg-gray-400 pt-4 rounded-b-lg h-full">
                {card.features.map((feature: string, idx: number) => (
                  <div key={idx} className="flex items-center mb-5 px-8 gap-3">
                    <Icon iconName={card.dot} svgProps={{ height: 15 }} />
                    <span className="text-white text-base font-normal">
                      {feature}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="flex flex-col gap-[64px] mt-[54px]">
        <div className="container flex items-center justify-start">
          <span className="text-3xl 2xl:text-heading-40 font-black leading-tight text-white max-w-6xl">
            PIXUP VIP Club Benefits
          </span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-5 justify-between gap-x-2 md:gap-x-4 2xl:gap-md-5 gap-y-10">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="flex flex-col w-full h-auto bg-gray-400 rounded-lg justify-start items-center px-6 py-7"
            >
              <div className="flex w-[75px] md:w-20 2xl:w-[100px] h-[75px] md:h-20 2xl:h-[100px] bg-custom-gradient-gray rounded-xl justify-center items-center border-[3px] border-[#5D6A98] p-5 mb-6">
                <Icon iconName={benefit.iconName} svgProps={{}} />
              </div>
              <span className="text-white text-3xl font-black mb-4 text-center">
                {benefit.title}
              </span>
              <p className="text-gray-100 text-base font-normal text-center">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className="flex flex-col lg:flex-row mt-[113px] justify-between gap-[62px] md:gap-8">
        <div className="flex flex-col items-start justify-start w-full lg:w-[45%]">
          <span className="text-3xl 2xl:text-heading-40 font-black leading-tight text-white">
            Frequently Asked Questions
          </span>
          <p className="text-gray-100 text-base font-normal mt-4 max-w-xl">
            Reach out to our award winning support team
          </p>
        </div>
        <div className="flex flex-col w-full lg:w-[50%]">
          {questionsData.map((item, index) => (
            <QuestionItem
              key={index}
              question={item.question}
              answer={item.answer}
              isActive={activeIndex === index}
              onClick={() =>
                setActiveIndex(activeIndex === index ? null : index)
              }
            />
          ))}
        </div>
      </section>
    </div>
  );
}

export default VipClub;
