import React, { useState, useEffect, useRef } from "react";
import Icon from "@components/core-components/icon";
import DatePickerComponent from "react-datepicker";
import { getYear, getMonth } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  placeholder?: string;
  maxDate?: Date;
  range?: { start: number; end: number; step?: number };
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  iconName?: string;
  svgProps?: {
    width: number;
    height: number;
  };
  showLeftIcon?: boolean;
  showLabel?: boolean;
}

const range = (start: number, end: number, step: number = 1) => {
  const length = Math.floor((end - start) / step) + 1;
  return Array.from({ length }, (_, i) => start + i * step);
};

const DatePicker = ({
  placeholder = "Tarih seç",
  maxDate,
  range: rangeProp = { start: 1990, end: getYear(new Date()) + 1, step: 1 },
  backgroundColor = "bg-white",
  color = "text-gray-900",
  hoverColor = "",
  iconName = "downArrowBlack",
  svgProps = { width: 11, height: 7 },
  showLeftIcon = false,
  showLabel = true,
}: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const years = range(rangeProp.start, rangeProp.end, rangeProp.step);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <div className="relative w-full" ref={selectRef}>
      {showLabel && (
        <label
          onClick={() => setIsOpen((prev) => !prev)}
          className={`absolute text-gray-200 left-[17px] cursor-pointer transform -translate-y-1/2 text-sm transition-all duration-300 ${
            selectedDate ? "top-4 text-xs" : "top-1/2"
          }`}
        >
          {placeholder}
        </label>
      )}
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={`flex h-[50px] gap-2 items-center justify-between w-full text-sm font-semibold px-4 py-3 rounded-md cursor-pointer ${backgroundColor} ${hoverColor}`}
      >
        <div className="flex items-center gap-2 w-full mt-3">
          {showLeftIcon && (
            <Icon
              iconName={"calendarLightGray"}
              svgProps={{ width: 23, height: 23 }}
            />
          )}
          <span className={`${selectedDate ? color : "text-gray-200"} `}>
            {selectedDate ? formatDate(selectedDate) : ""}
          </span>
        </div>
        <Icon
          iconName={iconName}
          svgProps={svgProps}
          className={`transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 bg-white shadow-md w-auto h-auto mt-2 rounded-md max-w-60">
          <DatePickerComponent
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className="flex flex-row m-[10px] justify-between">
                <div className="flex flex-row gap-2">
                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    className="bg-transparent cursor-pointer text-sm font-bold w-auto"
                    style={{ appearance: "none" }}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(Number(value))
                    }
                    className="bg-transparent cursor-pointer text-sm font-bold w-auto"
                    style={{ appearance: "none" }}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="w-4 h-4"
                  >
                    <Icon
                      iconName="downArrowBlack"
                      svgProps={{ width: 11, height: 7 }}
                    />
                  </button>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className="w-4 h-4 ml-2"
                  >
                    <Icon
                      iconName="downArrowBlack"
                      svgProps={{ width: 11, height: 7 }}
                      className="transform rotate-180"
                    />
                  </button>
                </div>
              </div>
            )}
            calendarContainer={({ children }) => (
              <div>
                {children}
                <div className="flex justify-between p-2 border-t">
                  <button
                    onClick={() => {
                      setSelectedDate(null);
                      setIsOpen(false);
                    }}
                    className="text-red-700 px-1"
                  >
                    Clear
                  </button>
                  <button
                    onClick={() => {
                      setSelectedDate(new Date());
                      setIsOpen(false);
                    }}
                    className="text-blue-700 px-1"
                  >
                    Today
                  </button>
                </div>
              </div>
            )}
            selected={selectedDate}
            onChange={(date: Date | null) => {
              setSelectedDate(date);
              setIsOpen(false);
            }}
            inline
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
