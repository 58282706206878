import React from "react";

interface NumberInputProps {
  title: string;
  value: number | string;
  onChange: (value: number | string) => void;
  borderColor?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
  title,
  value,
  onChange,
  borderColor = "border-back-bet",
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <div className="flex flex-col items-start">
      <span className="text-gray-200 text-base font-normal mb-1">{title}</span>
      <input
        type="number"
        value={value}
        onChange={handleInputChange}
        className={`w-full h-12 py-3 p-2 text-white bg-transparent rounded-[4px] focus:outline-none border-2 ${borderColor}`}
      />
    </div>
  );
};

export default NumberInput;
