import CustomButton from "@components/core-components/button/CustomButton";
import React, { useState } from "react";
import SwitchCard from "./SwitchCard";

function PreferencesSettings() {
  const [ghostMode, setGhostMode] = useState(true);
  const [hideStats, setHideStats] = useState(false);
  const [hideRaceStats, setHideRaceStats] = useState(false);

  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="pt-6 text-white text-[15px]">PRIVACY</p>
        <p className="text-sm text-gray-200 leading-none pt-3">
          User privacy is one of the core values of Stake. These settings allow
          you to be completely anonymous from the rest of the players.
        </p>
        <div className="flex py-8 flex-col">
          <SwitchCard
            title="Enable Ghost Mode"
            description="Your username will not appear in public bet feed and bet preview"
            isChecked={ghostMode}
            onToggle={(checked) => setGhostMode(checked)}
            bgColor="bg-gray-400"
          />
          <SwitchCard
            title="Hide all your statistics"
            description="Other users won't be able to view your wins, losses and wagered statistics"
            isChecked={hideStats}
            onToggle={(checked) => setHideStats(checked)}
            bgColor="bg-gray-500"
          />
          <SwitchCard
            title="Hide all your race statistics"
            description="Other users won't be able to view your race statistics"
            isChecked={hideRaceStats}
            onToggle={(checked) => setHideRaceStats(checked)}
            bgColor="bg-gray-400"
          />
        </div>
      </div>
      <div className="flex justify-end h-full gap-6w-full">
        <CustomButton
          classname="max-w-[175px]"
          text="Save"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default PreferencesSettings;
