import React from "react";
import BetSets, { BetInterface } from "./BetSets";
import Icon from "@components/core-components/icon";
const mockData: BetInterface[] = [
  { id: 1, type: "back", odds: 2.5, amount: 150 },
  { id: 2, type: "lay", odds: 1.8, amount: 120 },
];
interface MatchCardItemProps {
  title: string;
  betData: BetInterface[];
  odds: 1 | 0 | null;
  bookable?: boolean;
  isSuspended?: boolean;
  actionShow?: boolean;
  multipleSets?: boolean;
  withHeader?: boolean;
}
export default function MatchCardItem({
  title,
  bookable = false,
  betData,
  multipleSets = false,
  isSuspended = false,
  withHeader = false,
  actionShow = false,

  odds = null,
}: MatchCardItemProps) {
  return (
    <div>
      <div
        className={`flex ${
          !multipleSets ? "flex-row " : "flex-col md:flex-row gap-2 "
        }  p-2.5   justify-between w-full bg-gray-400`}
      >
        <div
          className={`flex text-white items-center  ${
            multipleSets
              ? "justify-start md:justify-center "
              : " justify-center"
          }  gap-2`}
        >
          <span
            className={`px-1 min-w-[14px] py-[1px] rounded ${
              odds === 1
                ? "bg-success"
                : odds === 0
                ? "bg-gray-300"
                : "bg-gray-400 text-transparent"
            }`}
          >
            {odds !== null ? odds : "-"}
          </span>
          <p>{title}</p>
        </div>

        <div
          className={`flex ${
            multipleSets
              ? !isSuspended &&
                "bg-gray-500 md:bg-gray-400 rounded p-2 pt-1 md:p-0 md:pt-0"
              : ""
          } gap-[39px] lg:gap-[116px] justify-center items-center  md:justify-between`}
        >
          {bookable && (
            <button className="text-gray-500 bg-gray-100 rounded p-1 h-[24px] md:h-full   text-xs md:text-sm font-semibold">
              BOOK
            </button>
          )}
          <div className="overflow-auto">
            <BetSets
              data={betData}
              multipleSets={multipleSets}
              isSuspended={isSuspended}
            />
          </div>
          {multipleSets ? null : (
            <>
              {" "}
              {actionShow ? (
                <div className="flex hidden md:flex gap-2.5 ">
                  <Icon
                    className="bg-gray-500 p-2.5 rounded"
                    iconName="favorite"
                  />
                  <Icon className="bg-gray-500 p-2.5 rounded" iconName="info" />
                </div>
              ) : (
                <div className="  hidden md:block w-[98px]"></div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
