import React, { useEffect, useState } from "react";
import Icon from "@components/core-components/icon";
import GameCard from "@components/core-components/card/GameCard";
import "swiper/css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import SampleImage from "../../../../assets/images/gameSample.png";
import CustomSwiper from "@components/core-components/swiper";
import { useSelector } from "react-redux";
import {
  casinoGames,
  fetchGames,
  GameResponse,
  setMobileGameStarted,
} from "store/casino/casinoSlice";
import CustomButton from "@components/core-components/button/CustomButton";
import { setOpenModal } from "store/auth/authenticationSlice";
import AuthModal from "@components/core-components/AuthModal";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useBaseQuery } from "api/core/useBaseQuery";
import LoadingOverlay from "@components/layout-components/LoadingOverlay";
import { GAMES_URL } from "config/apiUrls";

function Game() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuthenticated } = useSelector((state: any) => state.auth);
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const games = useAppSelector(casinoGames);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      const orientation = window.orientation;
      setIsLandscape(orientation === 90 || orientation === -90);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      const orientation = window.orientation;
      setIsLandscape(orientation === 90 || orientation === -90);
    };
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const { data: selectedGame, isLoading } = useBaseQuery<any>({
    endpoint: GAMES_URL.GET_GAME(gameId),
    enabled: !!gameId,
  });

  const tags = [
    "Bonus Buy",
    "Candy",
    "Cascading",
    "Drop & Wins",
    "Fruit",
    "Pragmatic Games",
    "Recommended Games",
    "Sages",
    "Slots",
  ];

  const handlePlayNow = () => {
    navigate(`play`);
    if (isMobile || isLandscape) {
      dispatch(setMobileGameStarted(true));
    }
  };
  if (isLoading) return <LoadingOverlay />;
  const screenHeight = window.innerHeight - 133;
  return (
    <div className={"flex flex-col items-center p-0 md:px-8 md:pt-7"}>
      <div
        className={
          "flex justify-between md:max-w-screen-xxl items-center pb-5 text-white w-full pt-5 px-3 md:pt-0 md:px-0 sticky md:static top-0 bg-gray-900 md:bg-transparent z-10"
        }
      >
        {isMobile ? (
          <>
            <button
              className="cursor-pointer"
              onClick={() => {
                dispatch(setMobileGameStarted(false));
                navigate(-1);
              }}
            >
              <Icon
                iconName="sliderArrowLeft"
                svgProps={{ width: 26, height: 26 }}
              />
            </button>
            <span className="truncate text-lg font-semibold text-center flex-grow">
              {games[0]?.game_name}
            </span>
            <button onClick={() => console.log("Added to favorites")}>
              <Icon
                iconName="favorite"
                svgProps={{
                  width: 26,
                  height: 26,
                }}
              />
            </button>
          </>
        ) : (
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-2 text-sm font-medium">
              <Link to={`/`}>Homepage</Link>
              <Icon
                iconName="rightArrow"
                svgProps={{ width: 16, height: 12 }}
              />
              <span>{games[0]?.game_name}</span>
            </div>
            <button onClick={() => console.log("Added to favorites")}>
              <Icon
                iconName="favorite"
                svgProps={{
                  width: 26,
                  height: 26,
                }}
              />
            </button>
          </div>
        )}
      </div>
      {isMobile && <AuthModal />}
      <div
        style={{ height: screenHeight, margin: 0 }}
        className="relative w-full md:max-w-screen-xxl md:bg-black overflow-hidden mb-4 md:h-auto md:max-h-[750px]"
      >
        <img
          src={SampleImage}
          alt="Game"
          className="w-full h-full object-cover"
        />

        <div className="absolute h-full inset-0 flex flex-col items-center justify-center bg-black bg-opacity-60">
          <img
            src={selectedGame?.url_thumb}
            alt="Game Thumbnail"
            className="w-[100px] h-[133px] md:w-[150px] md:h-[200px] rounded-lg mb-4"
          />
          <h1 className={"text-xl md:text-4xl font-black text-white mb-4"}>
            {selectedGame?.game_name || "Game Name"}
          </h1>
          <div className="flex flex-col w-full max-w-[175px] md:max-w-[283px] gap-4">
            {isAuthenticated ? (
              <CustomButton
                text="Play Now"
                onClick={handlePlayNow}
                buttonType="primary"
                height="md:h-[50px]"
              />
            ) : (
              <CustomButton
                text=" Register or Sign In"
                onClick={() => dispatch(setOpenModal(true))}
                buttonType="primary"
                height="md:h-[50px]"
              />
            )}
            <CustomButton
              text=" Fun Play"
              onClick={() => console.log("Fun Play Clicked")}
              buttonType="secondary"
            />
          </div>
        </div>
      </div>
      {!isAuthenticated && (
        <div
          className={`flex flex-col mx-2 md:mx-0 mt-6 md:mt-10 md:flex-row gap-4 md:w-full w-auto max-w-screen-xxl bg-gray-600 rounded-lg px-2 py-4 md:p-8 text-white`}
        >
          <img
            src={selectedGame?.url_thumb}
            alt={selectedGame?.game_name}
            className={"w-24 h-24 md:w-36 md:h-36 rounded-lg"}
          />
          <div className="w-full">
            <span className="text-lg font-black text-orange">
              Pragmatic Play
            </span>
            <h2 className={"text-2xl md:text-4xl font-black mt-2"}>
              {selectedGame?.game_name}
            </h2>
            <div className="flex flex-wrap gap-2 mt-3">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-400 text-xs px-3 py-1 rounded-lg"
                >
                  {tag}
                </span>
              ))}
            </div>
            <div className="gap-8 mt-6 max-w-screen-sm">
              <p className="text-sm font-normal mb-2 text-gray-200">
                Sweet Bonanza 1000 is a makeover of Pragmatic Play’s popular
                video slot, Sweet Bonanza.
              </p>
              <p className="text-sm font-normal mb-3 text-gray-200">
                The new version of the renowned candy-themed game uses a 6x5
                grid, has a max potential payout of 25,000x the pixup, and has
                no set paylines, as it pays anywhere.
              </p>
              <p className="text-sm font-normal mb-3 text-gray-200">
                Let’s unpack the exciting gameplay and game details of this
                reimagination of the beloved candy-themed slot machine now
                available at Pixup Casino !
              </p>
              <h3 className="text-lg font-black mb-3 mt-8">
                How to Play Sweet Bonanza 1000 & Gameplay
              </h3>
              <p className="text-sm font-normal mb-3 text-gray-200">
                You can choose to play this online slot game in free-play mode
                or start wagering funds right away.
              </p>
              <p className="text-sm font-normal mb-3 text-gray-200">
                The demo game is an excellent way to learn the rules and
                features of a game, but our how-to-play online slots guide can
                also help you master the basics. While you are in a reading
                mood, check out the online casino guide to learn more about how
                to navigate Pixup.com .
              </p>
              <p className="text-sm font-normal mb-3 text-gray-200">
                Once you gain sufficient confidence in the gameplay, load the
                game and choose your bet. The plus and minus buttons are
                prominent in the menu, allowing you to adjust your bet amount.
                The spin button will start each spin. You secure a win if 8 or
                more matching symbols align anywhere on the reels. The outcome
                of spins is random, and an RNG system regulates that chance
                effect.
              </p>
            </div>
          </div>
          {!isMobile && (
            <button className="flex w-auto items-center bg-transparent h-7 text-white gap-4 mt-4">
              <Icon iconName="favorite" svgProps={{ width: 26, height: 26 }} />
              <span className="text-base font-black whitespace-nowrap">
                Add Favorite
              </span>
            </button>
          )}
        </div>
      )}
      <div className={"pt-8 pb-14 w-full px-3 md:px-0 md:max-w-screen-xxl"}>
        <CustomSwiper
          title="Recommended Games"
          items={games}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
    </div>
  );
}

export default Game;
