import Icon from "@components/core-components/icon";
import TabsComponent from "@components/core-components/TabsComponent";
import React, { useState } from "react";

type Game = {
  game: string;
  user: string;
  multiplier: number;
  betAmount: number;
  payout: number;
};

type Props = {
  games: Game[];
  showHeader?: boolean;
};

const rowData = [
  {
    game: "Gates of Olympus",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Big Bass BOOM",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Mines",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Big Bass BOOM",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Mines",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Big Bass BOOM",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Mines",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Big Bass BOOM",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
  {
    game: "Mines",
    user: "Hidden",
    time: "2:54 PM",
    betAmount: "20.46271936",
    multiplier: "1.65x",
    payout: "-20.46271936",
  },
];

const tabs = [
  { id: "casino", label: "Casino" },
  { id: "sports", label: "Sports" },
];

const MobileCasinoTable: React.FC<Props> = ({ games, showHeader = true }) => {
  const [activeTab, setActiveTab] = useState("casino");

  const scrollRef = React.createRef<HTMLUListElement>();
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const target = e.currentTarget;
    if (target.scrollTop + target.clientHeight < target.scrollHeight) {
      e.stopPropagation();
    }
  };

  return (
    <div className="bg-gray-500 rounded-t-xl mb-4">
      {showHeader && (
        <div className="pt-3 px-3 mb-4">
          <TabsComponent
            tabs={tabs}
            isHomeScreen={true}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            classNames="bg-gray-900 rounded-lg h-12 p-2"
            activeTabColor="bg-primary"
          />
        </div>
      )}
      <ul
        ref={scrollRef}
        onScroll={handleScroll}
        className="max-h-[716px] overflow-y-auto"
      >
        {rowData.map((game, index) => (
          <li
            key={index}
            className="flex flex-col gap-3 shadow-md p-3  w-full odd:bg-gray-400 even:bg-gray-500"
          >
            <div className="flex flex-row justify-between w-full">
              <p className="text-body-reg-14 font-black text-white">
                {game.game}
              </p>
              <p className="text-[#8B9CCF] font-normal text-body-reg-12">
                {game.time}
              </p>
            </div>
            <hr className="w-full h-[1px] bg-[#525C7C] border-0" />

            <div className="flex flex-col gap-3">
              <p className="text-body-reg-13 font-semibold text-[#8B9CCF]">
                USER:{" "}
                <span className="text-white text-body-reg-13 font-normal">
                  {game.user}
                </span>
              </p>
              <p className="text-body-reg-13 font-semibold text-[#8B9CCF]">
                MULTIPLIER:{" "}
                <span className="text-white text-body-reg-13 font-normal">
                  {game.multiplier}x
                </span>
              </p>
              <p className="text-body-reg-13 font-semibold text-[#8B9CCF] flex flex-row gap-2 items-center">
                BET AMOUNT:{" "}
                <span className="text-white text-body-reg-13 font-normal">
                  {game.betAmount}
                </span>
                <Icon iconName="tether" svgProps={{ width: 18, height: 18 }} />
              </p>
              <p className="text-body-reg-13 font-semibold text-[#8B9CCF]">
                PAYOUT:{" "}
                <span className="text-white text-body-reg-13 font-normal">
                  {game.payout}
                </span>
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileCasinoTable;
