import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import Icon from "@components/core-components/icon";
import PlatformModal from "@components/core-components/modal/PlatformModal";
import Menu from "pages/private/casino/pages/menu";
import Chat from "pages/private/casino/pages/chat";
import Favorites from "pages/private/casino/pages/favorites";
import Categories from "pages/private/casino/pages/categories";
import {
  setActiveTab,
  toggleModal,
  closeAllModals,
} from "store/menu/menuSlice";
import useCheckUrl from "helper/useCheckUrl";
import Betslip from "pages/public/exchange/components/betslip";
import Sports from "pages/public/exchange/components/sports";
import ExchangeMenu from "pages/public/exchange/components/menu";

function MobileBottomTabs({
  isLandscape = false,
  isMobile = false,
}: {
  isLandscape?: boolean;
  isMobile?: boolean;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeTab, modals } = useAppSelector((state) => state.menu);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const isActive = (tabKey: string) => activeTab === tabKey;
  const isExchangeScreen = useCheckUrl(location.pathname, "exchange");

  const handleTabClick = (tab: any) => {
    if (tab.key === activeTab) {
      dispatch(toggleModal(tab.key));
      dispatch(setActiveTab(""));
      if (location.pathname.includes("lounge")) {
        dispatch(setActiveTab("casino"));
      }
    } else {
      dispatch(closeAllModals());
      dispatch(setActiveTab(tab.key));
      if (!tab.path) {
        dispatch(toggleModal(tab.key));
      } else {
        navigate(tab.path);
      }
    }

    if (location.pathname.includes("lounge") && activeTab === "") {
      dispatch(setActiveTab("casino"));
    }
  };

  useEffect(() => {
    if (location.pathname.includes("lounge")) {
      dispatch(setActiveTab("casino"));
    } else if (
      !location.pathname.includes("lounge") &&
      activeTab === "casino"
    ) {
      dispatch(setActiveTab(""));
    }

    dispatch(closeAllModals());
  }, [dispatch, location.pathname]);

  const renderMobileBottomTabs = () => {
    if (isLandscape && !isMobile) return false;
    return true;
  };

  const tabs =
    isExchangeScreen && isAuthenticated
      ? [
          {
            key: "exchangeMenu",
            label: "Menu",
            iconName: "searchMenu",
          },
          {
            key: "sports",
            label: "Sports",
            iconName: "sports",
          },
          {
            key: "platform",
            label: "Platform",
            iconName: "exchangePlatform",
          },
          {
            key: "betslip",
            label: "Betslip",
            iconName: "betslip",
          },
          {
            key: "chat",
            label: "Chat",
            iconName: "chat",
          },
        ]
      : isAuthenticated
      ? [
          {
            key: "menu",
            label: "Menu",
            iconName: "searchMenu",
          },
          {
            key: "favorites",
            label: "Favorites",
            iconName: "favorites",
          },
          {
            key: "platform",
            label: "Platform",
            iconName: "platform",
          },
          {
            key: "categories",
            label: "Categories",
            iconName: "categories",
          },
          {
            key: "chat",
            label: "Chat",
            iconName: "chat",
          },
        ]
      : [
          {
            key: "menu",
            label: "Menu",
            iconName: "searchMenu",
          },
          {
            key: "casino",
            label: "Casino",
            iconName: "casinoIcon",
            path: "/lounge",
          },
          {
            key: "sportbook",
            label: "Sportbook",
            iconName: "sportsbookIcon",
            path: "/",
          },
          {
            key: "exchange",
            label: "Exchange",
            iconName: "exchangeIcon",
            path: "/exchange",
          },
          {
            key: "chat",
            label: "Chat",
            iconName: "chat",
          },
        ];

  return (
    <>
      {renderMobileBottomTabs() && (
        <>
          <div className="fixed bottom-0 left-0 bg-gray-900 flex justify-around items-center shadow-md z-20 h-[65px] w-full">
            {tabs.map((tab) => (
              <button
                key={tab.key}
                className={`flex flex-col items-center w-full max-w-16 ${
                  isActive(tab.key) ? "text-yellow" : "text-white"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                <Icon
                  iconName={tab.iconName}
                  svgProps={{
                    width:
                      tab.iconName === "platform" ||
                      tab.iconName === "exchangePlatform"
                        ? 30
                        : 24,
                    height:
                      tab.iconName === "platform" ||
                      tab.iconName === "exchangePlatform"
                        ? 30
                        : 24,
                    fill: isActive(tab.key) ? "#FDD60B" : "#FFFFFF",
                  }}
                />
                <span
                  className={`text-xs mt-2 ${
                    isActive(tab.key) ? "text-yellow" : "text-white"
                  }`}
                >
                  {tab.label}
                </span>
              </button>
            ))}
          </div>
          {/* Modals */}
          <PlatformModal
            isOpen={modals.platform}
            onClose={() => dispatch(toggleModal("platform"))}
          />
          <Menu
            isOpen={modals.menu}
            onClose={() => dispatch(toggleModal("menu"))}
          />
          <Chat
            isOpen={modals.chat}
            onClose={() => dispatch(toggleModal("chat"))}
            mt="mt-0"
          />
          <Favorites
            isOpen={modals.favorites}
            onClose={() => dispatch(toggleModal("favorites"))}
          />
          <Categories
            isOpen={modals.categories}
            onClose={() => dispatch(toggleModal("categories"))}
          />
          <ExchangeMenu
            isOpen={modals.exchangeMenu}
            onClose={() => dispatch(toggleModal("exchangeMenu"))}
          />
          <Sports
            isOpen={modals.sports}
            onClose={() => dispatch(toggleModal("sports"))}
          />
          <Betslip
            isOpen={modals.betslip}
            onClose={() => dispatch(toggleModal("betslip"))}
          />
        </>
      )}
    </>
  );
}

export default MobileBottomTabs;
