import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "@components/layout-components/Header";
import HeaderMobile from "@components/layout-components/HeaderMobile";
import LeftMenu from "../../components/layout-components/LeftMenu";
import MobileBottomTabs from "@components/layout-components/MobileBottomTabs";
import { useAppSelector } from "hooks/redux";
import AuthModal from "@components/core-components/AuthModal";
import { useDispatch } from "react-redux";
import Footer from "@components/layout-components/Footer";
import useIsLandscape from "hooks/useIsLandscape";
import useCheckUrl from "helper/useCheckUrl";
import RightMenu from "@components/layout-components/RightMenu";
import Icon from "@components/core-components/icon";

function PrivateLayout() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const isLandscape = useIsLandscape();
  const { activeTab: menuTab } = useAppSelector((state) => state.menu);
  const [activeTab, setActiveTab] = useState("lastPlays");
  const [isRightMenuVisible, setIsRightMenuVisible] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    layoutSettings: { mobileDepositOpened, mobileGameStarted },
  } = useAppSelector((state) => state.casino);

  const { isAuthenticated, openModal } = useAppSelector((state) => state.auth);

  const updateLayout = () => {
    setIsMobile(window.innerWidth <= 1024);
    // setIsLandscape(window.innerWidth > window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateLayout);
    updateLayout(); // Initial check on mount

    return () => {
      window.removeEventListener("resize", updateLayout);
    };
  }, []);
  const isGameScreen = useCheckUrl(location.pathname, "play");

  const shouldHideLeftMenu = () => {
    if (isMobile) return false;

    return true;
  };
  const isPlay = useCheckUrl(location.pathname, "play");
  const shouldShowHeaderMobile = () => {
    if (menuTab === "chat") return false;
    if (isPlay) return false;
    if (mobileDepositOpened) return false;
    if (mobileGameStarted) return false;
    return true;
  };
  const shouldShowMobileBottomTabs = () => {
    if (isMobile) {
      if (mobileDepositOpened) return false;
      return true;
    }
    return false;
  };
  const toggleRightMenu = () => {
    setIsRightMenuVisible((prev) => !prev);
  };

  const getButtonPosition = () => {
    if (!isRightMenuVisible) return "right-0";
    return activeTab === "chat" ? "right-[370px]" : "right-[270px]";
  };

  return (
    <div className="flex-grow flex flex-col relative h-screen overflow-hidden">
      <AuthModal />
      {isMobile || isLandscape ? (
        shouldShowHeaderMobile() ? (
          <HeaderMobile />
        ) : null
      ) : (
        <Header />
      )}
      <div
        className={`flex ${
          isMobile || isLandscape
            ? mobileDepositOpened || isGameScreen
              ? "mt-0"
              : "mt-[60px] "
            : "mt-[60px]"
        } h-full w-screen relative`}
      >
        {shouldHideLeftMenu() && <LeftMenu />}
        <main
          className={`flex-grow flex-1 bg-gray-700 h-full middle:h-full  w-full overflow-x-hidden main-no-scrollbar main-no-scrollbar::-webkit-scrollbar ${
            isAuthenticated && isMobile && !location.pathname.includes("play")
              ? "mt-14"
              : ""
          }`}
        >
          <Outlet />
          <Footer />
        </main>
        {isAuthenticated && (
          <>
            {isMobile ? null : (
              <>
                <button
                  className={`absolute bg-gray-600 border-gray-400 border border-r-0 text-white mt-4 px-2 py-1 rounded-l-lg z-10 ${getButtonPosition()}`}
                  onClick={toggleRightMenu}
                >
                  {isRightMenuVisible ? (
                    <Icon
                      iconName="rightArrow"
                      svgProps={{ width: 10, height: 16 }}
                    />
                  ) : (
                    <Icon
                      iconName="rightArrow"
                      svgProps={{ width: 10, height: 16 }}
                      className="transition transform rotate-180"
                    />
                  )}
                </button>
                <div
                  className={`transition-transform duration-500 ${
                    isRightMenuVisible ? "translate-x-0" : "translate-x-full"
                  }`}
                >
                  {isRightMenuVisible && (
                    <RightMenu
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {shouldShowMobileBottomTabs() && !isLandscape && (
        <MobileBottomTabs isLandscape={isLandscape} isMobile={isMobile} />
      )}
    </div>
  );
}

export default PrivateLayout;
