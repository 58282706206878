import React from "react";
import Icon from "@components/core-components/icon";

interface InputWithButtonProps {
  label: string;
  amount: string;
  inputValue: string;
  onInputChange: (value: string) => void;
  buttonText?: string;
  buttonIcon?: string;
  onButtonClick: () => void;
  iconName?: string;
  placeholder?: string;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
  label,
  amount,
  inputValue,
  onInputChange,
  buttonText,
  buttonIcon,
  onButtonClick,
  iconName,
  placeholder,
}) => {
  return (
    <div>
      <div className="flex items-center justify-between mb-2 text-body-reg-13">
        <label className="block text-gray-200">{label}</label>
        <div className="text-right text-white font-semibold">{amount}</div>
      </div>
      <div className="flex flex-row pr-4">
        <div className="flex items-center bg-white text-white rounded-l-md px-4 py-2 h-[50px] w-full">
          {iconName && (
            <div className="flex items-center gap-2 mr-2">
              <Icon iconName={iconName} svgProps={{ width: 24, height: 24 }} />
            </div>
          )}
          <input
            type="text"
            value={inputValue}
            onChange={(e) => onInputChange(e.target.value)}
            placeholder={placeholder}
            className="flex-1 bg-transparent text-gray-800 text-sm placeholder-gray-200 focus:outline-none"
          />
        </div>
        <button
          onClick={onButtonClick}
          className="bg-gray-300 hover:bg-gray-400 text-white font-bold px-6 py-2 h-[50px] w-[77px] -mr-4 rounded-r-md"
        >
          {buttonText
            ? buttonText
            : buttonIcon && (
                <Icon
                  iconName={buttonIcon}
                  svgProps={{ width: 18, height: 18 }}
                />
              )}
        </button>
      </div>
    </div>
  );
};

export default InputWithButton;
