import { useEffect } from "react";
import Pages from "./pages";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { checkAuthStatus } from "store/auth/authenticationSlice";
import ScrollToTop from "helper/ScrollToTop";
import { closeAllModals } from "store/menu/menuSlice";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "@components/layout-components/LoadingOverlay";
import { fetchGames } from "store/casino/casinoSlice";

function App() {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.auth);
  const location = useLocation();
  useEffect(() => {
    dispatch(fetchGames());
  }, []);

  useEffect(() => {
    dispatch(closeAllModals());
  }, [location.pathname]);
  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <ScrollToTop classNames="flex-grow flex-1 bg-gray-700 h-screen overflow-x-hidden main-no-scrollbar" />
      <Pages />
    </>
  );
}

export default App;
