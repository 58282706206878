import CustomButton from "@components/core-components/button/CustomButton";
import InsightCards from "@components/core-components/insight-cards";
import React from "react";

export interface BetInterface {
  id: number;
  type: "back" | "lay";
  odds: number;
  amount: number;
}

interface BetSetsProps {
  multipleSets?: boolean;
  data: BetInterface[];
  isSuspended?: boolean;
}

export default function BetSets({
  data,
  isSuspended = false,
  multipleSets = false,
}: BetSetsProps) {
  const backData = data.filter((bet) => bet.type === "back");
  const layData = data.filter((bet) => bet.type === "lay");

  return (
    <div className="relative flex gap-2 items-start justify-start">
      {isSuspended && (
        <div className="absolute inset-0 top-[-1px] bottom-[-1px] left-[-1px] right-[-1px] bg-opacity-95 bg-gray-700 flex items-center justify-center z-10 pointer-events-none">
          <span className="text-danger font-bold text-xl">SUSPENDED</span>
        </div>
      )}
      <div className="relative flex-col gap-2 items-start justify-start">
        {" "}
        {multipleSets && !isSuspended && (
          <p className="block md:hidden text-gray-200 text-end">Back</p>
        )}
        <div className="relative flex gap-2 items-start justify-start">
          {backData.map((bet) => (
            <InsightCards
              key={bet.id}
              cartType={bet.type}
              odds={bet.odds}
              amount={bet.amount}
            />
          ))}
        </div>
      </div>
      <div className="relative flex-col gap-2 items-start justify-end">
        {" "}
        {multipleSets && !isSuspended && (
          <p className="block md:hidden text-gray-200 ">Lay</p>
        )}
        <div className="relative flex gap-2 items-start justify-start">
          {layData.map((bet) => (
            <InsightCards
              key={bet.id}
              cartType={bet.type}
              odds={bet.odds}
              amount={bet.amount}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
