import React, { useEffect, useState } from "react";
import FullPageModal from "@components/core-components/modal/FullPageModal";
import Icon from "@components/core-components/icon";
import AdjustableValueInput from "@components/core-components/input/AdjustableValueInput";
import NumberInput from "@components/core-components/input/NumberInput";
import CustomButton from "@components/core-components/button/CustomButton";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Betslip: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [number, setNumber] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs = [
    { label: "Your bets", count: 1459 },
    { label: "Matched", count: 1459 },
    { label: "Unmatched", count: 459 },
  ];

  return (
    <FullPageModal title="BETSLIP" isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full px-2 py-4 overflow-x-hidden main-no-scrollbar ">
        {/* TABS */}
        <div className="flex items-center -mx-2 justify-between w-screen shadow-2xl border-b h-[50px] border-gray-900 mb-2 bg-gray-400">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => setActiveTab(index)}
              className={`relative flex h-full items-center justify-between w-1/3 px-3 gap-1 text-base font-semibold transition-colors duration-200 ${
                activeTab === index ? "bg-gray-600 text-white" : "text-gray-200"
              }`}
            >
              <span>{tab.label}</span>
              <span
                className={`text-body-reg-10 font-normal rounded-[3px] px-1 py-0.5 text-gray-100 ${
                  activeTab === index ? "bg-gray-300" : "bg-gray-300 "
                }`}
              >
                {tab.count}
              </span>
              {activeTab === index && (
                <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-5 rounded-t-[3px] h-1 bg-success"></span>
              )}
            </button>
          ))}
        </div>
        {/* CARD */}
        <div className="w-full text-white rounded-[4px] bg-gray-400">
          <div className="flex flex-row w-full items-center gap-1 justify-between border-b border-gray-300 py-2">
            <div className="flex flex-row items-center gap-1 pl-1">
              <Icon
                iconName="cricket24"
                svgProps={{
                  width: 24,
                  height: 24,
                }}
                className="items-center justify-center"
              />
              <h2 className="text-body-reg-15 font-semibold">
                Guyana Amazon Warriors - Lahore Qalandars
              </h2>
            </div>
            <Icon
              iconName="close26"
              svgProps={{ width: 26, height: 26 }}
              onClick={() => {}}
              className="cursor-pointer pr-1"
            />
          </div>
          <p className="text-base font-semibold pt-4 px-1">Match Odds</p>
          <p className="text-body-reg-15 font-normal pt-4 px-1">
            Lahore Qalandars
          </p>
          <p className="text-sm text-success px-1 pt-1">
            0<span className="text-gray-200 ml-1">-</span> 1848
          </p>

          <div className="flex flex-row w-full items-center pt-3 pb-2 px-1 gap-2">
            <AdjustableValueInput
              title="Lay"
              initialValue={20.5}
              step={1}
              borderColor="border-lay-bet"
              buttonsBgColor="bg-lay-bet"
            />
            <AdjustableValueInput
              title="Stake"
              initialValue={20000}
              step={1}
              borderColor="border-lay-bet"
              buttonsBgColor="bg-lay-bet"
            />
          </div>
          <div className="flex flex-col gap-2 px-1">
            <NumberInput
              title="Profit"
              value={number}
              onChange={() => {}}
              borderColor="border-lay-bet"
            />
          </div>
          <div className="grid grid-cols-4 gap-2 mt-4 px-1">
            {/* Row 1 */}
            {[500, 1000, 5000, "delete"].map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  if (item === "delete") {
                    setNumber((prev) => {
                      const newValue =
                        typeof prev === "string"
                          ? String(prev).slice(0, -1)
                          : String(prev).slice(0, -1);
                      return newValue === "" ? 0 : Number(newValue);
                    });
                  } else {
                    setNumber((prev) => Number(prev) + Number(item));
                  }
                }}
                className={`w-full h-10 bg-transparent text-gray-200 text-sm font-semibold rounded-[4px] text-center border border-gray-200 hover:bg-gray-500 ${
                  item === "delete" ? "bg-gray-200 text-white" : ""
                }`}
              >
                {item === "delete" ? (
                  <Icon
                    iconName="sliderArrowLeft"
                    svgProps={{ width: 14, height: 16 }}
                  />
                ) : typeof item === "number" && item >= 1000 ? (
                  `${item / 1000}k`
                ) : (
                  item
                )}
              </button>
            ))}

            {/* Row 2 */}
            {[20000, 50000, 100000, 0].map((item, index) => (
              <button
                key={index}
                onClick={() => setNumber((prev) => Number(prev) + Number(item))}
                className="w-full h-10 bg-transparent text-gray-200 text-sm font-semibold rounded-[4px] text-center border border-gray-200 hover:bg-gray-500"
              >
                {item === 0 ? "0" : `${item / 1000}k`}
              </button>
            ))}

            {/* Row 3 */}
            {[1, 2, 3, "00"].map((item, index) => (
              <button
                key={index}
                onClick={() => setNumber((prev) => Number(prev) + Number(item))}
                className="w-full h-10 bg-transparent text-gray-200 text-sm font-semibold rounded-[4px] text-center border border-gray-200 hover:bg-gray-500"
              >
                {item}
              </button>
            ))}

            {/* Row 4 */}
            {[4, 5, 6, "000"].map((item, index) => (
              <button
                key={index}
                onClick={() => setNumber((prev) => Number(prev) + Number(item))}
                className="w-full h-10 bg-transparent text-gray-200 text-sm font-semibold rounded-[4px] text-center border border-gray-200 hover:bg-gray-500"
              >
                {item}
              </button>
            ))}

            {/* Row 5 */}
            {[7, 8, 9, "Clear"].map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  if (item === "Clear") {
                    setNumber(0);
                  } else {
                    setNumber((prev) => Number(prev) + Number(item));
                  }
                }}
                className={`w-full h-10 bg-transparent text-gray-200 text-sm font-semibold rounded-[4px] text-center border border-gray-200 hover:bg-gray-500 ${
                  item === "Clear" ? "bg-gray-200 text-gray-500" : ""
                }`}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="flex items-center gap-2 justify-center text-xs font-normal text-gray-200 mt-3 pb-3 pt-1">
            <span>
              MIN STAKE <span className="text-success">50</span>
            </span>
            <span>
              MAX STAKE <span className="text-success">50</span>
            </span>
            <span>
              MAX PROFIT <span className="text-success">50</span>
            </span>
          </div>
        </div>

        {/* FOOTER */}
        <div className="w-full mt-14">
          <CustomButton
            text="Place Bet"
            onClick={() => {}}
            buttonType="success"
          />
          <div className="flex flex-row w-full items-center justify-between px-1 pt-3 text-sm font-semibold text-gray-100">
            <div className="flex flex-col w-full ">
              <span>Guyana Amazon Warriors</span>
              <span className="text-success px-1 pt-1">
                0<span className="text-gray-200 ml-1">-</span>
                <span className="text-danger pl-1">-122</span>
              </span>
            </div>
            <div className="flex flex-col w-full">
              <span>The Draw</span>
              <span className=" text-success px-1 pt-1">
                0<span className="text-gray-200 ml-1">-</span>
                <span className="text-danger pl-1">-122</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </FullPageModal>
  );
};

export default Betslip;
