import CustomButton from "@components/core-components/button/CustomButton";
import InputWithTitle from "@components/core-components/input/InputWithTitle";
import React from "react";

function Api() {
  const [showToken, setShowToken] = React.useState(false);
  const handleRevealToken = () => {
    setShowToken(!showToken);
  };
  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="pt-6 text-white text-[15px]">TOKEN CREATION</p>
        <p className="text-[14px] text-gray-200 leading-none pt-4">
          Disclaimer: Stake.com support will never ask you for this token. In
          giving this token to other people, you are granting them full access
          to your account.
        </p>
        <div className="h-[1px] w-full bg-[#2C3555] my-8"></div>
        <p className="text-[14px] text-gray-200 leading-none pb-[8px]">Token</p>
        <InputWithTitle
          type={showToken ? "text" : "password"}
          title="Copy this code to your authenticator app"
          inputText="SDFKGHKJ3223DSF8SDF98SDFSDFH"
          rightButtonIcon="copy"
          classname="lg:max-w-[404px]"
          rightButtonIconClick={() =>
            navigator.clipboard.writeText("SDFKGHKJ3223DSF8SDF98SDFSDFH")
          }
          isButtonShow={true}
        />
      </div>

      <div className="flex justify-end h-full mt-6 lg:max-w-[404px]">
        <CustomButton
          classname="max-w-[175px]"
          text="Reveal"
          onClick={handleRevealToken}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default Api;
