import React from "react";

const PromotionCard = ({
  icon,
  title,
  subtitle,
  iconWidth,
  iconHeight,
  customStyles,
  top,
  left,
}: {
  icon: string;
  title: string;
  subtitle: string;
  iconWidth: string;
  iconHeight: string;
  customStyles: string;
  top: string;
  left: string;
}) => (
  <div
    className={`relative w-full flex items-center lg:p-4 sm:p-6 gap-2 rounded-lg text-white h-[78px] md:h-[100px] lg:h-[108px] transition-transform duration-200 hover:scale-105 ${customStyles} group`}
    // className={`relative flex items-center p-4 sm:p-6 gap-2 rounded-lg text-white w-full md:w-[320px] lg:w-[375px] xl:w-[413px] h-[78px] md:h-[100px] lg:h-[108px] transition-transform duration-200 hover:scale-105 ${customStyles} group`}
  >
    <img
      src={icon}
      alt={title}
      className={`absolute ${top} ${left} ${iconWidth} ${iconHeight} flex-shrink-0 z-10`}
      style={{
        animation: "shake 1s ease-in-out",
      }}
    />
    <div className="ml-32 lg:ml-40">
      <h3 className="font-black text-lg lg:text-2xl 2xl:text-3xl">{title}</h3>
      <p className="text-sm md:text-base font-semibold">{subtitle}</p>
    </div>
  </div>
);

export default PromotionCard;
