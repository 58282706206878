import CustomButton from "@components/core-components/button/CustomButton";
import React, { useState } from "react";
import RadioCard from "./RadioCard";

const FiatNumberFormatting = () => {
  const [selectedFormat, setSelectedFormat] = useState("format1");

  const handleRadioChange = (value: string) => {
    setSelectedFormat(value);
  };

  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="pt-0 text-white text-[15px]"> FIAT NUMBER FORMATTING</p>
        <div className="flex py-[20px] flex-col">
          <RadioCard
            id="format1"
            title="123,456.78"
            value="format1"
            selectedValue={selectedFormat}
            onChange={handleRadioChange}
            bgColor="bg-gray-400"
          />
          <RadioCard
            id="format2"
            title="۱۲۳٬۴۵۶٫۷۸"
            value="format2"
            selectedValue={selectedFormat}
            onChange={handleRadioChange}
            bgColor="bg-gray-500"
          />
          <RadioCard
            id="format3"
            title="123.456,78"
            value="format3"
            selectedValue={selectedFormat}
            onChange={handleRadioChange}
            bgColor="bg-gray-400"
          />
        </div>
      </div>
      <div className="flex justify-end h-full ">
        <CustomButton
          classname="max-w-[175px]"
          text="Save"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
};

export default FiatNumberFormatting;
