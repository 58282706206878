import React, { ReactNode, useEffect, useRef } from "react";
import Icon from "@components/core-components/icon";

interface ModalProps {
  isOpen: boolean;
  onClose: any;
  content: ReactNode;
  title?: any;
  showTitleBorder?: boolean;
  contentClassNames?: string;
  wrapperClassNames?: string;
  maxWidth?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  content,
  title,
  showTitleBorder = true,
  contentClassNames,
  wrapperClassNames,
  maxWidth = "max-w-[484px]",
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
      console.log("outsidezxs");
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[50] flex items-start justify-center bg-black bg-opacity-60 overflow-y-hidden">
      <div
        className={`relative flex flex-col rounded-xl shadow-lg w-screen ${maxWidth} mx-2 md:mx-auto my-auto max-h-[80vh] md:max-h[95vh] ${wrapperClassNames}`}
        ref={modalRef}
      >
        {title && (
          <div
            className={`flex flex-col justify-center rounded-t-xl h-[70px] px-4 md:px-6 py-4 md:py-8 w-full bg-gray-600 text-white ${
              showTitleBorder ? "border-b border-gray-400" : ""
            }`}
          >
            <div className="h-[32px] py-6 flex flex-row justify-between items-center">
              <div className="font-semibold text-heading-20">{title}</div>
              <Icon
                iconName="closeCircle"
                svgProps={{ height: 32, width: 32 }}
                onClick={onClose}
              />
            </div>
          </div>
        )}
        <div
          className={`bg-gray-600 pb-6 overflow-y-auto rounded-b-xl overflow-x-hidden main-no-scrollbar::-webkit-scrollbar main-no-scrollbar ${contentClassNames}`}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default Modal;
