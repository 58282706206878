import { useEffect, useRef, useState } from "react";
import Icon from "../icon";

interface DropdownButtonProps {
  iconName: string;
  label: string;
  options: {
    label: string;
    onClick: () => void;
    icon?: string;
    value?: string;
  }[];
  iconWidth?: number;
  iconHeight?: number;
  footer?: any;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  iconName,
  label,
  options,
  iconWidth = 20,
  iconHeight = 20,
  footer,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative h-full">
      <button
        className={`flex items-center gap-2 h-full text-white text-sm font-black relative whitespace-nowrap ${
          isOpen ? "border-b-[3px] border-yellow bg-gray-500" : ""
        } px-4 md:px-6`}
        onClick={toggleDropdown}
      >
        <Icon
          iconName={iconName}
          svgProps={{ width: iconWidth, height: iconHeight }}
        />
        {label}
        {iconName !== "profileIconWhite" && (
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>

      {isOpen && (
        <>
          <div className="block lg:hidden">
            <div
              className="fixed left-0 right-0 bottom-0 bg-black opacity-50 z-10"
              style={{
                top: dropdownRef.current?.getBoundingClientRect().bottom,
              }}
            ></div>
          </div>
          <div
            className={` bg-gray-500 text-gray-100 ring-1 ring-black ring-opacity-5 z-50 fixed w-screen left-0 right-0 lg:absolute lg:w-64 lg:left-auto lg:right-0`}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div className="py-2">
              {options.map((option: any, index: number) => (
                <button
                  key={index}
                  onClick={() => {
                    option.onClick();
                    setIsOpen(false);
                  }}
                  className="flex items-center text-left w-full justify-between py-4 px-6 text-sm font-semibold hover:bg-gray-700 border-b border-gray-300"
                >
                  <div className="flex w-full items-center gap-3">
                    {option.icon && (
                      <Icon
                        iconName={option.icon}
                        svgProps={{ width: 17, height: 17 }}
                      />
                    )}
                    {option.label}
                  </div>
                  <span className="flex flex-row justify-end text-white font-semibold text-sm w-40">
                    {option.value}
                  </span>
                </button>
              ))}
            </div>
            {footer && footer}
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownButton;
