import React from "react";
import Icon from "./icon";

const TabsComponent = ({
  tabs,
  activeTab,
  setActiveTab,
  classNames,
  activeTabColor = "bg-pink",
  buttonWidth,
  buttonPaddingX = "px-4",
}: {
  tabs: { id: string; label?: string; icon?: string }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  classNames?: string;
  isHomeScreen?: boolean;
  activeTabColor?: "bg-pink" | "bg-primary";
  buttonWidth?: string;
  buttonPaddingX?: string;
}) => {
  return (
    <div className={`flex items-center justify-between w-full ${classNames}`}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={`w-full ${buttonWidth} min-h-8 ${buttonPaddingX} rounded items-center justify-center transition-colors duration-200 ${
            activeTab === tab.id
              ? `${activeTabColor} ${
                  activeTabColor === "bg-primary"
                    ? "text-gray-600"
                    : "text-white"
                }`
              : "text-white hover:bg-gray-400"
          }`}
        >
          {tab.icon ? <Icon iconName={tab.icon} /> : tab.label}
        </button>
      ))}
    </div>
  );
};

export default TabsComponent;
