import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";
import React from "react";

function OffersSettings() {
  return (
    <div className="w-full lg:max-w-[454px] flex flex-col">
      <div>
        <p className="pt-6 text-white text-body-reg-15 font-semibold">
          WELCOME OFFER
        </p>
        <p className="text-gray-200 text-body-reg-13 pt-3">
          To claim your welcome offer, please enter your code within 24 hours of
          signing up.
        </p>
        <div className="py-6">
          <Textfield type="text" placeholder="Code*" id="code" />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex justify-end h-full gap-6 w-full">
          <CustomButton
            text="Submit"
            classname="max-w-[175px]"
            onClick={() => {}}
            buttonType="primary"
          />
        </div>
      </div>
    </div>
  );
}

export default OffersSettings;
