import React, { useState, useEffect, useRef } from "react";
import Icon from "@components/core-components/icon";

interface SelectOption {
  label: string;
  value: string;
  icon?: string;
}

interface CustomSelectProps {
  options: SelectOption[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  showIcon?: boolean;
  showSearch?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  placeholder = "Select an option",
  showIcon = true,
  showSearch = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const selectRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
    setSearchTerm("");
  };

  const renderLabel = (label: string) => {
    const [mainLabel, subLabel] = label.split(" / ");
    return (
      <>
        {mainLabel}
        {subLabel && (
          <span className="text-gray-300 text-xs font-normal">
            {" "}
            / {subLabel}
          </span>
        )}
      </>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={selectRef}>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex h-[50px] gap-2 items-center justify-between w-full bg-gray-400 hover:bg-gray-500 text-white text-sm font-semibold px-4 py-3 rounded-md cursor-pointer"
      >
        <div className="flex items-center gap-2">
          {showIcon &&
            value &&
            options.find((option) => option.value === value)?.icon && (
              <Icon
                iconName={
                  options.find((option) => option.value === value)?.icon!
                }
                svgProps={{ width: 26, height: 26 }}
              />
            )}
          <span>
            {value
              ? renderLabel(
                  options.find((option) => option.value === value)?.label!
                )
              : placeholder}
          </span>
        </div>
        <Icon
          iconName="downArrow"
          svgProps={{ width: 16, height: 16 }}
          className={`transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div className="absolute top-full min-w-20 mt-2 w-full overflow-x-hidden bg-gray-400 rounded-md shadow-lg z-10 p-4 main-no-scrollbar::-webkit-scrollbar main-no-scrollbar">
          {showSearch && (
            <div className="flex items-center bg-gray-500 px-4 py-2 rounded-md h-12">
              <Icon
                iconName="searchWhite"
                svgProps={{ width: 16, height: 16 }}
              />
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="flex-1 bg-transparent text-white placeholder-gray-200 ml-2 focus:outline-none"
              />
            </div>
          )}
          <ul className="max-h-60 py-4 overflow-y-auto overflow-x-hidden main-no-scrollbar::-webkit-scrollbar main-no-scrollbar">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleOptionClick(option.value)}
                  className={`flex items-center w-full justify-between px-4 py-3 cursor-pointer hover:bg-gray-500 text-white rounded-md ${
                    option.value === value ? "bg-gray-500" : ""
                  }`}
                >
                  <div className="flex items-center gap-2">
                    {showIcon && option.icon && (
                      <Icon
                        iconName={option.icon}
                        svgProps={{ width: 26, height: 26 }}
                      />
                    )}
                    <span>{renderLabel(option.label)}</span>
                  </div>
                  {showIcon && option.value === value && (
                    <Icon
                      iconName="tick"
                      svgProps={{ width: 14, height: 14 }}
                    />
                  )}
                </li>
              ))
            ) : (
              <span className="text-white text-sm px-2">No options found</span>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
