import { Wallet } from "@components/layout-components/types";
import { baseService } from "api/core/baseService";
import axios from "axios";
import { useAppSelector } from "hooks/redux";
import useIsLandscape from "hooks/useIsLandscape";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GameResponse, setMobileGameStarted } from "store/casino/casinoSlice";

function GamePlay() {
  // const {
  //   layoutSettings: { isLandscape },
  // } = useAppSelector((state) => state.casino);
  const isLandscape = useIsLandscape();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const { userId } = useSelector((state: any) => state.auth);
  const { gameId } = useParams();
  const [loading, setLoading] = useState(false);
  const [userBalance, setUserBalance] = useState<Wallet>();
  const [dynamicHeight, setDynamicHeight] = useState(window.innerHeight - 65);
  const location = useLocation();
  const dispatch = useDispatch();

  const [iframeUrl, setIframeUrl] = useState("");
  const getdata = async () => {
    const wallet = await baseService.getById<Wallet>(
      "users/wallet/active/",
      userId
    );
    setUserBalance(wallet);

    const requestData = {
      operatorId: "pixupplay",
      providerName: "JiLi",
      gameId: gameId,
      userId: userId,
      username: "cagatayy",
      platformId: isMobile ? "mobile" : "desktop",
      lobby: false,
      clientIp: "188.119.16.245",
      currency: wallet?.currency[0].code,
      balance: wallet?.total,
      redirectUrl: "https://staging.pixupplay.com",
    };

    axios
      .post("https://staging.pixupplay.com/casino", requestData)
      .then((res) => {
        setIframeUrl(res.data.url);
      })
      .catch((error) => {
        console.error("Hata oluştu:", error);
      });
  };

  useEffect(() => {
    getdata();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1400);
      setDynamicHeight(window.innerHeight - 75);
    };

    if (isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("resize", handleResize);
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    dispatch(setMobileGameStarted(location.pathname.includes("play")));
  }, [location.pathname]);
  return (
    <div
      style={{
        // marginBottom: isLandscape ? "10px" : "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        height: isLandscape ? window.innerHeight : dynamicHeight,
      }}
    >
      <div
        className="m-0 bg-black overflow-hidden"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: isLandscape && isMobile ? "10px" : "0px",

          width: "100%",
          height:
            isLandscape && isMobile
              ? `${window.innerHeight - 30}px`
              : isMobile
              ? `${dynamicHeight}px`
              : "90%",
        }}
      >
        {loading ? (
          <div
            className="flex items-center justify-center text-white"
            style={{ minHeight: isMobile ? "75%" : "90%" }}
          >
            Loading game...
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            src={iframeUrl}
            width={isLandscape && isMobile ? "100%" : "90%"}
            height="100%"
            style={{ border: "none" }}
            title="Game Frame"
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default GamePlay;
