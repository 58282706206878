import CustomButton from "@components/core-components/button/CustomButton";
import InputWithTitle from "@components/core-components/input/InputWithTitle";
import Textfield from "@components/core-components/input/Textfield";
import React from "react";
import Qr from "../../../../../assets/images/Screenshot 2024-10-28 at 08.55.11 1.png";

function TwoFactorSecurity() {
  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="text-white text-[15px]">TWO FACTOR</p>
        <p className="text-sm text-gray-200 leading-none py-4">
          To keep your account extra secure leave a two factor authentication
          enabled.
        </p>
        <div className="h-[1px] w-full bg-[#2C3555] mb-[23px]"></div>
        <InputWithTitle
          title="Copy this code to your authenticator app"
          inputText="SDFKGHKJ3223DSF8SDF98SDFSDFH"
          rightButtonIcon="copy"
          classname="max-w-[404px]"
          rightButtonIconClick={() =>
            navigator.clipboard.writeText("SDFKGHKJ3223DSF8SDF98SDFSDFH")
          }
          isButtonShow={true}
        />
        <p className="text-xs text-gray-200 leading-none pt-6 pb-3">
          Don’t let anyone see this
        </p>
        <div className="mb-[23px]">
          <img src={Qr} alt="qr" />
        </div>
        <div className="flex flex-col gap-[15px]">
          {" "}
          <Textfield
            type="password"
            className="max-w-[404px]"
            placeholder="Password*"
            id="password"
          />{" "}
          <Textfield
            type="password"
            className="max-w-[404px]"
            placeholder="Two Factor Code**"
            id="twoFactorCode"
          />{" "}
        </div>
        <div className="h-[1px] w-full bg-[#2C3555] my-6"></div>
      </div>

      <div className="flex justify-end h-full w-full">
        <CustomButton
          classname="max-w-[175px]"
          text="Submit"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default TwoFactorSecurity;
