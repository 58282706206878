import React from "react";
import Icon from "@components/core-components/icon";

function IgnoredUsers() {
  return (
    <div className="w-full flex flex-col h-full min-h-48 items-center justify-center relative">
      <Icon
        iconName="transparentPixupLogo"
        svgProps={{ width: 209, height: 131 }}
        className="absolute"
      />
      <div className="relative text-center">
        <p className="text-white text-lg font-semibold">IGNORED USERS</p>
        <p className="text-gray-200 text-sm font-normal">
          No ignored users to show
        </p>
      </div>
    </div>
  );
}

export default IgnoredUsers;
