import Breadcrumb from "@components/core-components/breadcrumb";
import React, { useEffect, useState } from "react";
import Boxer from "../../../assets/images/desktop-sponsor-03.avif";
import BoxerMd from "../../../assets/images/tablet-sponsor-03.avif";
import UfcFighters from "../../../assets/images/desktop-sponsor-01.avif";
import UfcFightersMd from "../../../assets/images/tablet-sponsor-01.avif";
import UfcLogo from "../../../assets/images/ufc-logo.png";
import UfcBoxers from "../../../assets/images/desktop-sponsor-02.avif";
import UfcBoxersMd from "../../../assets/images/tablet-sponsor-02.avif";

function Sponsorships() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [backgroundImage, setBackgroundImage] = useState(UfcFighters);
  const [ufcBoxersImage, setUfcBoxersImage] = useState(UfcBoxers);
  const [boxerImage, setBoxerImage] = useState(Boxer);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 1024) {
      setBackgroundImage(UfcFighters);
      setUfcBoxersImage(UfcBoxers);
      setBoxerImage(Boxer);
    } else if (screenWidth >= 640 && screenWidth <= 1024) {
      setBackgroundImage(UfcFightersMd);
      setUfcBoxersImage(UfcBoxersMd);
      setBoxerImage(BoxerMd);
    } else if (screenWidth < 640) {
      setBackgroundImage(UfcFightersMd);
      setUfcBoxersImage(UfcBoxersMd);
      setBoxerImage(BoxerMd);
    }
  }, [screenWidth]);

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Sponsorship", href: "/sponsorship" },
  ];

  return (
    <div className="mt-2 md:mt-3 lg:pt-6 text-white">
      <div className="hidden md:block px-8">
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <section className="relative flex flex-col 2xl:flex-row items-start 2xl:items-center justify-between mt-0 md:mt-[35px] lg:mt-6">
        <div className="flex-shrink-0 relative w-full">
          <img
            src={backgroundImage}
            style={{
              mixBlendMode: "lighten",
              opacity: 1,
            }}
            alt="Ufc Fighters"
            className="w-full h-auto min-h-[171px] max-h-[302px] lg:max-h-[628px] relative object-cover"
          />
        </div>
        <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2">
          <img
            src={UfcLogo}
            alt="UFC Logo"
            className="w-[156px] h-auto md:w-[256px]"
          />
        </div>
      </section>
      <section className="px-4 mt-10 md:mt-[50px] 2xl:mt-[107px] items-center justify-center text-center max-w-[1120px] mx-auto">
        <div className="flex flex-col">
          <span className="text-2xl md:text-heading-40 2xl:text-6xl font-black mb-4 md:mb-5 lg:mb-6">
            A New Partnership
          </span>
          <p className="text-gray-100 text-sm md:text-lg mb-2">
            Be rewarded for enjoying Pragmatic's range of slots games on Stake!
          </p>
          <p className="text-gray-100 text-sm md:text-lg mb-2">
            Every month, we award $2,000,000 in random drops to Pragmatic slot
            players!
          </p>
          <p className="text-gray-100 text-sm md:text-lg mb-2">
            Join for Daily Prize Drops and Daily Tournaments! For a full list of
            Pragmatic's eligible games, click the link below.
          </p>
          <div className="flex w-full justify-center mt-10">
            <img
              src={ufcBoxersImage}
              alt="Ufc Boxers"
              className="w-screen md:max-w-[700px] lg:max-w-[984px] h-auto lg:max-h-[554px] md-max-h-[395px] object-contain mb-12 md:mb-[66px] 2xl:mb-20"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl md:text-heading-40 2xl:text-6xl font-black mb-4 md:mb-5 lg:mb-6 ">
            Branded Presence
          </span>
          <p className="text-gray-100 text-sm md:text-lg mb-2">
            Pixup receives a wide range of sponsorship assets in this
            collaboration including a branded presence in the Octagon for key
            Pay-Per-View and Fight Night events. Pixup is the proud presenting
            bet partner of the UFC as seen in massive weekends at UFC 274 in
            Phoenix, USA and UFC 283 & UFC 301 in Rio de Janeiro, Brazil. These
            events saw Stake branding all across the prestigious arenas as
            legendary fighters like Charles Oliveira, Carla Esparza, Brandon
            Moreno & Glover Teixeira took to the Octagon. Find out more about
            our UFC ambassadors Alexa Grasso , Jailton Almeida, Israel
            Adesanya , Alex Pereira , Caio Borralho , Merab
            Dvalishvili and Alexandre Pantoja .
          </p>
          <div className="flex w-full justify-center mt-10">
            <img
              src={boxerImage}
              alt="Boxer"
              className="w-screen md:max-w-[700px] lg:max-w-[984px] h-auto lg:max-h-[554px] md-max-h-[395px] object-contain mb-12 md:mb-[66px] 2xl:mb-20"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-2xl md:text-heading-40 2xl:text-6xl font-black mb-4 md:mb-5 lg:mb-6 ">
            Far-Reaching Collaboration
          </span>
          <p className="text-gray-100 text-sm md:text-lg mb-2">
            “Stake.com has been a great partner the past years, and we’re
            thrilled to continue the relationship,” - UFC Vice President of
            Global Partnerships Nick Smith. “With sports betting becoming more
            widely accessible, we have added Brazil, a very important emerging
            gaming market, to a far-reaching collaboration. We look forward to
            working with Stake.com to continue to offer a unique way for fans to
            experience the action and excitement of UFC through Stake’s
            innovative and emerging gaming platform.”
          </p>
        </div>
      </section>
    </div>
  );
}

export default Sponsorships;
