import MenuList from "@components/core-components/MenuList";
import GameCard from "@components/core-components/card/GameCard";
import CustomSwiper from "@components/core-components/swiper";
import React, { useEffect, useState } from "react";
import SearchBar from "@components/core-components/input/SearchBar";
import { casinoGames, fetchGames } from "store/casino/casinoSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import SearchModal from "../../components/SearchModal";
import FullPageModal from "@components/core-components/modal/FullPageModal";
import { sanitizeUrl } from "helper/SanitizeUrl";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Menu: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const games = useAppSelector(casinoGames);
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState("");
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  // useEffect(() => {
  //   dispatch(fetchGames());
  // }, [dispatch]);

  const menuItems = {
    Promotions: "promotionIcon",
    Affiliate: "affiliate",
    "VIP Club": "vipClub",
    Blog: "blog",
    Forum: "forum",
    Sponsorship: "sponsorship",
    "Responsible Gambling": "responsibleGambling",
  };

  const handleTabClick = (tab: string) => {
    const cleanedUrl = sanitizeUrl(tab);
    if (cleanedUrl === "Casino") {
      navigate("/lounge");
    } else {
      navigate(`/${cleanedUrl}`);
    }
  };

  return (
    <FullPageModal title="MENU" isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full pt-6 overflow-x-hidden main-no-scrollbar">
        <div className="flex w-full items-center justify-between px-3">
          <SearchBar
            onClick={handleOpenSearchModal}
            value=""
            onChange={() => {}}
            preventFocus={true}
            bgColor="bg-gray-500"
            borderColor="border-gray-300"
          />
        </div>
        <SearchModal
          isOpen={isSearchModalVisible}
          onClose={() => setSearchModalVisible(false)}
        />
        <div className="pt-6 w-full px-3 md:px-0 pb-6 border-b border-gray-300">
          <CustomSwiper
            title="New Release"
            items={games}
            renderItem={(game) => (
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status === "ACTIVE" ? false : true}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <MenuList
          menuItems={menuItems}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          onMenuItemClick={handleTabClick}
        />
      </div>
    </FullPageModal>
  );
};

export default Menu;
