import Icon from "@components/core-components/icon";
import React, { useState } from "react";
import GameDetailModal from "./GameDetailModal";

interface IRowData {
  game: string;
  betId: string;
  date: string;
  betAmount: string;
  multiplier: string;
  payout: string;
}

interface MyBetsTableMobileProps {
  data: IRowData[];
}

const MyBetsTableMobile: React.FC<MyBetsTableMobileProps> = ({ data }) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBetId, setSelectedBetId] = useState<string | null>(null);

  const toggleRowExpansion = (betId: string) => {
    setExpandedRow(expandedRow === betId ? null : betId);
  };

  const openModal = (betId: string) => {
    setSelectedBetId(betId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBetId(null);
    setIsModalOpen(false);
  };

  const fields = [
    { label: "BET ID", value: "betId" },
    { label: "DATE", value: "date" },
    { label: "BET AMOUNT", value: "betAmount", icon: "usdt2" },
    { label: "MULTIPLIER", value: "multiplier" },
  ];

  const scrollRef = React.createRef<HTMLUListElement>();
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const target = e.currentTarget;
    if (target.scrollTop + target.clientHeight < target.scrollHeight) {
      e.stopPropagation();
    }
  };

  return (
    <>
      <div className="bg-gray-500 rounded-b-xl pb-4">
        <div className={`flex flex-col shadow-md px-4 py-2 w-full bg-gray-600`}>
          <div className="flex flex-row justify-between gap-4 w-full cursor-pointer">
            <div className="flex flex-row items-center flex-1">
              <p className="text-body-reg-14 font-black text-gray-200">GAME</p>
            </div>
            <div className="flex flex-row items-center flex-1">
              <p className="text-body-reg-14 font-black text-gray-200">
                BET ID
              </p>
            </div>
            <div className="flex flex-row items-center flex-1 justify-center">
              <p className="text-body-reg-14 font-black text-gray-200 ">
                PAYOUT
              </p>
            </div>
          </div>
        </div>
        <ul
          ref={scrollRef}
          onScroll={handleScroll}
          className="max-h-[716px] overflow-y-auto rounded-b-x .main-no-scrollbar::-webkit-scrollbar main-no-scrollbar"
        >
          {data.map((game, index) => (
            <li
              key={index}
              className={`flex flex-col shadow-md p-3 w-full odd:bg-gray-400 even:bg-gray-500`}
            >
              <div
                className={`flex flex-row justify-between gap-4 w-full cursor-pointer ${
                  expandedRow !== game.betId
                    ? "py-1"
                    : "border-b border-gray-300 pb-4 pt-1"
                }`}
                onClick={() => toggleRowExpansion(game.betId)}
              >
                <div className="flex flex-row gap-[10px] items-center flex-1 overflow-hidden">
                  <Icon
                    iconName="slots"
                    className="mt-[3px]"
                    svgProps={{ width: 18, height: 18 }}
                  />
                  <p className="text-body-reg-14 font-black text-white truncate">
                    {game.game}
                  </p>
                </div>
                <div
                  className="flex flex-row gap-[10px] items-center flex-1 overflow-hidden"
                  onClick={() => openModal(game.betId)}
                >
                  <Icon
                    iconName="id"
                    className="mt-[3px]"
                    svgProps={{ width: 18, height: 18 }}
                  />
                  <p className="text-body-reg-14 font-black text-white truncate">
                    {game.betId}
                  </p>
                </div>
                <div className="flex flex-row gap-[10px] flex-1 justify-end items-center">
                  <p
                    className={`text-body-reg-14 font-black ${
                      game.payout.startsWith("-")
                        ? "text-gray-100"
                        : "text-green-500"
                    }`}
                  >
                    {game.payout}
                  </p>
                  <Icon
                    iconName="downArrow"
                    svgProps={{ width: 18, height: 18 }}
                    className={`transform transition-transform duration-200 ${
                      expandedRow === game.betId ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>
              <div
                className={`transition-all duration-500 ease-in-out overflow-hidden ${
                  expandedRow === game.betId ? "max-h-[500px]" : "max-h-0"
                }`}
              >
                {expandedRow === game.betId && (
                  <div className="flex flex-col">
                    {fields.map((field, fieldIndex) => (
                      <div
                        key={field.value}
                        className={`flex flex-row items-center justify-between w-full py-3 ${
                          fieldIndex !== fields.length - 1
                            ? "border-b border-gray-300"
                            : ""
                        }`}
                      >
                        <p className="text-body-reg-13 font-semibold text-gray-200">
                          {field.label}
                        </p>
                        <div className="flex flex-row gap-[10px] items-center">
                          {field.icon && (
                            <Icon
                              iconName={field.icon}
                              className="mt-[2px]"
                              svgProps={{ width: 18, height: 18 }}
                            />
                          )}
                          <span className="text-white text-body-reg-13 font-normal">
                            {game[field.value as keyof typeof game]}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <GameDetailModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default MyBetsTableMobile;
