import React from "react";
import Icon from "@components/core-components/icon";

interface SelectOption {
  id?: string;
  label: string;
  value: string;
}

interface CustomSelectSecondaryProps {
  options: SelectOption[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  background?: string;
  color?: string;
  id: string;
  iconName?: string;
  svgProps?: {
    width: number;

    height: number;
  };
  showLabel?: boolean;
}

const SelectInput: React.FC<CustomSelectSecondaryProps> = ({
  options,
  value,
  onChange,
  id,
  placeholder = "Select an option",
  background = "bg-white",
  color = "text-gray-900",
  iconName = "downArrowBlack",
  svgProps = { width: 11, height: 7 },
  showLabel = true,
}) => {
  const handleClick = () => {
    const selectElement = document.getElementById(id) as HTMLSelectElement;
    if (selectElement) {
      selectElement.click();
    }
  };

  return (
    <div className="relative w-full">
      <div
        className={`relative flex items-center h-[50px] w-full text-sm font-semibold px-4 py-3 rounded-md cursor-pointer ${background}`}
      >
        <select
          id={id}
          onClick={(e) => e.stopPropagation()}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="absolute inset-0 w-full   p-0 pt-3 z-10  h-full opacity-0 cursor-pointer outline-none"
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {/* Floating Label */}
        {showLabel && (
          <label
            htmlFor={id}
            onClick={handleClick}
            className={`absolute font-normal left-[16px] cursor-pointer transform -translate-y-1/2 text-sm transition-all duration-300 ${
              value
                ? "top-[16px] text-xs text-gray-200"
                : "top-1/2 text-gray-200"
            }`}
          >
            {placeholder}
          </label>
        )}

        <p className={`${value ? color : "text-gray-200"} flex-1  p-0 pt-3 `}>
          {value ? options.find((option) => option.value === value)?.label : ""}{" "}
        </p>

        <Icon
          iconName={iconName}
          svgProps={svgProps}
          className="absolute right-4 transform transition-transform duration-200"
        />
      </div>
    </div>
  );
};

export default SelectInput;
