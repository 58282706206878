import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";
import CustomPhoneInput from "@components/core-components/phoneInput";
import React from "react";

function GeneralSettingsSecondPart() {
  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="text-white text-[15px]">PHONE NUMBER</p>
        <div className="h-[1px] w-full bg-[#2C3555] mb-6 mt-4"></div>
        <p className="text-sm text-gray-200 leading-none pb-6">
          We only service areas that are listed in the available country code
          list.
        </p>
        <CustomPhoneInput />
        {/* <Textfield
          type="text"
          className="max-w-[404px]"
          placeholder="Phone Number*"
          id="phoneNumber"
        />{" "} */}
        <div className="h-[1px] w-full bg-[#2C3555] my-6"></div>
      </div>
      <div className="flex justify-end h-full gap-6w-full">
        <CustomButton
          classname="max-w-[175px]"
          text="Submit"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default GeneralSettingsSecondPart;
