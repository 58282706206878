import React, { useState } from "react";
import Icon from "@components/core-components/icon";

interface CategoryListProps {
  categories: { [key: string]: string };
  activeCategory: string;
  setActiveCategory: (category: string) => void;
}

const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  activeCategory,
  setActiveCategory,
}) => {
  return (
    <div className="mt-4 pb-4 md:border-b border-gray-300 items-start">
      {Object.keys(categories).map((category) => (
        <button
          key={category}
          onClick={() => setActiveCategory(category)}
          className={`flex items-center w-full h-[53px] text-sm gap-3 font-semibold px-3 transition-colors duration-200 ${
            activeCategory === category
              ? "text-white bg-gray-900 border-l-4 border-[#C13EDA]"
              : "text-gray-200 hover:bg-gray-400"
          }`}
        >
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex items-center gap-3">
              <Icon
                iconName={categories[category]}
                svgProps={{
                  width: 17,
                  height: 17,
                }}
              />
              <span style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                {category}
              </span>
            </div>
            <div className="block md:hidden">
              <Icon
                iconName="rightArrow"
                svgProps={{
                  width: 12,
                  height: 12,
                }}
              />
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default CategoryList;
