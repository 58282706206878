import React from "react";
import Modal from "@components/core-components/modal";
import CustomButton from "../button/CustomButton";
import Icon from "../icon";

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenDepositModal: () => void;
  onOpenWithdrawModal: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({
  isOpen,
  onClose,
  onOpenDepositModal,
  onOpenWithdrawModal,
}) => {
  const handleDepositClick = () => {
    onClose();
    onOpenDepositModal();
  };

  const handleWithdrawClick = () => {
    onClose();
    onOpenWithdrawModal();
  };

  const currencies = [
    {
      id: 1,
      name: "BTC",
      fullName: "Bitcoin",
      amount: "54.2904748 BTC",
      equivalent: "$54.34 USD",
      icon: "btc24",
    },
    {
      id: 2,
      name: "INR",
      fullName: "Indian Rupee",
      amount: "12.00 INR",
      equivalent: "$0.14 USD",
      icon: "inr24",
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-[540px]"
      title={
        <div className="flex justify-center items-center w-full">
          <span className="text-lg font-semibold text-white">WALLET</span>
        </div>
      }
      content={
        <>
          <div className={"px-4 md:px-10 pt-6 w-full"}>
            <Icon iconName="usd50" svgProps={{ width: 50, height: 50 }} />
            <div className="flex flex-col items-center gap-1 mt-4 pb-7 border-b border-gray-400">
              <span className="text-white text-heading-24 font-black">
                $970,00 <span className="text-base font-normal">USD</span>
              </span>
              <span className="text-gray-200 text-sm font-semibold">
                Estimated Balance
              </span>
            </div>
          </div>
          <div className={"flex flex-col px-4 md:px-10 pt-5 w-full gap-3"}>
            <span className="text-gray-100 text-base font-semibold">
              Your Currencies
            </span>
            <div className="flex flex-col gap-3">
              {currencies.map((currency) => (
                <div
                  key={currency.id}
                  className="flex justify-between items-center bg-gray-400 p-4 rounded-md"
                >
                  <div className="flex items-center gap-4">
                    <Icon
                      iconName={currency.icon}
                      svgProps={{ width: 24, height: 24 }}
                    />
                    <div className="flex flex-col">
                      <span className="text-white font-bold text-sm">
                        {currency.name}
                      </span>
                      <span className="text-gray-200 text-xs font-normal">
                        {currency.fullName}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col text-right">
                    <span className="text-white font-bold text-sm">
                      {currency.amount}
                    </span>
                    <span className="text-gray-200 text-xs font-normal">
                      {currency.equivalent}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <CustomButton
              text="Deposit"
              onClick={handleDepositClick}
              buttonType="primary"
            />
            <CustomButton
              text="Withdraw"
              onClick={handleWithdrawClick}
              buttonType="secondary"
            />
            <span className="flex w-full items-center justify-center text-gray-200 text-xs font-semibold py-3">
              Improve your account security with Two-Factor Authentication
            </span>
            <CustomButton
              text="Enable 2FA"
              onClick={() => {}}
              buttonType="secondary"
            />
          </div>
        </>
      }
    />
  );
};

export default WalletModal;
