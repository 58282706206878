import React from "react";
import SwitchCard from "./SwitchCard";
import CustomButton from "@components/core-components/button/CustomButton";

function CommunitySettings() {
  const [excludeFromRain, setExcludeFromRain] = React.useState(false);
  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="pt-0 text-white text-[15px]">COMMUNITY</p>

        <div className="flex py-[20px] flex-col">
          <SwitchCard
            title="Exclude from rain"
            description="Prevents you from receiving a rain in chat"
            isChecked={excludeFromRain}
            onToggle={(checked) => setExcludeFromRain(checked)}
            bgColor="bg-gray-400"
          />
        </div>
      </div>
      <div className="flex justify-end h-full gap-6 w-full">
        <CustomButton
          classname="max-w-[175px]"
          text="Save"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default CommunitySettings;
