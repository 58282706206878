import axiosInstance from "config/axiosInstance";

export const baseService = {
  getAll: async <T>(url: string) => {
    try {
      const response = await axiosInstance.get<T>(url);
      return response.data;
    } catch (error: any) {
      console.log("baseService", error);
      // if (error.response && error.response.status === 401) {
      //   window.location.href = "/";
      // }
      throw new Error(error);
    }
  },
  getById: async <T>(url: string, id: number) => {
    try {
      const response = await axiosInstance.get<T>(url + id);
      return response.data;
    } catch (error: any) {
      console.log("baseService getById", error);
      // if (error.response && error.response.status === 401) {
      //   window.location.href = "/";
      // }
      throw new Error(error);
    }
  },
  add: async <T>(url: string, data: any) => {
    try {
      const response = await axiosInstance.post<T>(url, data);
      return response.data;
    } catch (error: any) {
      console.log("baseService add", error);
      // if (error.response && error.response.status === 401) {
      //   throw new Error(error);
      // }
      throw new Error(error);
    }
  },
  update: async <T>(url: string, data: any) => {
    try {
      const response = await axiosInstance.put<T>(url, data);
      return response.data;
    } catch (error: any) {
      console.log("baseService update", error);
      // if (error.response && error.response.status === 401) {
      //   window.location.href = "/";
      // }
      throw new Error(error);
    }
  },
  delete: async <T>(url: string, id: number) => {
    try {
      const response = await axiosInstance.delete<T>(url + id);
      return response.data;
    } catch (error: any) {
      console.log("baseService delete", error);
      // if (error.response && error.response.status === 401) {
      //   window.location.href = "/";
      // }
      throw new Error(error);
    }
  },
};
