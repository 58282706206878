import React from "react";
import Icon from "@components/core-components/icon";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  label: string;
  href: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  className?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, className }) => {
  return (
    <nav className={`text-body-reg-15 font-semibold mb-4 ${className}`}>
      <ul className="flex">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <li>
              <Link to={item.href}>{item.label}</Link>
            </li>
            {index < items.length - 1 && (
              <li>
                <Icon
                  iconName="rightArrow"
                  svgProps={{
                    width: 6,
                    height: 18,
                    className:
                      "mx-2 items-center justify-center self-center text-center",
                  }}
                />
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
