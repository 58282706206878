import React from "react";
import Icon from "../icon";

interface SearchBarProps {
  onClick: () => void;
  classNames?: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  preventFocus?: boolean;
  bgColor?: string;
  borderColor?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onClick,
  value,
  classNames,
  onChange,
  preventFocus = true,
  bgColor = "bg-gray-900",
  borderColor = "border-gray-400",
}) => {
  const handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    if (preventFocus) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <button
      onClick={onClick}
      className={`flex items-center min-h-12 gap-4 h-full ${bgColor} border ${borderColor} rounded-lg pl-4 py-3 w-full ${classNames}`}
    >
      <Icon
        iconName="searchWhite"
        svgProps={{
          width: 16,
          height: 16,
        }}
      />
      <input
        type="text"
        placeholder="Search your game..."
        className="bg-transparent flex-1 text-sm font-medium text-gray-200 placeholder-gray-200 focus:outline-none"
        style={{ fontSize: "16px" }} // Ensure font size is 16px or higher
        onMouseDown={handleMouseDown}
        onClick={!preventFocus ? onClick : undefined}
        value={value}
        onChange={onChange}
      />
    </button>
  );
};

export default SearchBar;
