import React from "react";

function ActiveTokens() {
  return (
    <div className="w-full flex flex-col ">
      <div>
        <p className="pt-0 text-white text-[15px]">ACTIVE TOKENS</p>

        <p className="text-sm text-gray-200 leading-none pb-0">
          Stake currently operates with a closed API and therefore the use of
          these tokens are very limited. Every time you choose to reveal a new
          API token using the "Token Creation" form, you are initiating a new
          independent session. Old tokens are not deactivated until you use the
          click to disable all tokens.
        </p>
      </div>
    </div>
  );
}

export default ActiveTokens;
