import React, { useEffect, useState } from "react";
import slotIcon from "../../../../assets/images/megaways.avif";
import jackpotIcon from "../../../../assets/images/jackpots.avif";
import dropWinsIcon from "../../../../assets/images/drop-and-wins.avif";
import Carousel from "./Carousel";
import GameCard from "../../../../components/core-components/card/GameCard";
import TabsComponent from "../../../../components/core-components/TabsComponent";
import ProviderCard from "../../../../components/core-components/card/ProviderCard";
import PromotionCard from "../../../../components/core-components/card/PromotionCard";
import CasinoTable from "./CasinoTable";
import "swiper/css";
import { providers } from "../../../../data/data";
import CustomSwiper from "@components/core-components/swiper";
import SearchModal from "./SearchModal";
import SearchBar from "@components/core-components/input/SearchBar";
import { casinoGames } from "store/casino/casinoSlice";
import { useAppSelector } from "hooks/redux";
import MobileCasinoTable from "./MobileCasinoTable";
import CampaignPanel from "./CampaignPanel";

function Main() {
  const games = useAppSelector(casinoGames);
  const [activeTab, setActiveTab] = useState("lobby");
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  const campaigns = [
    {
      title: "CASINO BONUS",
      subtitle: "New Customers Only",
      imgName: "test-carousel",
      vectorName: "purpleVector",
    },
    {
      title: "BETTING BONUS",
      subtitle: "New Customers Only",
      imgName: "football",
      vectorName: "blueVector",
    },
    {
      title: "CRICKET BONUS",
      subtitle: "New Customers Only",
      imgName: "cricket",
      vectorName: "greenVector",
    },
    {
      title: "CASINO BONUS",
      subtitle: "New Customers Only",
      imgName: "test-carousel",
      vectorName: "purpleVector",
    },
  ];
  const tabs = [
    { id: "lobby", label: "Lobby" },
    { id: "slots", label: "Slots" },
    { id: "liveCasino", label: "Live Casino" },
    { id: "gameShows", label: "Game Shows" },
    { id: "crashGames", label: "Crash Games" },
    { id: "newRelease", label: "New Release" },
  ];

  return (
    <div className={`px-1 md:px-3 lg:px-6 2xl:px-8 2xl:pt-4`}>
      <div className="mx-3 md:mx-0">
        <Carousel
          items={campaigns}
          renderItem={(campaign) => (
            <CampaignPanel
              title={campaign.title}
              subtitle={campaign.subtitle}
              imgName={campaign.imgName}
              vectorName={campaign.vectorName}
            />
          )}
        />
      </div>
      <SearchModal
        isOpen={isSearchModalVisible}
        onClose={() => setSearchModalVisible(false)}
      />
      <div
        className={`flex xl:flex-row flex-col h-auto w-full overflow-x-scroll whitespace-nowrap pt-3 px-3 md:pt-6 md:px-0 gap-4 main-no-scrollbar::-webkit-scrollbar main-no-scrollbar text-body-reg-13 font-semibold md:text-body-reg-15 md:font-normal`}
      >
        <TabsComponent
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          classNames={`border border-solid bg-[#0D1120] overflow-x-auto overflow-y-hidden border-[#2C3555] rounded-lg p-2 w-full h-12 main-no-scrollbar::-webkit-scrollbar main-no-scrollbar`}
          buttonWidth={"w-[100px] md:w-full"}
        />
        <div className="">
          <SearchBar
            onClick={handleOpenSearchModal}
            classNames="xl:min-w-[200px]"
            value=""
          />
        </div>
      </div>
      <div className="pt-6 w-full pl-3 md:px-0">
        <CustomSwiper
          title="Slots"
          items={games}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
      <div className="pt-6 md:pt-6 w-full pl-3 md:px-0">
        <CustomSwiper
          title="Live Casino"
          items={games}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
      <div className="pt-6 md:pt-6 w-full pl-3 md:-mx-3">
        <CustomSwiper
          title="Providers"
          items={providers.map((provider, index) => ({
            ...provider,
            id: index,
          }))}
          height="100 px"
          showPagination={false}
          showViewAll={false}
          renderItem={(provider) => (
            <ProviderCard logo={provider.logo} name={provider.name} />
          )}
        />
      </div>
      <div className="py-9 md:py-16 w-full px-3 md:px-0">
        <div className="flex flex-wrap xl:flex-nowrap xl:flex flex-col lg:flex-row gap-4 md:gap-8 justify-center lg:justify-between items-center">
          <PromotionCard
            icon={slotIcon}
            title="MEGAWAYS"
            subtitle="All the Best Slots"
            iconWidth="w-[100px] md:w-[138px] lg:w-[141px] 2xl:w-[181px]"
            iconHeight="h-[100px] md:h-[138px] lg:h-[141px] 2xl:h-[181px]"
            customStyles="bg-gradient-to-r from-[#D02462] to-[#ED535A]"
            top="lg:top[13px] md:top[13px]"
            left="left-0"
          />
          <PromotionCard
            icon={jackpotIcon}
            title="JACKPOTS"
            subtitle="Massive Jackpots"
            iconWidth="w-[100px] md:w-[138px] lg:w-[141px] 2xl:w-[161px]"
            iconHeight="h-[100px] md:h-[138px] lg:h-[141px] 2xl:h-[161px]"
            customStyles="bg-gradient-to-r from-[#223A83] to-[#5780F9]"
            top="lg:top[13px] md:top[13px]"
            left="left-4"
          />
          <PromotionCard
            icon={dropWinsIcon}
            title="DROP & WINS"
            subtitle="Big Prize"
            iconWidth="w-[120px] md:w-[158px] lg:w-[171px] 2xl:w-[181px]"
            iconHeight="h-[120px] md:h-[158px] lg:h-[171px] 2xl:h-[181px]"
            customStyles="bg-[linear-gradient(260deg,#B85690_1.24%,#792ADF_100%)]"
            top="lg:top[13px] md:top[13px]"
            left="left-4"
          />
        </div>
      </div>
      <div className="w-full pl-3 md:px-0">
        <CustomSwiper
          title="Game Shows"
          items={games}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
      <div className="pt-6 md:pt-6 w-full pl-3 md:px-0">
        <CustomSwiper
          title="Crash Games"
          items={games}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
      <div className="pt-6 md:pt-6 w-full pl-3 md:px-0">
        <CustomSwiper
          title="New Release"
          items={games}
          renderItem={(game) => (
            <GameCard
              image={game.url_thumb}
              id={game.game_id}
              name={game.game_name}
              altText={game.game_name}
              notAvailable={game.status === "ACTIVE" ? false : true}
              showPlayCount={true}
            />
          )}
        />
      </div>
      <div className="pt-6 md:pt-6 w-full px-3 md:px-0">
        <div className="flex justify-between items-center text-white text-lg font-black mb-4">
          <span>Bets</span>
          <button className="text-sm">View All</button>
        </div>
        <div className="hidden md:block">
          <CasinoTable />
        </div>
        <div className="block md:hidden">
          <MobileCasinoTable games={[]} />
        </div>
      </div>
    </div>
  );
}

export default Main;
