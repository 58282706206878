import React, { useState, useEffect, useRef } from "react";
import Icon from "@components/core-components/icon";

interface SelectOption {
  id?: string;
  label: string;
  value: string;
}

interface CustomSelectSecondaryProps {
  options: SelectOption[];
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  background?: string;
  color?: string;
  iconName?: string;
  svgProps?: {
    width: number;
    height: number;
  };
  showLabel?: boolean;
}

const CustomSelectSecondary: React.FC<CustomSelectSecondaryProps> = ({
  options,
  value,
  onChange,
  placeholder = "Select an option",
  background = "bg-white",
  color = "text-gray-900",
  iconName = "downArrowBlack",
  svgProps = { width: 11, height: 7 },
  showLabel = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={selectRef}>
      {showLabel && (
        <label
          onClick={() => setIsOpen((prev) => !prev)}
          className={`absolute text-gray-200 left-[17px] cursor-pointer transform -translate-y-1/2 text-sm transition-all duration-300 ${
            value ? "top-4 text-xs" : "top-1/2"
          }`}
        >
          {placeholder}
        </label>
      )}
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={`flex h-[50px] gap-2 items-center justify-between w-full text-sm font-semibold px-4 py-3 rounded-md cursor-pointer ${background}`}
      >
        <span className={value ? color : "text-gray-200"}>
          {value ? options.find((option) => option.value === value)?.label : ""}
        </span>
        <Icon
          iconName={iconName}
          svgProps={svgProps}
          className={`transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div
          className={`absolute top-full w-full mt-2 rounded-md shadow-lg z-10 ${background}`}
        >
          <ul className="max-h-60 overflow-y-auto py-2 main-no-scrollbar::-webkit-scrollbar main-no-scrollbar">
            {options.map((option, index) => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                className={`flex items-center justify-between px-1 mx-4 cursor-pointer ${color} lg:hover:bg-gray-100 ${
                  index !== options.length - 1
                    ? "border-b border-[#67739A]"
                    : ""
                }`}
              >
                <div className="flex flex-row w-full items-center gap-2 justify-between py-3">
                  <span className="">{option.label}</span>
                  {option.value === value && (
                    <Icon
                      iconName="tick"
                      svgProps={{ width: 14, height: 14 }}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelectSecondary;
