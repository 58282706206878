import React, { useEffect, useState } from "react";
import Icon from "../icon";
import { useNavigate } from "react-router-dom";
import { sanitizeUrl } from "helper/SanitizeUrl";

interface GameCardProps {
  id: number | string;
  name: string;
  image: string;
  altText: string;
  notAvailable: boolean;
  showPlayCount?: boolean;
  mobileWidth?: string;
  mobileHeight?: string;
  tabletWidth?: string;
  tabletHeight?: string;
  desktopWidth?: string;
  desktopHeight?: string;
}

const GameCard = ({
  id,
  name,
  image,
  altText,
  notAvailable,
  showPlayCount = false,
  mobileWidth = "100px",
  mobileHeight = "146px",
  tabletWidth = "130px",
  tabletHeight = "173px",
  desktopWidth = "150px",
  desktopHeight = "200px",
}: GameCardProps) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 834);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth > 834 && window.innerWidth <= 1400
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 834);
      setIsTablet(window.innerWidth > 834 && window.innerWidth <= 1400);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    if (!notAvailable) {
      navigate(`/lounge/game/${id}/${sanitizeUrl(name)}`);
    }
  };

  const cardWidth = isMobile
    ? mobileWidth
    : isTablet
    ? tabletWidth
    : desktopWidth;
  const cardHeight = isMobile
    ? mobileHeight
    : isTablet
    ? tabletHeight
    : desktopHeight;

  return (
    <button
      onClick={handleClick}
      style={{ minWidth: cardWidth }}
      className="flex-shrink-0 rounded-lg w-full overflow-hidden transition-transform duration-200 hover:scale-105 pointer-events-auto"
      disabled={notAvailable}
    >
      <div
        style={{ minHeight: cardHeight }}
        className="w-full rounded-lg overflow-hidden relative group"
      >
        <div className="absolute inset-0 rounded-lg glow-line group-hover:animate-glow"></div>
        <img
          style={{ minHeight: cardHeight }}
          loading="lazy"
          src={image}
          alt={altText}
          className="w-full h-full object-fill rounded-lg"
        />
        {notAvailable && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-65 rounded-lg backdrop-blur-[2px] text-gray-200 text-center font-semibold px-4">
            <Icon iconName="region" svgProps={{ width: 34, height: 34 }} />
            <p className="mt-2 text-gray-100 font-semibold text-sm">
              Not available in your region
            </p>
          </div>
        )}
      </div>
      {showPlayCount && (
        <div className="flex flex-row text-center mt-3 items-center gap-2">
          <Icon iconName="ellipse20" svgProps={{ width: 7, height: 7 }} />
          <span className="text-white text-xs md:text-sm font-normal">
            3.291 playing
          </span>
        </div>
      )}
    </button>
  );
};

export default GameCard;
