import Breadcrumb from "@components/core-components/breadcrumb";
import React, { useState } from "react";
import MyBetsTableMobile from "./components/MyBetsTableMobile";
import CustomSelect from "@components/core-components/input/CustomSelect";
import DatePicker from "@components/core-components/input/DatePicker";
import TabsComponent from "@components/core-components/TabsComponent";
import DesktopTable from "@components/core-components/table/DesktopTable";
import Icon from "@components/core-components/icon";
import { setOpenModal } from "store/auth/authenticationSlice";
import GameDetailModal from "./components/GameDetailModal";

interface SelectOption {
  label: string;
  value: string;
  id: string;
}

function MyBets() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBetId, setSelectedBetId] = useState<string | null>(null);
  const openModal = (betId: string) => {
    setSelectedBetId(betId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBetId(null);
    setIsModalOpen(false);
  };
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "My Bets", href: "/lounge/my-bets" },
  ];
  const [activeTab, setActiveTab] = useState("casino");
  const [selectedRowValue, setSelectedRowValue] = useState<string>("10");
  const [selectedGameFilter, setSelectedGameFilter] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const gameFilterOptions = [
    { label: "Game Filter: All", value: "all" },
    { label: "Game Filter: Latest", value: "mines" },
    { label: "Game Filter: Oldest", value: "big-bass-boom" },
  ];
  const rowValue = [
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
    { label: "30", value: "30" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
  ];
  const tabs: SelectOption[] = [
    { id: "casino", label: "CASINO", value: "casino" },
    { id: "sportsbook", label: "SPORTSBOOK", value: "sportsbook" },
    { id: "exchange", label: "EXCHANGE", value: "exchange" },
  ];

  const columnDefs: any[] = [
    {
      headerName: "GAME",
      field: "game",
      cellClass: "flex flex-1 overflow-hidden",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row gap-[10px] items-center">
            <Icon
              iconName="slots"
              className="mt-[3px]"
              svgProps={{ width: 18, height: 18 }}
            />
            <p className="text-body-reg-15 font-semibold text-white">
              {params.value}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "BET ID",
      field: "betId",
      cellClass: "flex flex-1 overflow-hidden",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div
            className="flex flex-row gap-[10px] items-center"
            onClick={() => openModal(params.value)}
          >
            <Icon
              iconName="id"
              className="mt-[3px]"
              svgProps={{ width: 18, height: 18 }}
            />
            <p className="text-body-reg-15 font-semibold text-white">
              {params.value}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "DATE",
      field: "date",
      cellClass: "flex flex-1 overflow-hidden",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row gap-[10px] items-center">
            <p className="text-body-reg-15 font-normal text-gray-100 truncate">
              {params.value}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "BET AMOUNT",
      field: "betAmount",
      cellClass: "flex flex-1 overflow-hidden",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row gap-[10px] items-center">
            <Icon
              iconName="usdt"
              className="mt-[3px]"
              svgProps={{ width: 18, height: 18 }}
            />
            <p className="text-body-reg-15 font-semibold text-gray-100">
              {params.value}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "MULTIPLIER",
      field: "multiplier",
      cellClass: "flex flex-1 overflow-hidden truncate",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row gap-[10px] items-center">
            <p className="text-body-reg-15 font-normal text-gray-100">
              {params.value}
            </p>
          </div>
        );
      },
    },
    {
      headerName: "PAYOUT",
      field: "payout",
      cellClass: "flex flex-1 overflow-hidden",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row gap-[10px] items-center">
            <Icon
              iconName="usdt"
              className="mt-[3px]"
              svgProps={{ width: 18, height: 18 }}
            />
            <p
              className={`text-body-reg-14 font-black ${
                params.value.startsWith("-")
                  ? "text-gray-100"
                  : "text-green-500"
              }`}
            >
              {params.value}
            </p>
          </div>
        );
      },
    },
  ];

  const myBets = [
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3aa",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3ab",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "30,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3ac",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3ad",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3ae",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "549,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3af",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "568,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3ag",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3ah",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3ai",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3aj",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3ak",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3al",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3am",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3an",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Mines",
      betId: "117673c45af68e44d56517f3ao",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
    {
      game: "Big Bass BOOM",
      betId: "117673c45af68e44d56517f3ap",
      date: "19.11.2024 11:00",
      betAmount: "15,00 USD",
      multiplier: "1.65x",
      payout: "-15,00 USD",
    },
  ];

  return (
    <>
      <div className="relative w-full h-[210px] bg-custom-gradient-purple-to-pink">
        <div className="absolute top-0 left-0 w-full h-full px-4 md:px-8 pt-6">
          <Breadcrumb
            items={breadcrumbItems}
            className="flex items-start left-0 text-white"
          />
        </div>
      </div>
      <div className="relative -mt-[145px] px-4 md:px-8">
        <div className="flex flex-col justify-center items-start w-full px-5 py-6 gap-6 bg-gray-600 rounded-t-xl">
          <div className="hidden sm:flex h-12 flex-row items-center justify-between w-full gap-4">
            <TabsComponent
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              classNames={`bg-gray-700 rounded-lg p-2 w-full max-w-[556px] h-12 text-body-reg-15 font-semibold gap-1`}
              buttonWidth={"w-full"}
              activeTabColor="bg-primary"
            />
            <div className="hidden sm:block w-auto main-no-scrollbar::-webkit-scrollbar main-no-scrollbar">
              <CustomSelect
                options={rowValue}
                value={selectedRowValue}
                onChange={(value) => setSelectedRowValue(value)}
                showIcon={false}
                showSearch={false}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 items-start w-full">
            <div className="block sm:hidden w-full">
              <CustomSelect
                options={tabs}
                value={activeTab}
                onChange={setActiveTab}
                showSearch={false}
              />
            </div>
            <div className="w-full sm:max-w-[200px]">
              <CustomSelect
                options={gameFilterOptions}
                value={selectedGameFilter}
                onChange={(value) => setSelectedGameFilter(value)}
                placeholder="Game Filter: ALL"
                showSearch={false}
              />
            </div>
            <div className="flex flex-row items-center w-full sm:max-w-[350px] gap-4">
              <div className="w-full flex-1 sm:min-w-[140px]">
                <DatePicker
                  placeholder="Start Date"
                  maxDate={new Date()}
                  backgroundColor="bg-gray-400"
                  color="text-white"
                  hoverColor="hover:bg-gray-500"
                  iconName="downArrow"
                  svgProps={{ width: 16, height: 16 }}
                  // showLeftIcon={true}
                />
              </div>
              <div className="text-white">-</div>
              <div className="w-full flex-1 sm:min-w-[140px]">
                <DatePicker
                  placeholder="End Date"
                  maxDate={new Date()}
                  backgroundColor="bg-gray-400"
                  color="text-white"
                  hoverColor="hover:bg-gray-500"
                  iconName="downArrow"
                  svgProps={{ width: 16, height: 16 }}
                  //  showLeftIcon={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="block sm:hidden">
          <MyBetsTableMobile data={myBets} />
        </div>
        <div className="hidden sm:block">
          <DesktopTable data={myBets} columnDefs={columnDefs} />
        </div>
      </div>
      <GameDetailModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}

export default MyBets;
