import React, { useState } from "react";
import Modal from "@components/core-components/modal";
import TabsComponent from "@components/core-components/TabsComponent";
import Icon from "@components/core-components/icon";
import CustomSelect from "../input/CustomSelect";
import QrCode from "../../../assets/images/qr-code.png";
import Neosurf from "../../../assets/images/neosurf.png";
import Payeer from "../../../assets/images/payeer.png";
import Neteller from "../../../assets/images/neteller.png";
import Skrill from "../../../assets/images/skrill.png";
import Entropay from "../../../assets/images/entropay.png";
import Sticpay from "../../../assets/images/sticpay.png";
import CustomButton from "../button/CustomButton";
import InputWithTitle from "../input/InputWithTitle";

interface DepositModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DepositModal: React.FC<DepositModalProps> = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState<string>("crypto");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("BTC");
  const [selectedNetwork, setSelectedNetwork] = useState<string>("ETH");
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState<any>(null);
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [inputAmount, setInputAmount] = useState<string>("");

  const paymentMethods = [
    { id: "neosurf", name: "Neosurf", img: Neosurf },
    { id: "payeer", name: "Payeer", img: Payeer },
    { id: "neteller", name: "Neteller", img: Neteller },
    { id: "skrill", name: "Skrill", img: Skrill },
    { id: "entropay", name: "Entropay", img: Entropay },
    { id: "sticpay", name: "Sticpay", img: Sticpay },
  ];

  const filteredMethods = paymentMethods.filter((method) =>
    method.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currencyOptions = [
    { label: "BTC / Bitcoin", value: "BTC", icon: "btc" },
    { label: "ETH / Ethereum", value: "ETH", icon: "eth" },
    { label: "LTC / Litecoin", value: "LTC", icon: "ltc" },
    { label: "USDT / USD Tether", value: "USDT", icon: "usdt26" },
  ];

  const networkOptions = [
    { label: "ETH", value: "ETH" },
    { label: "BSC", value: "BSC" },
    { label: "TRX", value: "TRX" },
  ];

  const handleSelectMethod = (method: any) => {
    setSelectedMethod(method);
    setShowDetails(true);
  };

  const handleBack = () => {
    setShowDetails(false);
    setSelectedMethod(null);
  };

  const handleAmountClick = (amount: number) => {
    setSelectedAmount(amount);
    setInputAmount(`€${amount.toLocaleString()}`);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-[540px]"
      title={
        <div className="flex justify-center items-center w-full">
          <span className="text-lg font-semibold text-white">DEPOSIT</span>
        </div>
      }
      content={
        <>
          <div className={"px-4 md:px-10 pt-5 w-full"}>
            <TabsComponent
              tabs={[
                { id: "crypto", label: "CRYPTO" },
                { id: "localCurrency", label: "LOCAL CURRENCY" },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              classNames="mb-4 bg-gray-900 text-body-reg-13 font-black h-12 p-2 rounded-lg gap-2"
              activeTabColor="bg-primary"
              buttonPaddingX="px-0"
            />
          </div>
          {activeTab === "crypto" ? (
            <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-4">
              <div className="flex flex-col gap-4">
                <div>
                  <CustomSelect
                    options={currencyOptions}
                    value={selectedCurrency}
                    onChange={(value) => setSelectedCurrency(value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div>
                  <label className="block text-gray-200 text-body-reg-13 mb-2">
                    Network
                  </label>
                  <CustomSelect
                    options={networkOptions}
                    value={selectedNetwork}
                    onChange={(value) => setSelectedNetwork(value)}
                    placeholder="Select Network"
                  />
                </div>
                <div>
                  <label className="block text-gray-200 text-body-reg-13 mb-2">
                    Your USDT deposit address
                  </label>
                  <InputWithTitle
                    title="Your USDT deposit address"
                    inputText="1117638723c72hdxc8q29ey"
                    rightButtonIcon="copy"
                    rightButtonIconClick={() =>
                      navigator.clipboard.writeText("1117638723c72hdxc8q29ey")
                    }
                    isButtonShow={true}
                  />
                </div>
              </div>
              <div className="flex justify-center my-4">
                <img
                  src={QrCode}
                  alt="QR Code"
                  className="w-32 h-32 bg-white"
                />
              </div>
              <div className="text-xs text-gray-200 font-semibold text-center">
                Only send USDT to this address, 2 confirmation required.
              </div>
              <div className="text-xs text-gray-200 font-semibold text-center">
                Improve your account security with Two-Factor Authentication
              </div>
              <div className="text-center w-full">
                <button className="px-6 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 w-full border border-white">
                  Enable 2FA
                </button>
              </div>
            </div>
          ) : (
            <>
              {showDetails && selectedMethod ? (
                <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-4">
                  <div className="flex justify-between items-center">
                    <button
                      onClick={handleBack}
                      className="flex items-center justify-center gap-2 text-white text-sm font-semibold"
                    >
                      <Icon
                        iconName="sliderArrowLeft"
                        svgProps={{ width: 16, height: 16 }}
                      />
                      Back
                    </button>
                    <img
                      src={selectedMethod.img}
                      alt={selectedMethod.name}
                      className="w-[107px] h-auto object-cover"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <span className="text-xl font-black text-white">
                      {selectedMethod.name}
                    </span>
                    <p className="text-gray-200 text-body-reg-13">
                      You can deposit between €200 and €100,000.
                    </p>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    {[500, 1000, 2500, 5000, 10000, 50000].map((amount) => (
                      <button
                        key={amount}
                        onClick={() => handleAmountClick(amount)}
                        className={`w-[70px] h-[30px] text-xs font-semibold rounded-lg items-center justify-center ${
                          selectedAmount === amount
                            ? "bg-yellow text-gray-600"
                            : "bg-gray-400 text-white hover:bg-gray-500"
                        }`}
                      >
                        €{amount.toLocaleString()}
                      </button>
                    ))}
                  </div>
                  <div>
                    <input
                      type="text"
                      value={inputAmount}
                      onChange={(e) => setInputAmount(e.target.value)}
                      placeholder="€500"
                      className="w-full h-[50px] px-4 py-3 my-2 border rounded-lg text-gray-700 focus:outline-none"
                    />
                  </div>
                  <CustomButton text="Deposit" buttonType="primary" />
                </div>
              ) : (
                <div className="flex flex-col w-full max-w-[540px] px-4 md:px-10 h-auto rounded-lg gap-4">
                  <div className="flex items-center bg-gray-700 px-4 py-4 rounded-lg border border-gray-400">
                    <Icon
                      iconName="searchWhite"
                      svgProps={{ width: 16, height: 16 }}
                      className="text-gray-400"
                    />
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="flex-1 bg-transparent text-white placeholder-gray-200 ml-2 text-body-reg-13 font-normal focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    {filteredMethods.length > 0 ? (
                      filteredMethods.map((method) => (
                        <button
                          key={method.id}
                          className="flex items-center justify-between bg-gray-400 px-4 py-3 rounded-md cursor-pointer hover:bg-gray-500"
                          onClick={() => handleSelectMethod(method)}
                        >
                          <span className="text-white text-sm font-semibold">
                            {method.name}
                          </span>
                          <div className="flex items-center gap-1">
                            <img
                              src={method.img}
                              alt={method.name}
                              className="w-[70px] h-[30px]"
                            />
                            <Icon
                              iconName="whiteRightArrow"
                              svgProps={{ width: 18, height: 18 }}
                              className="mt-1"
                            />
                          </div>
                        </button>
                      ))
                    ) : (
                      <span className="text-white text-sm px-2">
                        No payment methods found
                      </span>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      }
    />
  );
};

export default DepositModal;
