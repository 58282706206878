import React, { useState } from "react";
import Icon from "@components/core-components/icon";

interface DropdownButton {
  text1: string;
  text2: string;
  onClick: () => void;
}

interface ExchangeCategoryListProps {
  categories: { [key: string]: string };
  activeCategory: string;
  setActiveCategory: (category: string) => void;
  dropdownButtons: { [key: string]: DropdownButton[] };
}

const ExchangeCategoryList: React.FC<ExchangeCategoryListProps> = ({
  categories,
  activeCategory,
  setActiveCategory,
  dropdownButtons,
}) => {
  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleDropdown = (category: string) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <>
      <div className="flex flex-col w-full pb-5 border-b border-gray-300">
        <button
          onClick={() => {}}
          className={`flex items-center justify-between w-full h-[53px] text-xs gap-3 font-semibold px-4 text-white hover:bg-gray-400`}
        >
          <div className="flex flex-row gap-2">
            <Icon
              iconName="myMarket"
              svgProps={{
                width: 14,
                height: 15,
              }}
            />
            <span>My markets</span>
          </div>
          0
        </button>
        <button
          onClick={() => {}}
          className={`flex items-center justify-between w-full h-[53px] text-sm gap-3 font-semibold px-4 text-white hover:bg-gray-400`}
        >
          <div className="flex flex-row gap-2">
            <Icon
              iconName="favoriteWhite"
              svgProps={{
                width: 14,
                height: 14,
              }}
            />
            <span>Favorites</span>
          </div>
          8
        </button>
      </div>
      <div className="mt-4 pb-4 md:border-b border-gray-300 items-start">
        {Object.keys(categories).map((category) => (
          <div key={category}>
            <button
              onClick={() => toggleDropdown(category)}
              className={`flex items-center w-full h-[53px] text-sm gap-3 font-semibold px-4 transition-colors duration-200 ${
                activeCategory === category || openDropdowns[category]
                  ? "text-white bg-gray-900"
                  : "text-gray-200 hover:bg-gray-400"
              }`}
            >
              <div className="w-full flex flex-row items-center justify-between">
                <div className="flex items-center gap-3">
                  <Icon
                    iconName={categories[category]}
                    svgProps={{
                      width: 14,
                      height: 14,
                    }}
                  />
                  <span
                    style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    {category}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <div className="flex items-center justify-center p-[2px] rounded-[2px] bg-gray-400">
                    <span className="text-xs font-normal text-white">12</span>
                  </div>
                  <svg
                    className={`h-5 w-5 transition-transform duration-200 ${
                      openDropdowns[category] ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="white"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </button>
            {openDropdowns[category] && (
              <div className="bg-gray-900 px-4 py-2">
                {dropdownButtons[category]?.map((button, index) => (
                  <button
                    key={index}
                    className="flex flex-col gap-3 py-1 w-full"
                    onClick={button.onClick}
                  >
                    <div className="flex flex-col justify-between items-center w-full py-2 text-sm text-white bg-gray-600 rounded">
                      <div className="flex flex-col w-full items-start text-gray-200 text-xs font-normal pb-[6px] border-b border-gray-300">
                        <span className="px-2">{button.text1}</span>
                        <span className="px-2">{button.text2}</span>
                      </div>
                      <div className="flex flex-row w-full items-center justify-between gap-1 px-2 pt-[6px]">
                        <span className="text-xs">+2</span>
                        <button>
                          <Icon
                            iconName="favoriteWhite"
                            svgProps={{
                              width: 14,
                              height: 14,
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ExchangeCategoryList;
