import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownButton from "../core-components/button/DropdownButton";
import Icon from "../core-components/icon";
import CustomButton from "@components/core-components/button/CustomButton";
import { useAppSelector } from "hooks/redux";
import { baseService } from "api/core/baseService";
import { Wallet } from "./types";
import { useDispatch } from "react-redux";
import {
  logoutUser,
  setInitialWindow,
  setOpenModal,
} from "store/auth/authenticationSlice";
import { AppDispatch } from "store";
import { closeAllModals, setActiveTab } from "store/menu/menuSlice";
import DepositModal from "@components/core-components/modal/DepositModal";
import WithdrawModal from "@components/core-components/modal/WithdrawModal";
import WalletModal from "@components/core-components/modal/WalletModal";
import { useBaseQuery } from "api/core/useBaseQuery";
import LoadingOverlay from "./LoadingOverlay";

function HeaderMobile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [playerData, setPlayerData] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedWallet, setSelectedWallet] = useState<any>([]);
  const { isAuthenticated, loading, userId } = useAppSelector(
    (state) => state.auth
  );
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const openWithdrawModal = () => setIsWithdrawModalOpen(true);
  const closeWithdrawModal = () => setIsWithdrawModalOpen(false);
  const openDepositModal = () => setIsDepositModalOpen(true);
  const closeDepositModal = () => setIsDepositModalOpen(false);
  const openWalletModal = () => setIsWalletModalOpen(true);
  const closeWalletModal = () => setIsWalletModalOpen(false);
  const openSignInModal = () => {
    dispatch(setInitialWindow("signIn"));
    dispatch(setOpenModal(true));
  };
  const openRegisterModal = () => {
    dispatch(setInitialWindow("register"));
    dispatch(setOpenModal(true));
  };

  const {
    data: wallets,
    isLoading,
    refetch,
    error,
  } = useBaseQuery<Wallet[]>({
    endpoint: `users/wallet/${userId}`,
    queryKey: ["wallets", userId],
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (wallets) {
      setPlayerData(wallets);

      const activeWallet = wallets.find((wallet) => wallet.isActive);

      if (activeWallet) {
        setSelectedWallet(activeWallet);
      } else {
        setSelectedWallet(wallets[0] || null);
      }
    }
  }, [wallets]);

  if (isLoading) return <LoadingOverlay />;

  const updateActiveWallet = async (userId: string, walletId: string) => {
    try {
      await baseService.update(`users/wallet/update/${userId}`, {
        walletId,
        playerId: "6734c8c7484ca261434ea4b4",
      });
      setSelectedWallet(
        playerData.find((wallet: any) => wallet._id === walletId)
      );
      refetch();
    } catch (error) {
      console.log(error);
    }
  };
  const handleLogoClick = () => {
    navigate(`/`);
    dispatch(closeAllModals());
    dispatch(setActiveTab(""));
  };

  const currencyIconMap: { [key: string]: string } = {
    USD: "usdt",
    JPY: "jpy",
    INR: "inr",
  };

  // const renderLogo = () => {
  //   if (location.pathname.includes("/lounge")) {
  //     return (
  //       <button onClick={handleLogoClick} className="flex flex-row items-end">
  //         <Icon
  //           iconName="pixupPink"
  //           svgProps={{ width: 34, height: 24 }}
  //           className="mr-1"
  //         />
  //         <Icon iconName="pixupClient" svgProps={{ width: 85, height: 15 }} />
  //         <Icon iconName="pixupCasino" svgProps={{ width: 75, height: 12 }} />
  //       </button>
  //     );
  //   } else if (location.pathname.includes("/exchange")) {
  //     return (
  //       <button onClick={handleLogoClick} className="flex flex-row items-end">
  //         <Icon
  //           iconName="pixupGreenMobile"
  //           svgProps={{ width: 29, height: 20 }}
  //           className="mr-1"
  //         />
  //         <Icon iconName="pixupClient" svgProps={{ width: 85, height: 15 }} />
  //         <Icon
  //           iconName="pixupExchangeMobile"
  //           svgProps={{ width: 91, height: 11 }}
  //         />
  //       </button>
  //     );
  //   } else {
  //     return (
  //       <button
  //         onClick={handleLogoClick}
  //         className="flex flex-row items-center"
  //       >
  //         <Icon
  //           iconName="pixupYellow"
  //           svgProps={{ width: 34, height: 24 }}
  //           className="mr-1"
  //         />
  //         <Icon iconName="pixupClient" svgProps={{ width: 85, height: 15 }} />
  //       </button>
  //     );
  //   }
  // };

  return (
    <header className="w-full fixed top-0 z-30">
      <div className="bg-gray-900 h-[60px] flex items-center justify-between shadow-lg px-4">
        {/* {renderLogo()} */}
        <button onClick={handleLogoClick} className="flex flex-row items-end">
          <Icon iconName="pixupLogo" svgProps={{ width: 102, height: 18 }} />
        </button>
        {loading && isLoading ? null : (
          <div className="flex items-center gap-4 h-full">
            {isAuthenticated ? (
              <>
                <div className="flex items-center w-full h-full">
                  <DropdownButton
                    iconName="profileIconWhite"
                    label=""
                    iconWidth={16}
                    iconHeight={16}
                    options={[
                      {
                        label: "My Bets",
                        icon: "myBets",
                        onClick: () => console.log("My Bets"),
                      },
                      {
                        label: "Settings",
                        icon: "settings",
                        onClick: () => console.log("Settings"),
                      },
                      {
                        label: "Logout",
                        icon: "logout",
                        onClick: () => dispatch(logoutUser()),
                      },
                    ]}
                  />
                </div>
                <button className="flex items-center h-full">
                  <Icon
                    iconName="notification"
                    svgProps={{ width: 20, height: 20 }}
                    className="cursor-pointer"
                  />
                </button>
              </>
            ) : (
              <div className="flex items-center gap-3">
                <CustomButton
                  text="Sign In"
                  onClick={openSignInModal}
                  buttonType="secondary"
                />
                <CustomButton
                  text="Register"
                  onClick={openRegisterModal}
                  buttonType="primary"
                />
              </div>
            )}
          </div>
        )}
      </div>

      {loading ? null : (
        <>
          {" "}
          {isAuthenticated && selectedWallet.currency?.length > 0 && (
            <div
              style={{ marginBottom: 10 }}
              className="bg-gray-600 h-[56px] flex items-center justify-between pr-4"
            >
              <DropdownButton
                iconName={
                  currencyIconMap[selectedWallet?.currency[0]?.code] ||
                  "defaultIcon"
                }
                label={`${selectedWallet?.total?.toFixed(
                  selectedWallet?.currency[0]?.fixedValueCount ?? 2
                )} ${selectedWallet?.currency[0]?.code}`}
                iconWidth={17}
                iconHeight={17}
                options={playerData?.map((wallet: any) => ({
                  label: `${wallet.currency[0]?.code}`,
                  onClick: () =>
                    updateActiveWallet("6734c8c7484ca261434ea4b4", wallet._id),
                  icon: wallet.currency[0]?.icon,
                }))}
              />
              <div className="flex items-center">
                <CustomButton
                  text="Deposit"
                  onClick={openWalletModal}
                  buttonType="primary"
                />
              </div>
              <DepositModal
                isOpen={isDepositModalOpen}
                onClose={closeDepositModal}
              />
              <WithdrawModal
                isOpen={isWithdrawModalOpen}
                onClose={closeWithdrawModal}
              />
              <WalletModal
                isOpen={isWalletModalOpen}
                onClose={closeWalletModal}
                onOpenDepositModal={openDepositModal}
                onOpenWithdrawModal={openWithdrawModal}
              />
            </div>
          )}
        </>
      )}
    </header>
  );
}

export default HeaderMobile;
