import React from "react";
import UploadSection from "../components/UploadSection";
import Icon from "@components/core-components/icon";

const uploadSections = [
  {
    title: "Upload Source of Funds",
    description: "Following file types are accepted: .png, .jpg, .pdf",
  },
];

const VerifyLevel4 = () => (
  <div className="lg:max-w-[740px]">
    <span className="text-white text-xl font-black">Level 4</span>
    <p className="text-gray-200 text-sm font-normal">
      Please upload supporting documentation for you Source of Funds. Document
      laying on a flat surface must show all 4 corners and all information
      should be clear and identifiable.
    </p>
    <div className="flex flex-row gap-4 w-full justify-start items-center p-5 my-6 h-[58px] bg-[#67624D] rounded-md">
      <Icon iconName="warningYellow" svgProps={{ width: 18, height: 17 }} />
      <p className="text-primary text-sm font-normal">
        Please complete level three verification first.
      </p>
    </div>

    {uploadSections.map((section, index) => (
      <UploadSection
        key={index}
        title={section.title}
        description={section.description}
      />
    ))}

    <p className="w-full mt-[26px] text-gray-200 text-sm font-normal">
      * Following file types are accepted: .png, .jpg, .pdf
    </p>
  </div>
);

export default VerifyLevel4;
