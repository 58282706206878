import React from "react";
import Icon from "@components/core-components/icon";

interface InputWithTitleProps {
  title: string;
  inputText: string;
  rightButtonIcon?: string;
  rightButtonIconClick?: () => void;
  isButtonShow: boolean;
  classname?: string;
  type?: string;
}

const InputWithTitle: React.FC<InputWithTitleProps> = ({
  title,
  classname,
  inputText,
  rightButtonIcon,
  type = "text",
  rightButtonIconClick,
  isButtonShow,
}) => {
  return (
    <div className={`${classname ? classname : ""}`}>
      <div className="flex items-center bg-white text-black px-4 py-2 rounded-lg shadow-md border border-gray-300">
        <div className="flex flex-col flex-1">
          <span className="text-xs font-semibold text-gray-200">{title}</span>
          <input
            type={type}
            value={inputText}
            readOnly
            className="bg-transparent text-body-reg-15 font-semibold text-gray-800 focus:outline-none"
          />
        </div>
        {isButtonShow && (
          <button
            className="ml-4 text-gray-700 hover:text-black"
            onClick={rightButtonIconClick}
          >
            {rightButtonIcon && (
              <Icon
                iconName={rightButtonIcon}
                svgProps={{ width: 22, height: 22 }}
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default InputWithTitle;
