import CustomSelect from "@components/core-components/input/CustomSelect";
import DesktopTable from "@components/core-components/table/DesktopTable";
import React from "react";
import MobileSessionsTable from "./MobileSessionTabs";
import Icon from "@components/core-components/icon";

interface SessionRow {
  browser: string;
  near: string;
  ipAddress: string;
  lastUsed: string;
  action: string;
  icon: string;
}

interface TableProps {
  data: SessionRow[];
}
const sessionData = [
  {
    browser: "Chrome",
    near: "AE, Dubai (Academic City)",
    ipAddress: "192.111.333.555",
    lastUsed: "12 Dec 2024 11:00",
    action: "Current",
    icon: "ios",
  },
  {
    browser: "Chrome",
    near: "BZ, Bouverdes",
    ipAddress: "162.111.333.555",
    lastUsed: "12 Dec 2024 11:00",
    action: "Remove Session",
    icon: "ios",
  },
  {
    browser: "Chrome",
    near: "BZ, Bouverdes",
    ipAddress: "162.111.333.555",
    lastUsed: "12 Dec 2024 11:00",
    action: "Remove Session",
    icon: "windows",
  },
  {
    browser: "Chrome",
    near: "BZ, Bouverdes",
    ipAddress: "162.111.333.555",
    lastUsed: "12 Dec 2024 11:00",
    action: "Remove Session",
    icon: "android",
  },
  {
    browser: "Chrome",
    near: "BZ, Bouverdes",
    ipAddress: "162.111.333.555",
    lastUsed: "12 Dec 2024 11:00",
    action: "Remove Session",
    icon: "windows",
  },
  {
    browser: "Chrome",
    near: "BZ, Bouverdes",
    ipAddress: "162.111.333.555",
    lastUsed: "12 Dec 2024 11:00",
    action: "Remove Session",
    icon: "android",
  },
];

const Table: React.FC<TableProps> = ({ data }) => {
  return (
    <div className="w-full bg-gray-800 rounded-lg overflow-hidden">
      {/* Table Header */}
      <div className="grid grid-cols-5 gap-4 bg-gray-600 text-gray-200 font-bold text-sm py-4 px-9">
        <span>Browser</span>
        <span>Near</span>
        <span>IP Address</span>
        <span>Last Used</span>
        <span>Action</span>
      </div>
      {/* Table Body */}
      <div>
        {data.map((row, index) => (
          <div
            key={index}
            className={`grid grid-cols-5 gap-4 py-1 items-center px-9 h-12 ${
              index % 2 === 0 ? "bg-gray-400" : "bg-gray-600"
            }`}
          >
            <div className="flex items-center gap-2">
              <Icon
                svgProps={{
                  width: 16,
                  height: 16,
                }}
                iconName={row.icon}
              />
              <span className="text-gray-100 font-normal text-sm">
                {row.browser}
              </span>
            </div>
            <span className="text-gray-100 font-normal text-sm">
              {row.near}
            </span>
            <span className="text-gray-100 font-normal text-sm">
              {row.ipAddress}
            </span>
            <span className="text-gray-100 font-normal text-sm">
              {row.lastUsed}
            </span>
            <span
              className={`text-sm font-medium ${
                row.action === "Current"
                  ? "text-white"
                  : "text-red-500 underline cursor-pointer"
              }`}
              onClick={() => {
                if (row.action !== "Current") {
                  alert(`Removing session for ${row.browser}`);
                }
              }}
            >
              {row.action}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

function SessionsTable() {
  return (
    <div className="w-full flex flex-col">
      <div>
        <p className="hidden lg:block pt-6 text-white px-8 text-[15px]">
          SESSIONS
        </p>

        <div className="hidden lg:block h-[1px] mx-8 bg-[#2C3555] mb-6 mt-4"></div>
        <div className="px-3 pt-8 md:px-6 lg:py-0 lg:px-8 w-full max-w-[258px] main-no-scrollbar::-webkit-scrollbar main-no-scrollbar">
          <CustomSelect
            options={[]}
            value={""}
            onChange={(value) => console.log(value)}
            showIcon={false}
            showSearch={false}
            placeholder="SESSION FILTER: All"
          />
        </div>
        <div className="hidden sm:block mt-4">
          <Table data={sessionData} />
        </div>
        <div className="block sm:hidden mt-4">
          <MobileSessionsTable data={sessionData} />
        </div>
      </div>
    </div>
  );
}

export default SessionsTable;
