import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";

function SecuritySetting() {
  return (
    <div className="w-full flex flex-col">
      <div>
        <p className="pt-6 text-white text-[15px]">PASSWORD</p>
        <div className="h-[1px] w-full bg-[#2C3555] mb-6 mt-4"></div>
        <div className="flex flex-col gap-4">
          {" "}
          <Textfield
            type="password"
            className="max-w-[404px]"
            placeholder="Old Password*"
            id="oldPassword"
          />{" "}
          <Textfield
            type="password"
            className="max-w-[404px]"
            placeholder="New Password*"
            id="newPassword"
          />{" "}
          <Textfield
            type="password"
            className="max-w-[404px]"
            placeholder="Confirm New Password*"
            id="confirmPassword"
          />{" "}
        </div>
        <div className="h-[1px] w-full bg-[#2C3555] my-6"></div>
      </div>
      <div className="flex justify-end h-full gap-6  w-full">
        <CustomButton
          classname="max-w-[175px]"
          text="Save"
          onClick={() => {}}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default SecuritySetting;
