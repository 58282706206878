// menuSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  activeTab: string;
  modals: Record<string, boolean>;
}

const initialState: MenuState = {
  activeTab: "",
  modals: {
    menu: false,
    chat: false,
    favorites: false,
    categories: false,
    platform: false,
  },
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<string>) {
      state.activeTab = action.payload;
    },
    toggleModal(state, action: PayloadAction<string>) {
      const modalKey = action.payload;
      state.modals[modalKey] = !state.modals[modalKey];
    },
    closeAllModals(state) {
      state.modals = Object.keys(state.modals).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as Record<string, boolean>);
    },
  },
});

export const { setActiveTab, toggleModal, closeAllModals } = menuSlice.actions;
export default menuSlice.reducer;
