import { useMemo } from "react";

function useCheckUrl(pathname: string, compareText: string): boolean {
  const isMatch = useMemo(() => {
    const pathSegments = pathname.split("/").filter(Boolean);

    const isMatch = pathSegments.includes(compareText);
    return isMatch;
  }, [pathname, compareText]);

  return isMatch;
}

export default useCheckUrl;
