import React, { useState } from "react";
import Icon from "@components/core-components/icon";

interface ISessionData {
  browser: string;
  ipAddress: string;
  near: string;
  lastUsed: string;
  action: string;
  icon: string;
}

interface SessionsTableMobileProps {
  data: ISessionData[];
}

const SessionsTableMobile: React.FC<SessionsTableMobileProps> = ({ data }) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const toggleRowExpansion = (ipAddress: string) => {
    setExpandedRow(expandedRow === ipAddress ? null : ipAddress);
    console.log(expandedRow);
  };

  const fields = [
    { label: "BROWSER", value: "browser" },
    { label: "NEAR", value: "near" },
    { label: "IP ADDRESS", value: "ipAddress" },
    { label: "LAST USED", value: "lastUsed" },
  ];

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <div className="bg-gray-500 rounded-b-xl pb-4 ">
      {/* Table Header */}
      <div className="flex flex-col shadow-md px-4 py-2 w-full bg-gray-600">
        <div className="flex flex-row justify-between gap-4 w-full cursor-pointer">
          <div className="flex flex-row items-center flex-1">
            <p className="text-body-reg-14 font-black text-gray-200">BROWSER</p>
          </div>

          <div className="flex flex-row items-center flex-1 ">
            <p className="text-body-reg-14 font-black text-gray-200">NEAR</p>
          </div>
          <div className="flex flex-row items-center flex-1 justify-center">
            <p className="text-body-reg-14 font-black text-gray-200">
              LAST USED
            </p>
          </div>
        </div>
      </div>
      {/* Table Rows */}
      <ul className="max-h-[716px] overflow-y-auto rounded-b-x">
        {data.map((session, index) => (
          <li
            key={index}
            className={`flex flex-col shadow-md p-3 w-full px-5   ${
              index % 2 === 0 ? "bg-gray-400" : "bg-gray-500"
            }`}
          >
            {/* Collapsed Row */}
            <div
              className={`flex flex-row justify-between gap-4  w-full cursor-pointer ${
                expandedRow !== session.ipAddress ? "py-1  " : "   pt-1"
              }`}
              onClick={() => toggleRowExpansion(session.ipAddress)}
            >
              <div className="flex flex-row gap-2 items-center flex-1 overflow-hidden">
                <Icon
                  iconName={session.icon}
                  svgProps={{ width: 16, height: 16 }}
                />
                <p className="text-body-reg-14 font-normal text-white truncate">
                  {session.browser}
                </p>
              </div>

              <div className="flex flex-row items-center flex-1 justify-start">
                <p
                  className={`text-body-reg-14 font-normal text-white truncate`}
                >
                  {truncateText(session.near, 15)}
                </p>
              </div>
              <div className="flex flex-row items-center flex-1 justify-end">
                <p className="text-body-reg-14 font-normal text-white truncate">
                  {truncateText(session.lastUsed, 6)}
                </p>
                <Icon
                  iconName="downArrow"
                  svgProps={{ width: 18, height: 18 }}
                  className={`transform transition-transform duration-200 ml-3 ${
                    expandedRow === session.ipAddress ? "rotate-180" : ""
                  }`}
                />
              </div>
            </div>
            {/* Expanded Row */}
            <div
              className={`transition-all duration-500 ease-in-out  overflow-hidden ${
                expandedRow === session.ipAddress ? "max-h-[500px]" : "max-h-0"
              }`}
            >
              {expandedRow === session.ipAddress && (
                <div className="flex flex-col mt-3 pb-2 rounded-md">
                  {fields.map((field, fieldIndex) => (
                    <div
                      key={field.value}
                      className={`flex flex-row items-center justify-between w-full py-3 ${
                        fieldIndex === 0
                          ? "border-t border-b border-gray-300"
                          : "border-b border-gray-300"
                      }`}
                    >
                      <p className="text-body-reg-13 font-semibold text-gray-200">
                        {field.label}
                      </p>
                      <span className="text-white text-body-reg-13 font-normal">
                        {truncateText(
                          session[field.value as keyof typeof session],
                          50
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-end my-4">
                <button
                  className={`text-sm font-medium  ${
                    session.action === "Current"
                      ? "text-white cursor-default"
                      : "text-red-500 underline cursor-pointer"
                  }`}
                  onClick={() => {
                    if (session.action !== "Current") {
                      alert(`Removing session for ${session.ipAddress}`);
                    }
                  }}
                >
                  {session.action === "Current"
                    ? "Current Session"
                    : "Remove Session"}
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SessionsTableMobile;
