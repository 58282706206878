import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Icon from "@components/core-components/icon";
import SearchBar from "@components/core-components/input/SearchBar";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  GameResponse,
  casinoGames,
  fetchGames,
} from "store/casino/casinoSlice";
import Breadcrumb from "@components/core-components/breadcrumb";
import GameCard from "@components/core-components/card/GameCard";

function CategoryDetails() {
  const { categoryName } = useParams();
  const games = useAppSelector(casinoGames);
  const dispatch = useAppDispatch();
  const capitalizeFirstLetter = (string: string) => {
    const formattedString = string.replace(/-/g, " ");
    return (
      formattedString.charAt(0).toUpperCase() +
      formattedString.slice(1).toLowerCase()
    );
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCards, setFilteredCards] = useState<GameResponse[]>([]);
  const [visibleCount, setVisibleCount] = useState(50);

  // useEffect(() => {
  //   dispatch(fetchGames());
  // }, [dispatch]);

  useEffect(() => {
    setFilteredCards(games);
  }, [games]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length === 0) {
      setFilteredCards(games);
    } else if (value.length >= 3) {
      const filtered = games.filter((card: GameResponse) =>
        String(card.game_name).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCards(filtered);
    }
  };

  const handleLoadMore = () => {
    setVisibleCount((prev) => {
      const remainingGames = filteredCards.length - prev;
      return prev + (remainingGames < 50 ? remainingGames : 50);
    });
  };

  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Casino", href: "/lounge" },
    {
      label: categoryName ? capitalizeFirstLetter(categoryName) : "",
      href: "/lounge/" + categoryName,
    },
  ];

  return (
    <>
      <div className="relative w-full h-14 md:h-28 flex items-center justify-between px-4 md:px-8 bg-custom-gradient-purple-to-pink">
        <div>
          <div className="hidden md:block">
            <Breadcrumb
              items={breadcrumbItems}
              className="flex items-start  left-0 text-white"
            />
          </div>
          <div className="flex items-center gap-1">
            <h1 className="text-xl md:text-3xl font-black text-white uppercase drop-shadow-4xl">
              {categoryName && capitalizeFirstLetter(categoryName)}
            </h1>
            <span className="text-sm font-semibold text-gray-100">
              - {games.length} Games
            </span>
          </div>
        </div>
        <div className="hidden md:flex">
          <Icon
            iconName="gradientBgLogo"
            svgProps={{ width: 140, height: 92 }}
            className="-mb-5"
          />
        </div>
      </div>
      <div className="md:p-8 p-3 w-full text-white">
        <div className="flex w-full items-center justify-between mb-6">
          <SearchBar
            onClick={handleOpenSearchModal}
            onChange={handleInputChange}
            value={searchTerm}
            preventFocus={false}
          />
        </div>

        <div className="flex justify-between items-center mb-6">
          <div className="md:hidden">
            <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
              Providers
              <Icon
                iconName="downArrow"
                svgProps={{ width: 16, height: 16, className: "ml-1" }}
              />
            </button>
          </div>
          <div className="hidden md:flex">
            <div className="flex items-center gap-2">
              <Icon iconName="filterBy" svgProps={{ width: 17, height: 17 }} />
              <span className="text-white text-sm font-semibold">
                Filter By:
              </span>
              <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
                Providers
                <Icon
                  iconName="downArrow"
                  svgProps={{ width: 16, height: 16, className: "ml-1" }}
                />
              </button>
            </div>
          </div>

          <div className="md:hidden">
            <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
              Popular
              <Icon
                iconName="downArrow"
                svgProps={{ width: 16, height: 16, className: "ml-1" }}
              />
            </button>
          </div>
          <div className="hidden md:flex">
            <div className="flex items-center gap-2">
              <Icon iconName="sortBy" svgProps={{ width: 17, height: 17 }} />
              <span className="text-white text-sm font-semibold">Sort By:</span>
              <button className="h-10 bg-[#2C3555] w-32 gap-3 rounded-lg flex flex-row justify-center items-center text-sm font-semibold text-white">
                Popular
                <Icon
                  iconName="downArrow"
                  svgProps={{ width: 16, height: 16, className: "ml-1" }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 custom:grid-cols-3  sm:grid-cols-4 md:grid-cols-5  lg:grid-cols-6 xl:grid-cols-8   2xl:grid-cols-9 gap-[6px]">
          {filteredCards.slice(0, visibleCount).map((game: GameResponse) => (
            <div key={game.game_id} className="relative">
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status !== "ACTIVE"}
                showPlayCount={true}
                mobileWidth="113px"
                mobileHeight="150px"
                tabletWidth="125px"
                tabletHeight="164px"
                desktopWidth="145px"
                desktopHeight="190px"
              />
            </div>
          ))}
          {filteredCards.length === 0 && (
            <div className="w-full h-full flex items-center justify-center">
              <p className="text-white text-lg font-semibold">
                No games found...
              </p>
            </div>
          )}
        </div>

        {/* <div className="flex flex-wrap justify-around items-center gap-[6px] ">
          {filteredCards.slice(0, visibleCount).map((game: GameResponse) => (
            <div key={game.game_id} className="relative">
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status === "ACTIVE" ? false : true}
                showPlayCount={true}
                mobileWidth="113px"
                mobileHeight="150px"
                tabletWidth="125px"
                tabletHeight="164px"
                desktopWidth="145px"
                desktopHeight="190px"
              />
            </div>
          ))}
          {filteredCards.length === 0 && (
            <div className="w-full h-full flex items-center justify-center">
              <p className="text-white text-lg font-semibold">
                No games found...
              </p>
            </div>
          )}
        </div> */}
        <div className="flex flex-col gap-4 text-center mt-10 items-center">
          <span className="text-gray-200 text-sm font-normal">
            Displaying {Math.min(visibleCount, filteredCards.length)} of{" "}
            {filteredCards.length} games
          </span>
          {visibleCount < filteredCards.length && (
            <button
              onClick={handleLoadMore}
              className="px-3 py-2 bg-gray-500 text-white text-sm font-semibold rounded-lg hover:bg-gray-600 w-auto h-10"
            >
              <div className="flex flex-row gap-3">
                <Icon
                  iconName="loadMore"
                  svgProps={{ width: 20, height: 20 }}
                  className="transform rotate-180 inline-block"
                />
                Load More
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CategoryDetails;
