import React, { ChangeEvent, useEffect, useState } from "react";
import { FieldError, UseFormRegister, UseFormWatch } from "react-hook-form";

interface TextfieldProps {
  id?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  style?: React.CSSProperties;
  register?: UseFormRegister<any>;
  watch?: UseFormWatch<any>;
  error?: FieldError;
  label?: string;
  disabled?: boolean;
}

const Textfield: React.FC<TextfieldProps> = ({
  id,
  type = "text",
  placeholder = "",
  value,
  onChange,
  required = false,
  className = "",
  style,
  register,
  watch,
  error,
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = register ? watch?.(id || "") : value || "";

    if (!currentValue) setIsFocused(false);
  };

  return (
    <div className={`${className} flex flex-col relative`}>
      <label
        htmlFor={id}
        className={`absolute text-gray-200 left-[17px] transform -translate-y-1/2 text-sm transition-all duration-300 ${
          isFocused || (register ? watch?.(id || "") : value) || error
            ? "top-4 text-xs"
            : "top-1/2"
        }`}
      >
        {placeholder}
      </label>

      <input
        onAnimationStart={(e: any) => {
          if (e.animationName === "autofill" && id) {
            onChange?.(e);
          }
        }}
        disabled={disabled}
        style={style}
        onFocus={handleFocus}
        type={type}
        id={id}
        {...(register
          ? register(id || "", {
              required,
              onBlur: handleBlur,
            })
          : { onBlur: handleBlur })}
        value={register ? undefined : value}
        onChange={onChange}
        className={`w-full text-[15px] h-[50px] p-4 border rounded-md bg-white pt-[30px] text-gray-900 placeholder-gray-200 ${
          error
            ? "border-red-500 bg-[var(--Danger-Danger-Light,#FFF1F2)]"
            : "border-gray-400"
        }`}
      />

      {error && (
        <p className="mt-2 text-red-500 text-body-reg-12 font-medium">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default Textfield;
