const API_BASE_URL = "https://staging.pixupplay.com/api/";

export const GAMES_URL = {
  GET_GAMES: API_BASE_URL + "games",
  GET_GAME: (id: any) => API_BASE_URL + `players/games/${id}`,
};

export const PLAYER_WALLET_API_URLS = {
  GET_PLAYER_WALLET: (id: number) => API_BASE_URL + `users/wallet/${id}`,
  DEPOSIT: API_BASE_URL + "players/deposit",
  WITHDRAW: API_BASE_URL + "players/withdraw",
};
