import React, { useState } from "react";
import Breadcrumb from "@components/core-components/breadcrumb";
import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";
import TabsComponent from "@components/core-components/TabsComponent";
import GeneralSettings from "./settings/components/GeneralSettings";
import GeneralSettingsSecondPart from "./settings/components/GeneralSettingsSecondPart";
import SecuritySetting from "./settings/components/SecuritySettings";
import TwoFactorSecurity from "./settings/components/TwoFactorSecurity";
import SessionsTable from "./settings/components/SessionsTable";
import VerifySettings from "./settings/verify/VerifySettings";
import CustomSwitch from "@components/core-components/switch";
import IgnoredUsers from "./settings/ignoredUsers";
import PreferencesSettings from "./settings/components/PreferencesSettings";
import CommunitySettings from "./settings/components/CommunitySettings";
import Marketing from "./settings/components/Marketing";
import OffersSecondPart from "./settings/offers/OffersSecondPart";
import OffersSettings from "./settings/offers";
import FiatNumberFormatting from "./settings/components/FiatNumberFormatting";
import Api from "./settings/components/Api";
import ActiveTokens from "./settings/components/ActiveTokens";
import CustomSelectSecondary from "@components/core-components/input/CustomSelectSecondary";

type Props = {};
interface SelectOption {
  label: string;
  id: string;
  value: string;
}

function Settings({}: Props) {
  const tabs: SelectOption[] = [
    { id: "General", label: "GENERAL", value: "General" },
    { id: "Security", label: "SECURITY", value: "Security" },
    { id: "Sessions", label: "SESSIONS", value: "Sessions" },
    { id: "Preferences", label: "PREFERENCES", value: "Preferences" },
    { id: "API", label: "API", value: "API" },
    { id: "IgnoredUsers", label: "IGNORED USERS", value: "IgnoredUsers" },
    { id: "Verify", label: "VERIFY", value: "Verify" },
    { id: "Offers", label: "OFFERS", value: "Offers" },
  ];
  const [activeTab, setActiveTab] = useState("General");
  const breadcrumbItems = [
    { label: "Profile", href: "/" },
    { label: "Settings", href: "/security" },
    { label: activeTab, href: "" },
  ];

  const renderFirstPart = () => {
    switch (activeTab) {
      case "General":
        return <GeneralSettings />;
      case "Security":
        return <SecuritySetting />;
      case "Sessions":
        return <SessionsTable />;
      case "IgnoredUsers":
        return <IgnoredUsers />;
      case "Verify":
        return <VerifySettings />;
      case "Preferences":
        return <PreferencesSettings />;
      case "Offers":
        return <OffersSettings />;
      case "API":
        return <Api />;
      default:
        return <div>No First Part Content Available</div>;
    }
  };

  const renderRestPart = () => {
    switch (activeTab) {
      case "General":
        return (
          <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
            <GeneralSettingsSecondPart />
          </div>
        );
      case "Security":
        return (
          <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
            <TwoFactorSecurity />
          </div>
        );
      case "Preferences":
        return (
          <>
            <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
              <CommunitySettings />
            </div>
            <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
              <Marketing />
            </div>
            <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
              <FiatNumberFormatting />
            </div>
          </>
        );
      case "Offers":
        return (
          <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
            <OffersSecondPart />
          </div>
        );
      case "API":
        return (
          <div className="flex flex-col h-full justify-center items-start w-full px-3 py-6 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-gray-600 rounded-xl gap-6">
            <ActiveTokens />
          </div>
        );

      default:
        return null;
    }
  };

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = (checked: boolean) => {
    setIsSwitchOn(checked);
    console.log("Switch is now:", checked ? "On" : "Off");
  };

  return (
    <div>
      <div className="relative w-full h-[210px] bg-gray-400">
        <div className="absolute top-0 left-0 w-full h-[4px] bg-gradient-to-r from-[#FFE458] to-[#E1BD00]"></div>
        <div className="absolute top-0 left-0 w-full h-full px-4 md:px-8 pt-6">
          <Breadcrumb
            items={breadcrumbItems}
            className="flex items-start left-0 text-white"
          />
        </div>
      </div>

      <div className="relative flex flex-col -mt-[145px] px-4 sm:px-6 lg:px-8 gap-4">
        <div
          className={`flex flex-col h-full justify-start items-start ${
            activeTab === "Sessions"
              ? "px-0 pb-0 pt-6"
              : "p-3 sm:p-6 lg:px-8 lg:py-6"
          } w-full  bg-gray-600 rounded-xl min-h-[287px]`}
        >
          <div
            className={`flex lg:hidden w-full ${
              activeTab === "Sessions" ? "px-3 md:px-6 lg:px-8" : "px-0"
            }`}
          >
            <CustomSelectSecondary
              options={tabs}
              value={activeTab}
              onChange={(value) => setActiveTab(value)}
              background="bg-gray-300"
              color="text-white"
              iconName="downArrow"
              svgProps={{ width: 16, height: 16 }}
              showLabel={false}
            />
          </div>
          <div
            className={`hidden lg:flex xl:flex-row flex-col h-auto w-full overflow-x-scroll whitespace-nowrap gap-4 main-no-scrollbar::-webkit-scrollbar main-no-scrollbar text-body-reg-13 font-semibold md:text-body-reg-15 md:font-normal`}
          >
            <TabsComponent
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              classNames={`bg-gray-700 rounded-lg ${
                activeTab === "Sessions" ? "mx-8" : "mx-0"
              }  p-2 w-full h-12 text-body-reg-15 font-semibold gap-1 overflow-x-auto overflow-y-hidden main-no-scrollbar::-webkit-scrollbar main-no-scrollbar`}
              buttonWidth={"w-full"}
              activeTabColor="bg-primary"
            />
          </div>
          {renderFirstPart()}
        </div>

        {renderRestPart() && renderRestPart()}
      </div>
    </div>
  );
}

export default Settings;
