import React from "react";

interface RadioCardProps {
  id: string;
  title: string;
  value: string;
  selectedValue: string;
  onChange: (value: string) => void;
  bgColor: string;
}

const RadioCard: React.FC<RadioCardProps> = ({
  title,
  value,
  selectedValue,
  onChange,
  bgColor,
}) => {
  const isChecked = selectedValue === value;

  return (
    <div
      className={`flex justify-between items-center px-8 py-[16px] h-[70px] gap-[18px] shadow-md ${bgColor} cursor-pointer`}
      onClick={() => onChange(value)}
    >
      <div>
        <h3 className="text-white font-semibold text-base">{title}</h3>
      </div>
      <div>
        {isChecked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <circle cx="12" cy="12" r="11" stroke="white" strokeWidth="2" />
            <circle cx="12" cy="12" r="7" fill="white" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <circle cx="12" cy="12" r="11.5" stroke="white" />
          </svg>
        )}
      </div>
    </div>
  );
};

export default RadioCard;
