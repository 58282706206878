import React, { useEffect, useState } from "react";
import Icon from "@components/core-components/icon";
import { useAppSelector } from "hooks/redux";
import { logoutUser } from "store/auth/authenticationSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface MenuListProps {
  menuItems: { [key: string]: string };
  activeCategory: string;
  setActiveCategory: (category: string) => void;
  setActiveTab?: (tab: string | null) => void;
  onMenuItemClick: (item: string) => void;
}

const MenuList: React.FC<MenuListProps> = ({
  menuItems,
  activeCategory,
  setActiveCategory,
  onMenuItemClick,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [isProfileOpen, setIsProfileOpen] = useState(() => {
    return (
      localStorage.getItem("activeCategory") === "Settings" &&
      location.pathname === "/settings"
    );
  });

  const dispatch: any = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState("");

  const handleProfileClick = (profile: string) => {
    setSelectedProfile(profile);
    setActiveCategory(profile);
    setActiveTab && setActiveTab(null);
  };
  useEffect(() => {
    if (location.pathname.includes("settings")) {
      handleProfileClick("Settings");
      setIsProfileOpen(true);
    }
  }, [location.pathname]);

  const handleAccordionToggle = () => {
    setIsProfileOpen((prevState) => !prevState);
  };

  return (
    <div className="mt-4 pb-4 md:border-b border-gray-300">
      {isAuthenticated && (
        <div
          className={`${isProfileOpen ? "bg-gray-600 lg:bg-gray-900" : ""} `}
        >
          <button
            onClick={handleAccordionToggle}
            className={`flex items-center text-start w-full h-[53px] text-sm gap-3 font-semibold px-5 transition-colors duration-200 ${
              activeCategory === "Profile"
                ? "text-yellow-400 bg-gray-600 lg:bg-gray-800 border-l-4 border-yellow-400"
                : "text-gray-200 lg:hover:bg-gray-400"
            }`}
          >
            <div className="w-full flex flex-row items-center justify-between">
              <div className="flex items-center gap-3">
                <Icon
                  iconName="profileIcon"
                  svgProps={{
                    width: 17,
                    height: 17,
                  }}
                />
                <span>Profile</span>
              </div>
              <svg
                className={`h-5 w-5 transition-transform duration-200 ${
                  isProfileOpen ? "rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <Icon
                iconName={isProfileOpen ? "chevronUp" : "chevronDown"}
                svgProps={{
                  width: 12,
                  height: 12,
                }}
              />
            </div>
          </button>
          <div
            className={`pl-0 w-full transition-all duration-300 ease-in-out overflow-hidden`}
            style={{
              maxHeight: isProfileOpen ? "500px" : "0px",
            }}
          >
            {["Settings", "Transactions", "Logout"].map((subItem) => (
              <button
                key={subItem}
                onClick={() => {
                  setSelectedProfile(subItem);
                  onMenuItemClick(subItem);
                  if (subItem === "Logout") {
                    dispatch(logoutUser());
                  } else {
                    navigate(`/${subItem.toLowerCase()}`);
                  }
                }}
                className={`flex items-center px-4 w-full text-start text-sm h-[40px] transition-colors duration-200 ${
                  selectedProfile === subItem
                    ? "text-white bg-gray-900 border-l-4 border-[#FEC602]"
                    : "text-gray-200 hover:bg-gray-400 border-l-4 lg:border-gray-900 border-gray-600"
                }`}
              >
                <Icon
                  className="mr-2"
                  iconName={subItem.toLocaleLowerCase()}
                  svgProps={{ width: 17, height: 17 }}
                />
                {subItem}
              </button>
            ))}
          </div>
        </div>
      )}

      {isAuthenticated && (
        <button
          onClick={() => {
            setIsProfileOpen(false);
            setActiveTab && setActiveTab("none");
            setSelectedProfile("");
            setActiveCategory("My Bets");

            onMenuItemClick("My Bets");
          }}
          className={`flex items-center text-start w-full h-[53px] text-sm gap-3 font-semibold px-5 transition-colors duration-200 ${
            activeCategory === "My Bets"
              ? "text-white bg-gray-900 border-l-4 border-orange"
              : "text-gray-200 hover:bg-gray-400"
          }`}
        >
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex items-center gap-3">
              <Icon
                iconName="myBets"
                svgProps={{
                  width: 17,
                  height: 17,
                }}
              />
              <span style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                My Bets
              </span>
            </div>
            <div className="lg:hidden">
              <Icon
                iconName="rightArrow"
                svgProps={{
                  width: 12,
                  height: 12,
                }}
              />
            </div>
          </div>
        </button>
      )}

      {Object.keys(menuItems).map((item) => (
        <button
          key={item}
          onClick={() => {
            setIsProfileOpen(false);
            setSelectedProfile("");
            setActiveCategory(item);
            onMenuItemClick(item);
          }}
          className={`flex items-center text-start w-full h-[53px] text-sm gap-3 font-semibold px-5 transition-colors duration-200 ${
            activeCategory === item
              ? "text-white bg-gray-900 border-l-4 border-orange"
              : "text-gray-200 hover:bg-gray-400"
          }`}
        >
          <div className="w-full flex flex-row items-center justify-between">
            <div className="flex items-center gap-3">
              <Icon
                iconName={menuItems[item]}
                svgProps={{
                  width: 17,
                  height: 17,
                }}
              />
              <span style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                {item}
              </span>
            </div>
            <div className="lg:hidden">
              <Icon
                iconName="rightArrow"
                svgProps={{
                  width: 12,
                  height: 12,
                }}
              />
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default MenuList;
