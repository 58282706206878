import CategoryList from "@components/core-components/CategoryList";
import FullPageModal from "@components/core-components/modal/FullPageModal";
import React, { useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Categories: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [activeCategory, setActiveCategory] = useState("");
  const categories = {
    Lobby: "lobby",
    Slots: "slots",
    "Live Casino": "liveCasino",
    "Game Shows": "gameShows",
    "Crash Games": "crashGames",
    "New Release": "release",
  };

  const screenHeight = window.innerHeight - 180;

  return (
    <FullPageModal title="CATEGORIES" isOpen={isOpen} onClose={onClose}>
      <div
        className="w-full overflow-x-hidden main-no-scrollbar"
        style={{ height: screenHeight }}
      >
        <CategoryList
          categories={categories}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      </div>
    </FullPageModal>
  );
};

export default Categories;
