import Icon from "@components/core-components/icon";
import Aviator from "../../../../assets/images/aviator.png";
import EmojiInput from "./EmojiInput";
import { useEffect, useRef, useState } from "react";

interface ChatComponentProps {
  onClose?: () => void;
}

const messages = [
  {
    id: 1,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 2,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 3,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 4,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 5,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 6,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 7,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 8,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 9,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 10,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },

  {
    id: 11,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 12,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 13,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 14,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
  {
    id: 15,
    name: "Alskin",
    message:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum vestibulum ut auctor. ",
    avatar: Aviator,
  },
];

const onlineCount = 44999;

const ChatComponent: React.FC<ChatComponentProps> = ({ onClose }) => {
  const messageListRef = useRef<HTMLUListElement>(null);
  const [messageListHeight, setMessageListHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const headerHeight = 83;
      const footerHeight = 133;
      const newHeight = window.innerHeight - headerHeight - footerHeight;
      setMessageListHeight(newHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="bg-gray-600 h-[calc(100vh-140px)] lg:h-[calc(100vh-122px)] flex flex-col overflow-y-hidden">
      {/* Header */}
      <div className="h-[83px] lg:h-12 flex flex-row items-center justify-between gap-2 py-5 px-4 bg-gray-500 border-b border-gray-300">
        <div className="flex flex-col items-start gap-1">
          <span className="block lg:hidden text-white font-semibold text-base">
            Pixup Chat
          </span>
          <div className="flex flex-row gap-2">
            <Icon iconName="onlinedot" svgProps={{ width: 7, height: 7 }} />
            <span className="font-normal text-body-reg-13 text-[#CCD6F4]">
              Online: {onlineCount}
            </span>
          </div>
        </div>
        <div className="block lg:hidden">
          <Icon
            iconName="closeCircle"
            svgProps={{ height: 32, width: 32 }}
            onClick={onClose}
          />
        </div>
      </div>
      {/* Messages List */}
      <ul
        ref={messageListRef}
        style={{ height: messageListHeight }}
        className="flex-1 w-full px-4 gap-1 flex flex-col overflow-y-auto mt-3 mb-3"
      >
        {messages.map((item) => (
          <li
            key={item.id}
            className="bg-gray-400 w-full rounded-lg flex flex-row items-start py-3 px-3 gap-2"
          >
            <div className="flex justify-center items-center">
              <img
                loading="lazy"
                className="w-7 h-4 rounded-full object-fill"
                src={item.avatar}
              />
            </div>
            <span className="text-white font-normal text-body-reg-13">
              <span className="text-gray-100 font-bold text-body-reg-13">
                {item.name}:
              </span>
              {item.message}
            </span>
          </li>
        ))}
      </ul>
      {/* Footer */}
      <div className="h-auto w-full bg-green flex flex-col justify-start border-gray-300 border-t gap-2 p-4">
        <div className="h-[50px] w-full flex flex-row items-center gap-4">
          <EmojiInput />
          <button className="h-[50px] w-[74px] bg-yellow border-[2px] border-[#E1BD00] flex items-center justify-center rounded-md text-body-med-14 font-semibold text-[#2C3555]">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
