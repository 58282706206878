import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = () => {
  const [phone, setPhone] = useState("");

  return (
    <div className="custom-phone-input flex items-center  w-full">
      <div className="flag-selector max-w-[404px] w-full">
        <PhoneInput
          country={"tr"}
          value={phone}
          onChange={(value) => setPhone(value)}
          enableSearch={true}
          containerStyle={{ width: "100%" }}
          inputStyle={{
            width: "calc(100% - 60px)",
            height: "50px",
            borderRadius: "6px",
            border: "1px solid #ccc",
            paddingLeft: "10px",
          }}
          buttonStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            marginRight: "10px",
            width: "50px",
          }}
          dropdownStyle={{
            zIndex: 1000,
            border: "1px solid #ccc",
            borderRadius: "8px",
          }}
        />
      </div>
    </div>
  );
};

export default CustomPhoneInput;
