import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch } from "../../hooks/redux";
import { registerUser } from "store/auth/authenticationSlice";
import CustomButton from "@components/core-components/button/CustomButton";
import Icon from "@components/core-components/icon";
import Textfield from "@components/core-components/input/Textfield";

interface RegisterFormProps {
  onSuccess: (email: string) => void;
}

const registerSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must include lower case letter")
    .matches(/[A-Z]/, "Password must include upper case letter")
    .matches(/\d/, "Password must include at least one number")
    .required("Password is required"),
});

const RegisterForm: React.FC<RegisterFormProps> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const [registerError, setRegisterError] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const res = await dispatch(registerUser(data)).unwrap();

      onSuccess(data.email);
      reset();
    } catch (error) {
      if (typeof error === "string") {
        setRegisterError(error);
      } else {
        setRegisterError("Error during registration");
      }
    }
  };

  const password = watch("password", "");

  const validationChecks = {
    hasLowerCase: /[a-z]/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /\d/.test(password),
    minLength: password.length >= 8,
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <Textfield
        type="email"
        placeholder="Email*"
        register={register}
        watch={watch}
        id="email"
        error={errors.email}
      />
      <Textfield
        watch={watch}
        type="text"
        placeholder="Username*"
        register={register}
        id="username"
        error={errors.username}
      />
      <Textfield
        type="password"
        placeholder="Password*"
        register={register}
        watch={watch}
        id="password"
        error={errors.password}
      />
      {registerError && <p className="text-red-500">{registerError}</p>}

      <div className="text-sm text-gray-200 space-y-1">
        <div className="flex flex-row items-center gap-3">
          <Icon
            iconName={
              validationChecks.hasLowerCase && validationChecks.hasUpperCase
                ? "checkSuccess"
                : "check"
            }
            svgProps={{ height: 16, width: 16 }}
          />
          <p>Includes lower and upper case</p>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Icon
            iconName={validationChecks.hasNumber ? "checkSuccess" : "check"}
            svgProps={{ height: 16, width: 16 }}
          />
          <p>At least 1 number</p>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Icon
            iconName={validationChecks.minLength ? "checkSuccess" : "check"}
            svgProps={{ height: 16, width: 16 }}
          />
          <p>Minimum 8 characters</p>
        </div>
      </div>
      {/* <div className="text-sm text-gray-200 space-y-1">
        <div className="flex flex-row items-center gap-3">
          <Icon iconName="checkSuccess" svgProps={{ height: 16, width: 16 }} />
          <p>Includes lower and upper case</p>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Icon iconName="check" svgProps={{ height: 16, width: 16 }} />
          <p>At least 1 number</p>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Icon iconName="check" svgProps={{ height: 16, width: 16 }} />
          <p>Minimum 8 characters</p>
        </div>
      </div> */}
      <CustomButton text="REGISTER" type="submit" />
    </form>
  );
};

export default RegisterForm;
