import CustomButton from "@components/core-components/button/CustomButton";
import Icon from "@components/core-components/icon";
import cricket from "../../../../assets/images/exchange-card-player.avif";
import purpleCricket from "../../../../assets/images/purple-cricket-player-background 1-2.png";
import Girl from "../../../../assets/images/girl-two.avif";
import soccerPlayer from "../../../../assets/images/sportsbook-card-player.avif";

interface CampaignPanelProps {
  title: string;
  imgName: string;
  borderGradient: string;
  bgGradient: string;
}

function CategoryPanels({
  title,
  imgName,
  borderGradient,
  bgGradient,
}: CampaignPanelProps) {
  const getImage = (imgName: string) => {
    switch (imgName) {
      case "casino":
        return Girl;
      case "cricket":
        return cricket;
      case "football":
        return soccerPlayer;
      case "exchange":
        return purpleCricket;
      default:
        return "";
    }
  };

  const getIconName = (imgName: string) => {
    switch (imgName) {
      case "casino":
        return "purpleCategory";
      case "cricket":
        return "cricketIcon";
      case "football":
        return "blueCategory";
      case "exchange":
        return "greenCategory";
      default:
        return "defaultIcon";
    }
  };
  return (
    <>
      <div
        style={{
          border: `2px solid ${borderGradient} `,
        }}
        className={`h-[64px] border-2 md:h-[160px] flex flex-col md:flex-row items-center md:items-end w-full ${bgGradient} md:py-5 md:px-3 rounded-xl relative`}
      >
        <div className="absolute md:block hidden leading-normal items-center justify-center pointer-events-none overflow-hidden bottom-0 left-0">
          <Icon
            iconName="vectorTriple"
            svgProps={{ width: 190, height: 120 }}
          />
        </div>
        <div className="block md:hidden absolute -top-4 left-1/2 transform -translate-x-1/2">
          <Icon
            iconName={getIconName(imgName)}
            svgProps={{ width: 42, height: 37 }}
          />
        </div>
        <div className="flex-grow md:flex-grow-0 md:w-full flex flex-col justify-end">
          <h3 className="font-black my-1  text-white text-base md:text-3xl relative flex justify-center md:justify-start items-end w-full">
            <span
              className="absolute font-semibold text-white -top-1.5 md:-top-2.5 left-0 opacity-30 text-sm md:text-lg "
              aria-hidden="true"
            >
              {title}
            </span>
            {title}
          </h3>
        </div>
        <div
          className={`absolute bottom-0 hidden md:block right-0 z-0 h-auto w-[131px]`}
        >
          <img
            src={getImage(imgName)}
            alt={imgName}
            className="w-full h-auto"
          />
        </div>
      </div>
    </>
  );
}

export default CategoryPanels;
