import React from "react";
import Icon from "@components/core-components/icon";

interface QuestionItemProps {
  question: string;
  answer: string;
  isActive: boolean;
  onClick: () => void;
}

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  answer,
  isActive,
  onClick,
}) => {
  return (
    <div className="bg-gray-600 rounded-lg mb-2 px-6 py-4 md:px-10 md:py-6 text-white">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={onClick}
      >
        <span className="font-bold text-sm md:text-lg text-left">
          {question}
        </span>
        <div className="transition-transform duration-500 ease-in-out">
          {isActive ? (
            <Icon
              iconName="dropClose"
              svgProps={{ width: 28, height: 28 }}
              className="transform rotate-180 opacity-100"
            />
          ) : (
            <Icon
              iconName="dropOpen"
              svgProps={{ width: 28, height: 28 }}
              className="transform rotate-0 opacity-75"
            />
          )}
        </div>
      </div>
      <div
        className={`transition-[max-height] duration-700 ease-in-out overflow-hidden ${
          isActive ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        <p className="mt-4 text-sm md:text-base text-gray-200">{answer}</p>
      </div>
    </div>
  );
};

export default QuestionItem;
