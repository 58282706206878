import React, { useState } from "react";
import CustomButton from "@components/core-components/button/CustomButton";
import Icon from "@components/core-components/icon";
import VerifyLevel1 from "./VerifyLevelOne";
import VerifyLevel2 from "./VerifyLevelTwo";
import VerifyLevel3 from "./VerifyLevelThree";
import VerifyLevel4 from "./VerifyLevelFour";

function VerifySettings() {
  const [currentLevel, setCurrentLevel] = useState(1);

  const levels = [
    { level: 1, completed: false },
    { level: 2, completed: currentLevel > 1 },
    { level: 3, completed: currentLevel > 2 },
    { level: 4, completed: currentLevel > 3 },
  ];

  const handleSubmit = () => {
    if (currentLevel < 4) {
      setCurrentLevel(currentLevel + 1);
    }
  };

  const handleLevelClick = (level: number) => {
    if (level <= currentLevel) {
      setCurrentLevel(level);
    }
  };

  return (
    <div className="w-full flex flex-col w-200">
      {/* Level Progress Bar */}
      <div className="flex items-center gap-2 justify-center w-full pt-[34px]">
        {levels.map((item, index) => (
          <React.Fragment key={item.level}>
            <div
              className="flex flex-col items-center cursor-pointer min-w-8"
              onClick={() => handleLevelClick(item.level)}
            >
              <div
                className={`flex items-center justify-center w-[24px] h-[24px] rounded-full ${
                  item.level < currentLevel
                    ? "bg-green-500"
                    : item.level === currentLevel
                    ? "bg-yellow"
                    : "bg-gray-100"
                }`}
              >
                <Icon
                  iconName={
                    item.level < currentLevel
                      ? "tickWhite"
                      : item.level === currentLevel
                      ? "clockGray"
                      : "clockWhite"
                  }
                  svgProps={{ width: 14, height: 14 }}
                  className={
                    item.level < currentLevel || item.level === currentLevel
                      ? "text-white"
                      : "text-gray-400"
                  }
                />
              </div>
              <p
                className={`mt-2 text-sm font-medium ${
                  item.level < currentLevel
                    ? "text-green-500"
                    : item.level === currentLevel
                    ? "text-yellow"
                    : "text-white"
                }`}
              >
                {`Level ${item.level}`}
              </p>
            </div>
            {index < levels.length - 1 && (
              <div
                className={`w-10 sm:w-[100px] h-[4px] rounded-full -mt-7 ${
                  item.level < currentLevel
                    ? "bg-green-500"
                    : item.level === currentLevel
                    ? "bg-gradient-to-r from-yellow to-gray-100"
                    : "bg-gray-100"
                }`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="h-[1px] w-full bg-gray-400 my-6"></div>

      {currentLevel === 1 && <VerifyLevel1 />}
      {currentLevel === 2 && <VerifyLevel2 />}
      {currentLevel === 3 && <VerifyLevel3 />}
      {currentLevel === 4 && <VerifyLevel4 />}

      <div
        className={`flex justify-end mt-6 ${
          currentLevel === 1 ? "max-w-full" : "lg:max-w-[739px]"
        }`}
      >
        <CustomButton
          classname="max-w-[175px]"
          text="Submit"
          onClick={handleSubmit}
          buttonType="primary"
        />
      </div>
    </div>
  );
}

export default VerifySettings;
