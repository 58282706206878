import React, { useEffect, useState } from "react";

function useIsLandscape() {
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useEffect(() => {
    const updateOrientation = () => {
      const isMobileDevice = window.innerWidth <= 824;
      if (isMobileDevice) {
        setIsLandscape(window.innerWidth > window.innerHeight);
      } else {
        setIsLandscape(false);
      }
    };

    // Check orientation on mount
    updateOrientation();

    // Add event listeners
    window.addEventListener("resize", updateOrientation);
    window.addEventListener("orientationchange", updateOrientation);

    return () => {
      // Clean up event listeners
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return isLandscape;
}

export default useIsLandscape;
