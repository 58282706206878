import FullPageModal from "@components/core-components/modal/FullPageModal";
import React from "react";
import ChatComponent from "../../components/ChatComponent";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "hooks/redux";
import { setActiveTab, toggleModal } from "store/menu/menuSlice";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  mt?: string;
}

const Chat: React.FC<ModalProps> = ({ isOpen, onClose, mt }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onCloseChat = () => {
    console.log("onCloseChat");
    if (location.pathname.includes("lounge")) {
      dispatch(setActiveTab("casino"));
      dispatch(toggleModal("chat"));
    } else {
      dispatch(toggleModal("chat"));
      dispatch(setActiveTab(""));
    }
  };

  return (
    <FullPageModal showTitle={false} isOpen={isOpen} onClose={onClose} mt={mt}>
      <ChatComponent onClose={onCloseChat} />
    </FullPageModal>
  );
};

export default Chat;
