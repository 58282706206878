import React from "react";
import Icon from "@components/core-components/icon";
import CustomButton from "@components/core-components/button/CustomButton";

const UploadSection = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="flex flex-col items-center bg-gray-400 rounded-md border-dashed border-2 border-gray-200 p-6">
    <Icon iconName="fileUpload" svgProps={{ width: 45, height: 45 }} />
    <p className="text-white text-base font-semibold mt-2">{title}</p>
    <p className="text-gray-200 text-sm font-normal">{description}</p>
    <CustomButton
      classname="mt-4 max-w-[175px]"
      text={title}
      buttonType="secondary"
      onClick={() => {}}
    />
  </div>
);

export default UploadSection;
