import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryList from "@components/core-components/CategoryList";
import MenuList from "@components/core-components/MenuList";
import { sanitizeUrl } from "helper/SanitizeUrl";
import useCheckUrl from "helper/useCheckUrl";
import ExchangeCategoryList from "@components/core-components/ExchangeCategoryList";

function LeftMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>(
    localStorage.getItem("activeTab") || "none"
  );
  const [activeCategory, setActiveCategory] = useState<string>(
    localStorage.getItem("activeCategory") || "none"
  );
  const isLoungeScreen = useCheckUrl(location.pathname, "lounge");
  const isExchangeScreen = useCheckUrl(location.pathname, "exchange");
  const [categories, setCategories] = useState<{ [key: string]: string }>({});
  const [dropdownButtons, setDropdownButtons] = useState<{
    [key: string]: { text1: string; text2: string; onClick: () => void }[];
  }>({});
  const [menuItems, setMenuItems] = useState<{ [key: string]: string }>({
    Promotions: "promotionIcon",
    Affiliate: "affiliate",
    "VIP Club": "vipClub",
    Blog: "blog",
    Forum: "forum",
    Sponsorship: "sponsorship",
    "Responsible Gambling": "responsibleGambling",
    "Live Support": "customerSupport",
  });

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveTab(null);
      setActiveCategory("Lobby");
      setCategories({});
    } else if (location.pathname.includes("/lounge")) {
      setActiveTab("Casino");
      setActiveCategory("Lobby");
      setCategories({
        Lobby: "lobby",
        Slots: "slots",
        "Live Casino": "liveCasino",
        "Game Shows": "gameShows",
        "Crash Games": "crashGames",
        "New Release": "release",
      });
    } else if (location.pathname.includes("/sportbook")) {
      setActiveTab("Sportbook");
      setCategories({
        test: "lobby",
        tests: "slots",
        "Live Casino": "liveCasino",
        "Game Shows": "gameShows",
        "Crash Games": "crashGames",
        "New Release": "release",
      });
    } else if (location.pathname.includes("/exchange")) {
      setActiveTab("Exchange");
      setCategories({
        Cricket: "cricket",
        Tennis: "tennis",
        Football: "football",
        Jackpot: "jackpot",
        "Cricket casino": "cricketCasino",
        Kabaddi: "kabaddi",
      });
      setDropdownButtons({
        Cricket: [
          {
            text1: "Guyana Amazon Warriors",
            text2: "Lahore Qalandars",
            onClick: () => console.log("Guyana Amazon Warriors clicked"),
          },
          {
            text1: "Lahore Qalandars",
            text2: "Guyana Amazon Warriors",
            onClick: () => console.log("Lahore Qalandars clicked"),
          },
        ],
        Tennis: [
          {
            text1: "Player 1",
            text2: "Player 2",
            onClick: () => console.log("Player 1 clicked"),
          },
          {
            text1: "Player 2",
            text2: "Player 1",
            onClick: () => console.log("Player 2 clicked"),
          },
        ],
        Football: [
          {
            text1: "Team A",
            text2: "Team B",
            onClick: () => console.log("Team A clicked"),
          },
          {
            text1: "Team B",
            text2: "Team A",
            onClick: () => console.log("Team B clicked"),
          },
        ],
        Jackpot: [
          {
            text1: "Jackpot 1",
            text2: "Jackpot 2",
            onClick: () => console.log("Jackpot 1 clicked"),
          },
          {
            text1: "Jackpot 2",
            text2: "Jackpot 1",
            onClick: () => console.log("Jackpot 2 clicked"),
          },
        ],
        "Cricket casino": [
          {
            text1: "Casino 1",
            text2: "Casino 2",
            onClick: () => console.log("Casino 1 clicked"),
          },
          {
            text1: "Casino 2",
            text2: "Casino 1",
            onClick: () => console.log("Casino 2 clicked"),
          },
        ],
        Kabaddi: [
          {
            text1: "Team X",
            text2: "Team Y",
            onClick: () => console.log("Team X clicked"),
          },
          {
            text1: "Team Y",
            text2: "Team X",
            onClick: () => console.log("Team Y clicked"),
          },
        ],
      });
    } else {
      setCategories({});
    }
  }, [location.pathname]);

  const handleTabClick = (tab: string, mainTab?: boolean) => {
    if (!mainTab) {
      localStorage.setItem("activeCategory", tab || "none");
    }

    const cleanedUrl = sanitizeUrl(tab);
    setActiveTab(tab);
    if (cleanedUrl === "casino") {
      navigate("/lounge");
    } else {
      navigate("/" + cleanedUrl);
    }
  };

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab || "none");
  }, [activeTab, activeCategory]);

  return (
    <aside className="bg-gray-600 pb-96 space-y-4 w-[190px] h-screen overflow-x-hidden main-no-scrollbar">
      <div className="flex flex-col space-y-2 p-4 border-b border-gray-300">
        {["Casino", "Sportbook", "Exchange"].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab, true)}
            disabled={tab === "Sportbook"}
            className={`h-[42px] text-left font-semibold text-sm px-4 rounded-lg transition-colors duration-200 ${
              activeTab === tab
                ? tab === "Exchange"
                  ? "bg-gradient-to-r from-[#20D910] to-[#180729] text-white border border-gray-400"
                  : "bg-gradient-to-r from-[#D410D9] to-[#180729] text-white border border-[#F20AFF]"
                : "bg-gray-700 text-white border border-gray-400 hover:bg-gray-400"
            } ${tab === "Sportbook" ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {tab}
          </button>
        ))}
      </div>

      {isLoungeScreen && (
        <CategoryList
          categories={categories}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      )}

      {isExchangeScreen && (
        <ExchangeCategoryList
          categories={categories}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          dropdownButtons={dropdownButtons}
        />
      )}

      <MenuList
        setActiveTab={setActiveTab}
        menuItems={menuItems}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        onMenuItemClick={handleTabClick}
      />
    </aside>
  );
}

export default LeftMenu;
