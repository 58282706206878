import React, { useState } from "react";

const EmojiInput: React.FC = () => {
  const [inputValue, setInputValue] = useState("");

  return (
    <div className="h-[50px] relative w-full">
      <div className="h-full w-full">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type a message"
          className="border p-2 rounded w-full h-full relative text-sm text-gray-900"
        />
      </div>
    </div>
  );
};

export default EmojiInput;
