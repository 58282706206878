import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useRef, useState } from "react";
import Icon from "../../../../components/core-components/icon";

interface CarouselProps<T> {
  items: T[];
  renderItem: (item: T) => JSX.Element;
}

const Carousel = <T,>({ items, renderItem }: CarouselProps<T>) => {
  const swiperRef = useRef<any>(null);

  return (
    <div className="relative">
      <Swiper
        ref={swiperRef}
        slidesPerView="auto"
        spaceBetween={10}
        autoplay={{ delay: 2000 }}
        style={{ height: "200px" }}
        loop
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index}
            className="h-[100px] w-[350px] md:w-[450px] "
          >
            {renderItem(item)}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation Button */}
      <button
        className="custom-next absolute top-[40%] right-0 cursor-pointer h-10 w-10 z-10 justify-center items-center bg-gray-600 border border-gray-600 rounded-lg text-sm hidden md:flex"
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        <Icon
          iconName="carouselRightIcon"
          svgProps={{ height: 15, width: 18 }}
        />
      </button>
    </div>
  );
};

export default Carousel;
