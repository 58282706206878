import React, { useEffect, useState } from "react";
import Breadcrumb from "@components/core-components/breadcrumb";
import ResponsibleGambling from "../../../assets/images/desktop-resp-gaming.avif";
import ResponsibleGamblingMd from "../../../assets/images/tablet-resp-gaming.avif";
import QuestionItem from "@components/QuestionItem";

function ResponsibleGamings() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [backgroundImage, setBackgroundImage] = useState(ResponsibleGambling);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 1024) {
      setBackgroundImage(ResponsibleGambling);
    } else if (screenWidth >= 640 && screenWidth <= 1024) {
      setBackgroundImage(ResponsibleGamblingMd);
    } else if (screenWidth < 640) {
      setBackgroundImage(ResponsibleGamblingMd);
    }
  }, [screenWidth]);

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Responsible Gambling", href: "/responsible-gambling" },
  ];

  const questionsData = [
    {
      question: "Pixup Smart",
      answer:
        "Leading the online gambling industry since 2017, Pixup.com offers a wide variety of online casino and sports betting options, operating globally in 15 different languages. With a reputable and secure platform, Pixup Casino is home to worldwide local currencies and crypto betting options for online slot games, Pixup Originals and live casino games. Pixup Sportsbook offers unbeatable odds on all major sporting events including a range of eSport leagues. We host regular bet bonuses and promotions and offer an exclusive VIP Club  experience - all with a simple-to-use deposit process on our licensed platform.",
    },
    {
      question: "Responsible Gambling FAQ's",
      answer:
        "Pixup is committed to providing a safe and responsible gambling environment for all our players. We have put together a list of frequently asked questions to help you understand our responsible gambling policies and procedures.",
    },
    {
      question: "Recognise The Signs of Gambling Dependency?",
      answer:
        "Gambling dependency can have a serious impact on your life and the lives of those around you. It is important to recognise the signs of gambling dependency so that you can seek help if you need it. Some of the signs of gambling dependency include:",
    },
    {
      question: "Self-Assessment",
      answer:
        "If you are concerned about your gambling habits, you can take a self-assessment test to help you determine whether you may have a gambling problem. The self-assessment test will ask you a series of questions about your gambling habits and behaviours, and will provide you with a score that indicates whether you may be at risk of developing a gambling problem.",
    },
    {
      question: "Self Exclusion",
      answer:
        "If you feel that you need to take a break from gambling, you can use our self-exclusion feature to block yourself from accessing your account for a set period of time. During this time, you will not be able to access your account or place any bets, and you will not receive any marketing communications from us.",
    },
    {
      question: "Gambling Limits",
      answer:
        "You can set gambling limits on your account to help you control your gambling habits. You can set deposit limits, loss limits, wagering limits, session limits, and time limits to help you manage your gambling activity. Once you have reached your limit, you will not be able to place any further bets until the limit resets.",
    },
    {
      question: "Budget Calculator",
      answer:
        "Our budget calculator tool can help you manage your gambling budget by providing you with an overview of your gambling activity and expenditure. You can use the budget calculator to set a budget for your gambling activity and track your spending to ensure that you are gambling responsibly.",
    },
  ];

  return (
    <div className="mt-2 md:mt-3 lg:pt-6 text-white">
      <div className="hidden md:block px-8">
        <Breadcrumb items={breadcrumbItems} />
      </div>
      <section className="relative flex flex-col 2xl:flex-row items-start 2xl:items-center justify-between mt-0 md:mt-[35px] lg:mt-6">
        <div className="flex-shrink-0 relative w-full">
          <img
            src={backgroundImage}
            style={{
              mixBlendMode: "lighten",
              opacity: 0.9,
            }}
            alt="Ufc Fighters"
            className="w-full h-auto min-h-[171px] max-h-[302px] lg:max-h-[628px] relative z-10 object-cover"
          />
        </div>
      </section>
      <section className="px-4 items-center justify-center text-center max-w-[1219px] mx-auto mb-14">
        <div className="flex flex-col">
          <span className="text-2xl md:text-heading-50 2xl:text-7xl font-black mb-3">
            RESPONSIBLE GAMBLING
          </span>
          <p className="text-gray-100 text-sm md:text-lg">
            “Pixup.com has been a great partner the past years, and we’re
            thrilled to continue the relationship,” - UFC Vice President of
            Global Partnerships Nick Smith. “With sports betting becoming more
            widely accessible, we have added Brazil, a very important emerging
            gaming market, to a far-reaching collaboration.”
          </p>
        </div>
      </section>
      <section className="px-4 items-center justify-center text-left max-w-[773px] mx-auto">
        {questionsData.map((item, index) => (
          <QuestionItem
            key={index}
            question={item.question}
            answer={item.answer}
            isActive={activeIndex === index}
            onClick={() => setActiveIndex(activeIndex === index ? null : index)}
          />
        ))}
      </section>
    </div>
  );
}

export default ResponsibleGamings;
