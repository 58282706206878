import TabsComponent from "../../../../components/core-components/TabsComponent";
import React, { useState } from "react";
import Icon from "../../../../components/core-components/icon";
import DesktopTable from "@components/core-components/table/DesktopTable";
import CustomSelect from "@components/core-components/input/CustomSelect";

interface CasinoTableProps {
  showHeader?: boolean;
}

const CasinoTable: React.FC<CasinoTableProps> = ({ showHeader = true }) => {
  const [activeTab, setActiveTab] = useState("allbets");
  const [ghostModeEnabled, setGhostModeEnabled] = useState(false);
  const [selectedRowValue, setSelectedRowValue] = useState<string>("10");

  const rowValue = [
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
    { label: "30", value: "30" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
  ];

  const columnDefs: any[] = [
    {
      headerName: "GAME",
      field: "game",
      cellClass: "flex flex-1",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row items-center text-white font-semibold text-body-reg-15">
            {params.value}
          </div>
        );
      },
    },
    {
      headerName: "USER",
      field: "user",
      cellClass: "flex flex-1",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row items-center text-white font-semibold text-body-reg-15">
            {params.value}
          </div>
        );
      },
    },
    {
      headerName: "TIME",
      field: "time",
      cellClass: "flex flex-1",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row items-center text-white font-semibold text-body-reg-15">
            {params.value}
          </div>
        );
      },
    },
    {
      headerName: "BET AMOUNT",
      field: "betAmount",
      cellClass: "flex flex-1",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row items-center text-white font-semibold text-body-reg-15">
            {params.value}
            <Icon iconName="usdt2" className="ml-3 mt-[2px]" />
          </div>
        );
      },
    },
    {
      headerName: "MULTIPLIER",
      field: "multiplier",
      cellClass: "flex flex-1",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row items-center text-white font-semibold text-body-reg-15">
            {params.value}
          </div>
        );
      },
    },
    {
      headerName: "PAYOUT",
      field: "payout",
      cellClass: "flex flex-1",
      flex: 1,
      cellRenderer: (params: { value: string }) => {
        return (
          <div className="flex flex-row items-center text-white font-semibold text-body-reg-15">
            {params.value}
            <Icon iconName="usdt2" className="ml-3 mt-[2px]" />
          </div>
        );
      },
    },
  ];

  const rowData = [
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Mines",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
    {
      game: "Big Bass BOOM",
      user: "Hidden",
      time: "2:54 PM",
      betAmount: "20.46271936",
      multiplier: "1.65x",
      payout: "-20.46271936",
    },
  ];
  const tabs = [
    { id: "allbets", label: "ALL BETS" },
    { id: "mybets", label: "MY BETS" },
    { id: "rollers", label: "HIGH ROLLERS" },
    { id: "race", label: "RACE LEADERBOARD" },
  ];

  return (
    <div className="h-full flex flex-col justify-start bg-[#1D243E] items-center rounded-xl">
      {showHeader && (
        <div className="h-12 mt-6 px-8 mb-8 flex flex-row items-center justify-between w-full gap-8">
          <TabsComponent
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            classNames="bg-[#12182B] h-full rounded-lg px-2 overflow-x-auto overflow-y-hidden gap-1 main-no-scrollbar::-webkit-scrollbar main-no-scrollbar"
            buttonWidth="min-w-[170px]"
          />
          <div className="h-full flex flex-row ">
            <button
              className="h-full bg-[#2C3555] w-[185px] rounded-lg px-5 py-[14px] mr-4 flex flex-row justify-center items-center text-body-reg-14 font-semibold text-white"
              onClick={() => setGhostModeEnabled(!ghostModeEnabled)}
            >
              <Icon iconName="ghost" className="mt-1 mr-4" /> Ghost Mode{" "}
              {ghostModeEnabled ? "On" : "Off"}
            </button>
            <div className="w-auto main-no-scrollbar::-webkit-scrollbar main-no-scrollbar">
              <CustomSelect
                options={rowValue}
                value={selectedRowValue}
                onChange={(value) => setSelectedRowValue(value)}
                showIcon={false}
                showSearch={false}
              />
            </div>
          </div>
        </div>
      )}
      <DesktopTable data={rowData} columnDefs={columnDefs} />
    </div>
  );
};

export default CasinoTable;
