import React from "react";
import Icon from "@components/core-components/icon";

interface IRowData {
  game: string;
  user?: string;
  time?: string;
  betId?: string;
  date?: string;
  betAmount: string;
  multiplier: string;
  payout: string;
}

interface ColumnDef {
  headerName: string;
  field: keyof IRowData;
  cellClass: string;
  flex: number;
  cellRenderer?: (params: { value: string }) => JSX.Element;
}

interface DesktopTableProps {
  data: IRowData[];
  columnDefs: ColumnDef[];
}

const DesktopTable: React.FC<DesktopTableProps> = ({ data, columnDefs }) => {
  const headers = columnDefs.map((colDef) => colDef.headerName);

  const scrollRef = React.createRef<HTMLUListElement>();
  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const target = e.currentTarget;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      target.scrollTop !== 0
    ) {
      e.stopPropagation();
    }
  };

  return (
    <div className="h-full flex flex-col justify-start bg-gray-600 items-center rounded-b-xl w-full">
      <div className="bg-gray-500 rounded-b-xl pb-4 w-full">
        <div className="flex flex-col w-full bg-gray-600">
          <div className="flex flex-row justify-between gap-4 w-full px-8 py-3">
            {headers.map((header, index) => (
              <div key={index} className={`flex flex-row items-center flex-1`}>
                <p className="text-body-reg-14 font-black text-gray-200">
                  {header}
                </p>
              </div>
            ))}
          </div>
          <ul
            ref={scrollRef}
            onScroll={handleScroll}
            className="max-h-[552px] overflow-y-auto rounded-b-x .main-no-scrollbar::-webkit-scrollbar main-no-scrollbar"
          >
            {data.map((game, index) => (
              <li
                key={index}
                className={`flex flex-col shadow-md px-8 py-3 w-full odd:bg-gray-400 even:bg-gray-500`}
              >
                <div className="flex flex-row justify-between gap-4 w-full cursor-pointer">
                  {columnDefs.map((colDef, colIndex) => (
                    <div
                      key={colIndex}
                      className={`flex flex-row gap-[10px] items-center ${colDef.cellClass} ${colDef.flex}`}
                    >
                      {colDef.cellRenderer ? (
                        colDef.cellRenderer({
                          value: game[colDef.field] as string,
                        })
                      ) : (
                        <p className="text-body-reg-15 font-normal text-gray-100 truncate">
                          {game[colDef.field]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DesktopTable;
