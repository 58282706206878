import CustomButton from "@components/core-components/button/CustomButton";
import Icon from "@components/core-components/icon";
import woman from "../../../../assets/images/girl.avif";
import football from "../../../../assets/images/footballer.png";
import exchange from "../../../../assets/images/exchange-players.avif";
import cricket from "../../../../assets/images/exchange-card-player.avif";

interface CampaignPanelProps {
  title: string;
  subtitle: string;
  imgName: string;
  vectorName: string;
}
function CampaignPanel({
  title,
  subtitle,
  imgName,
  vectorName,
}: CampaignPanelProps) {
  const getImage = (imgName: string) => {
    switch (imgName) {
      case "casino":
        return woman;
      case "cricket":
        return cricket;
      case "football":
        return football;
      case "exchange":
        return exchange;
      default:
        return woman;
    }
  };
  return (
    <div className=" relative w-[350px] md:w-[450px] h-[180px] py-[22px] px-[24px] flex-shrink-0 rounded-lg bg-gray-500 shadow-md flex items-start justify-start">
      <div>
        <p className="text-primary">Campaign!</p>
        <h3 className="font-black  my-1 text-white text-lg md:text-2xl lg:text-3xl">
          {title}
        </h3>
        <p className="text-sm text-white font-normal md:text-base ">
          {subtitle}
        </p>
        <CustomButton
          text="Learn More"
          classname="max-w-[150px] p-2.5 my-2.5"
          buttonType="secondary"
        />
      </div>
      <div className="absolute top-0 right-0">
        <Icon
          svgProps={{
            width: 173,
            height: 136,
          }}
          iconName={vectorName}
        />
      </div>
      <div
        className={`absolute pr-0 bottom-0 right-0 max-w-full  ${
          imgName === "cricket" ? "w-[180px]" : "w-[220px]"
        } h-[179px]`}
      >
        <img src={getImage(imgName)} alt={imgName} />
      </div>
    </div>
  );
}

export default CampaignPanel;
