import MenuList from "@components/core-components/MenuList";
import React, { useState } from "react";
import SearchBar from "@components/core-components/input/SearchBar";
import { useNavigate } from "react-router-dom";
import FullPageModal from "@components/core-components/modal/FullPageModal";
import { sanitizeUrl } from "helper/SanitizeUrl";
import SearchModal from "pages/private/casino/components/SearchModal";
import Icon from "@components/core-components/icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExchangeMenu: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState("");
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  const menuItems = {
    Promotions: "promotionIcon",
    Affiliate: "affiliate",
    "VIP Club": "vipClub",
    Blog: "blog",
    Forum: "forum",
    Sponsorship: "sponsorship",
    "Responsible Gambling": "responsibleGambling",
  };

  const handleTabClick = (tab: string) => {
    const cleanedUrl = sanitizeUrl(tab);
    if (cleanedUrl === "Casino") {
      navigate("/lounge");
    } else {
      navigate(`/${cleanedUrl}`);
    }
  };

  return (
    <FullPageModal title="MENU" isOpen={isOpen} onClose={onClose}>
      <div className="w-full h-full pt-3 overflow-x-hidden main-no-scrollbar">
        <div className="flex w-full items-center justify-between px-3">
          <SearchBar
            // onClick={handleOpenSearchModal}
            onClick={() => {}}
            value=""
            onChange={() => {}}
            preventFocus={true}
            bgColor="bg-gray-500"
            borderColor="border-gray-300"
          />
        </div>
        <SearchModal
          isOpen={isSearchModalVisible}
          onClose={() => setSearchModalVisible(false)}
        />
        <div className="pt-8 w-full px-3 pb-6 border-b border-gray-300">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-white text-base font-black">Sports</h2>
            <button className="text-xs font-black">View All</button>
          </div>
          <div className="grid grid-cols-4 md:grid-cols-4 gap-1">
            {[
              { label: "In play", iconName: "inPlaySquare" },
              { label: "Cricket", iconName: "cricketSquare" },
              { label: "Tennis", iconName: "tennisSquare" },
              { label: "Football", iconName: "footballSquare" },
              { label: "Jackpot", iconName: "jackpotSquare" },
              { label: "Kabaddi", iconName: "kabaddiSquare" },
              { label: "Cricket Casino", iconName: "cricketCasinoSquare" },
            ].map((item, index) => (
              <button
                key={index}
                className="flex flex-col items-center justify-center bg-gray-500 py-2 px-1 h-20 min-w-[83px] rounded-md text-gray-100"
              >
                <Icon
                  iconName={item.iconName}
                  svgProps={{
                    width: 28,
                    height: 28,
                  }}
                />
                <span className="mt-2 text-body-reg-13">{item.label}</span>
              </button>
            ))}
          </div>
        </div>
        <MenuList
          menuItems={menuItems}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          onMenuItemClick={handleTabClick}
        />
      </div>
    </FullPageModal>
  );
};

export default ExchangeMenu;
