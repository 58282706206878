import React, { useState } from "react";
import FavoriteGameCards from "@components/core-components/FavoriteGameCards";
import FullPageModal from "@components/core-components/modal/FullPageModal";
import { useSelector } from "react-redux";
import { casinoGames } from "store/casino/casinoSlice";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Favorites: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const games = useSelector(casinoGames);
  const favoritesGames = games.slice(6, 11);

  const [favoriteIds, setFavoriteIds] = useState<string[]>(
    favoritesGames.map((game) => game.game_id)
  );

  const toggleFavorite = (id: string) => {
    if (favoriteIds.includes(id)) {
      setFavoriteIds(favoriteIds.filter((gameId) => gameId !== id));
    } else {
      setFavoriteIds([...favoriteIds, id]);
    }
  };

  const screenHeight = window.innerHeight - 180;

  return (
    <FullPageModal title="FAVORITES" isOpen={isOpen} onClose={onClose}>
      <div
        className="w-full px-3 pt-6 overflow-x-hidden main-no-scrollbar"
        style={{ height: screenHeight }}
      >
        <div className="space-y-2">
          {favoritesGames.map((game) => (
            <FavoriteGameCards
              key={game.game_id}
              game={game}
              isFavorite={favoriteIds.includes(game.game_id)}
              toggleFavorite={toggleFavorite}
              isFavoriteIconShown={true}
            />
          ))}
        </div>
      </div>
    </FullPageModal>
  );
};

export default Favorites;
