import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "@components/layout-components/Header";
import HeaderMobile from "@components/layout-components/HeaderMobile";
import MobileBottomTabs from "@components/layout-components/MobileBottomTabs";
import { useAppSelector } from "hooks/redux";
import AuthModal from "@components/core-components/AuthModal";
import LeftMenu from "@components/layout-components/LeftMenu";
import Footer from "@components/layout-components/Footer";
import useCheckUrl from "helper/useCheckUrl";
import RightMenu from "@components/layout-components/RightMenu";
import Icon from "@components/core-components/icon";

function PublicLayout() {
  const { activeTab: menuTab } = useAppSelector((state) => state.menu);

  const getButtonPosition = () => {
    if (!isRightMenuVisible) return "right-0";
    return activeTab === "chat" ? "right-[370px]" : "right-[270px]";
  };
  const [activeTab, setActiveTab] = useState("lastPlays");
  const [isRightMenuVisible, setIsRightMenuVisible] = useState(true);
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  useEffect(() => {
    const handleResize2 = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize2);
    return () => {
      window.removeEventListener("resize", handleResize2);
    };
  }, [isLandscape]);
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const toggleRightMenu = () => {
    setIsRightMenuVisible((prev) => !prev);
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const location = useLocation();
  const {
    layoutSettings: { mobileDepositOpened, mobileGameStarted },
  } = useAppSelector((state) => state.casino);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isGameScreen = useCheckUrl(location.pathname, "game");

  const shouldHideLeftMenu = () => {
    if (isMobile) return false;
    return true;
  };
  const isPlay = useCheckUrl(location.pathname, "play");
  const shouldShowHeaderMobile = () => {
    if (menuTab === "chat") return false;
    if (isPlay) return false;
    if (isGameScreen) return false;
    if (mobileDepositOpened) return false;
    if (mobileGameStarted) return false;

    return true;
  };

  const shouldShowMobileBottomTabs = () => {
    if (isMobile) {
      if (mobileDepositOpened) return false;

      return true;
    }
    return false;
  };

  return (
    <div className="flex-grow flex flex-col relative h-full overflow-hidden">
      {isMobile ? (
        shouldShowHeaderMobile() ? (
          <HeaderMobile />
        ) : null
      ) : (
        <Header />
      )}

      <div
        // className={`flex ${
        //   isMobile || isLandscape
        //     ? mobileDepositOpened || isGameScreen || menuTab === "chat"
        //       ? "mt-[60px]"
        //       : "mt-[60px] "
        //     : "mt-[60px]"
        // } h-full w-screen relative   `}
        className={`flex h-full w-screen relative ${
          (isMobile && isGameScreen) || menuTab === "chat"
            ? "mt-[0px]"
            : "mt-[60px]"
        } `}
      >
        <AuthModal />
        {shouldHideLeftMenu() && <LeftMenu />}
        <main
          className={`flex-grow flex-1 bg-gray-700 h-screen middle:h-full w-full overflow-x-hidden main-no-scrollbar main-no-scrollbar::-webkit-scrollbar ${
            isAuthenticated && isMobile && !location.pathname.includes("game")
              ? "mt-14"
              : ""
          }`}
        >
          <Outlet />
          <Footer />
        </main>
        {isAuthenticated && (
          <>
            {isMobile ? null : (
              <>
                <button
                  className={`absolute bg-gray-600 border-gray-400 border border-r-0 text-white mt-4 px-2 py-1 rounded-l-lg z-10 ${getButtonPosition()}`}
                  onClick={toggleRightMenu}
                >
                  {isRightMenuVisible ? (
                    <Icon
                      iconName="rightArrow"
                      svgProps={{ width: 10, height: 16 }}
                    />
                  ) : (
                    <Icon
                      iconName="rightArrow"
                      svgProps={{ width: 10, height: 16 }}
                      className="transition transform rotate-180"
                    />
                  )}
                </button>
                <div
                  className={`transition-transform duration-500 ${
                    isRightMenuVisible ? "translate-x-0" : "translate-x-full"
                  }`}
                >
                  {isRightMenuVisible && (
                    <RightMenu
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {shouldShowMobileBottomTabs() && (
        <MobileBottomTabs isLandscape={isLandscape} isMobile={isMobile} />
      )}
    </div>
  );
}

export default PublicLayout;
