import React from "react";
import MatchCard from "../../components/betCardItems/MatchCard";
interface BetInterface {
  id: number;
  type: "back" | "lay";
  odds: number;
  amount: number;
  bookable: boolean;
  index: number;
  title: string;
  isSuspended?: boolean;
}
export const mockData: BetInterface[] = [
  {
    id: 1,
    type: "back",
    odds: 2.5,
    title: "Team A",
    amount: 150,
    bookable: true,
    index: 1,
    isSuspended: false,
  },
  {
    id: 2,
    type: "lay",
    odds: 1.8,
    amount: 120,
    index: 0,
    bookable: true,
    isSuspended: true,
    title: "Team B",
  },
];
export default function GameInformation() {
  const mockData2: BetInterface[] = [
    {
      id: 1,
      type: "back",
      odds: 2.5,
      title: "Team A",
      amount: 150,
      bookable: false,
      index: 1,
      isSuspended: false,
    },
    {
      id: 2,
      type: "lay",
      odds: 1.8,
      amount: 120,
      index: 0,
      bookable: false,
      isSuspended: true,
      title: "Team B",
    },
    {
      id: 3,
      type: "lay",
      odds: 1.5,
      amount: 100,
      index: 0,
      bookable: false,
      isSuspended: false,
      title: "Team B",
    },
    {
      id: 4,
      type: "back",
      odds: 0.5,
      title: "Team A",
      amount: 150,
      bookable: false,
      index: 1,
      isSuspended: false,
    },
    {
      id: 5,
      type: "lay",
      odds: 1.5,
      amount: 100,
      index: 0,
      bookable: false,
      isSuspended: false,
      title: "Team B",
    },
    {
      id: 6,
      type: "back",
      odds: 0.5,
      title: "Team A",
      amount: 150,
      bookable: false,
      index: 1,
      isSuspended: false,
    },
  ];
  return (
    <>
      {" "}
      <MatchCard
        headerTitleSet={["No", "Yes"]}
        title="Match Odds"
        betData={mockData}
        likeable
      />
      <MatchCard
        headerTitleSet={["Back", "Lay"]}
        title="Match Odds"
        betData={mockData}
        likeable={false}
      />
      <MatchCard
        headerTitleSet={["Back", "Lay"]}
        title="Match Odds"
        betData={mockData2}
        likeable={false}
        multipleSets
      />
    </>
  );
}
