import React, { useState } from "react";
import Icon from "../icon";

interface AdjustableValueInputProps {
  title: string;
  initialValue?: number;
  step?: number;
  borderColor?: string;
  buttonsBgColor?: string;
}

const AdjustableValueInput: React.FC<AdjustableValueInputProps> = ({
  title,
  initialValue = 0,
  step = 1,
  borderColor = "border-back-bet",
  buttonsBgColor = "bg-back-bet",
}) => {
  const [value, setValue] = useState(initialValue);
  const increment = () => setValue(value + step);
  const decrement = () => setValue(value - step);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue)) {
      setValue(newValue);
    }
  };

  return (
    <div className="flex flex-col items-start w-full">
      <span className="text-gray-200 text-base font-normal">{title}</span>
      <div
        className={`hidden lg:flex items-center mt-1 rounded-[4px] overflow-hidden ${borderColor}`}
      >
        <input
          type="text"
          value={value}
          onChange={handleInputChange}
          className={`text-center bg-transparent text-white text-sm w-16 h-12 py-3 p-1 border-2 border-r-0 ${borderColor} rounded-l-[4px] focus:outline-none`}
        />
        <div className="flex flex-col">
          <button
            onClick={increment}
            className={`w-11 ${buttonsBgColor} flex items-center justify-center text-white`}
          >
            <Icon
              iconName="plusForButton"
              svgProps={{ width: 24, height: 24 }}
            />
          </button>
          <button
            onClick={decrement}
            className={`w-11 ${buttonsBgColor} flex items-center justify-center text-white`}
          >
            <Icon
              iconName="minusForButton"
              svgProps={{ width: 24, height: 24 }}
            />
          </button>
        </div>
      </div>

      <div
        className={`flex lg:hidden flex-row w-full items-center mt-1 rounded-[4px] h-12 overflow-hidden border-2 ${borderColor}`}
      >
        <button
          onClick={decrement}
          className={`flex items-center justify-center text-white ${buttonsBgColor} w-11 h-12 border-none`}
        >
          <Icon
            iconName="minusForButton"
            svgProps={{ width: 24, height: 24 }}
          />
        </button>
        <input
          type="number"
          value={value}
          onChange={handleInputChange}
          className={`text-center bg-transparent text-white text-base w-full h-12 py-2 px-1 focus:outline-none rounded-none`}
        />
        <button
          onClick={increment}
          className={`flex items-center justify-center text-white ${buttonsBgColor} w-11 h-12 border-none`}
        >
          <Icon iconName="plusForButton" svgProps={{ width: 24, height: 24 }} />
        </button>
      </div>
    </div>
  );
};

export default AdjustableValueInput;
