import CustomButton from "@components/core-components/button/CustomButton";
import Textfield from "@components/core-components/input/Textfield";
import React from "react";

function GeneralSettings() {
  const [email, setEmail] = React.useState("");
  return (
    <div className="w-full flex flex-col">
      <div>
        <p className="pt-6 text-white text-[15px]">EMAIL</p>
        <div className="h-[1px] w-full bg-gray-400 mb-6 mt-4"></div>
        <Textfield
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          className="max-w-[404px]"
          placeholder="Email*"
          id="email"
        />{" "}
        <div className="h-[1px] w-full bg-gray-400 my-6"></div>
      </div>
      <div className="flex justify-end">
        <div className="flex justify-end h-full gap-6 w-1/2">
          <CustomButton
            text="Resend Code"
            classname="max-w-[175px] min-w-28"
            onClick={() => {}}
            buttonType="secondary"
          />
          <CustomButton
            classname="max-w-[175px] min-w-28"
            text="Save"
            onClick={() => {}}
            buttonType="primary"
          />
        </div>
      </div>
    </div>
  );
}

export default GeneralSettings;
