import { Route, Routes } from "react-router-dom";
import LandingExchange from "./pages/Landing";
import GameInformation from "./pages/GameInformation";

function Exchange() {
  return (
    <div className={`px-1 md:px-3 lg:px-6 2xl:px-8 2xl:pt-4 flex flex-col `}>
      exchange
    </div>
  );
}

export default Exchange;
