import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ classNames }: { classNames: string }) => {
  const location = useLocation();

  useEffect(() => {
    const container = document.getElementsByClassName(classNames)[0];
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location, classNames]);

  return null;
};

export default ScrollToTop;
