import React, { useEffect, useRef, useState } from "react";
import CustomSwiper from "@components/core-components/swiper";
import { casinoGames, fetchGames } from "store/casino/casinoSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import TabsComponent from "@components/core-components/TabsComponent";
import GameCard from "@components/core-components/card/GameCard";
import MobileCasinoTable from "../casino/components/MobileCasinoTable";
import CasinoTable from "../casino/components/CasinoTable";
import CategoryPanels from "./components/CategoryPanels";
import PixupVipModal from "./components/PixupVipModal";
import Icon from "@components/core-components/icon";

function LandingPrivate() {
  const games = useAppSelector(casinoGames);
  const [activeTab, setActiveTab] = useState("casino");
  const dispatch = useAppDispatch();
  const [isSearchModalVisible, setSearchModalVisible] =
    useState<boolean>(false);
  const [isVIPModalVisible, setVIPModalVisible] = useState<boolean>(false);

  const handleOpenSearchModal = () =>
    setSearchModalVisible(!isSearchModalVisible);

  const handleOpenVIPModal = () => setVIPModalVisible(true);
  const handleCloseVIPModal = () => setVIPModalVisible(false);

  // useEffect(() => {
  //   dispatch(fetchGames());
  // }, []);

  const [progress, setProgress] = useState(40);
  const progressPoints = [
    { threshold: 0, iconName: "vipProgressGray" },
    { threshold: 20, iconName: "vipProgressBlue" },
    { threshold: 40, iconName: "vipProgressYellow" },
    { threshold: 60, iconName: "vipProgressOrange" },
    { threshold: 80, iconName: "vipProgressPurple" },
    { threshold: 100, iconName: "vipProgressRed" },
  ];

  const tabs = [
    { id: "casino", label: "CASINO" },
    { id: "sports", label: "SPORTS" },
  ];

  const categories = [
    {
      title: "CASINO",
      borderGradient: "#CC10D2",
      imgName: "casino",
      bgGradient: "bg-custom-purple-to-pink",
    },
    {
      title: "SPORTSBOOK",
      imgName: "football",
      borderGradient: "#0A51BD",
      bgGradient: "bg-custom-gradient-blue2",
    },
    {
      title: "EXCHANGE",
      borderGradient: "#09CA1A",
      imgName: "exchange",
      bgGradient: "bg-custom-gradient-green2",
    },
  ];

  return (
    <>
      <div className={`px-3 lg:px-[33px] 2xl:px-8 pt-4 lg:pt-5`}>
        <div className="flex-row flex items-between below-1850:flex-col gap-6  ">
          <div className="relative flex flex-col min-w-[351px] 2xl:min-w-[413px] h-[160px] p-6 flex-shrink-0 rounded-xl bg-gray-600 shadow-md items-start justify-start">
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between w-full">
                <p className="text-white text-base font-semibold">
                  Camadan VIP
                </p>
                <Icon
                  iconName="vipArrowRight"
                  svgProps={{ width: 17, height: 17 }}
                  className="cursor-pointer"
                  onClick={handleOpenVIPModal}
                />
              </div>
              <p className="text-gray-100 text-sm font-normal">
                Earn points as you play and benefit from VIP privileges
              </p>
            </div>
            <div className="w-full h-14 mt-[18px] rounded-xl bg-gray-700 flex items-center px-6">
              <div className="relative w-full h-3 rounded-full bg-white">
                <div
                  className="absolute h-3 rounded-full"
                  style={{
                    width: `${progress}%`,
                    maxWidth: "100%",
                    background:
                      "linear-gradient(90deg, #26325A 0%, #95A8E2 100%)",
                  }}
                ></div>
                <div className="absolute flex justify-between w-full h-full top-0">
                  {progressPoints.map((point, index) => (
                    <div
                      key={index}
                      className="relative -mx-1 -top-1 w-[20px] h-[20px] flex justify-center items-center"
                    >
                      <Icon
                        iconName={point.iconName}
                        svgProps={{ width: 21, height: 17 }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-between flex-grow gap-2 md:gap-6 justify-between">
            {categories.map((category, index) => (
              <CategoryPanels
                borderGradient={category.borderGradient}
                key={index}
                title={category.title}
                imgName={category.imgName}
                bgGradient={category.bgGradient}
              />
            ))}
          </div>
        </div>
        <div className="pt-6  w-full md:px-0">
          <CustomSwiper
            title="Last Played"
            items={games}
            renderItem={(game) => (
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status === "ACTIVE" ? false : true}
                showPlayCount={true}
              />
            )}
          />
        </div>
        <div className="pt-6 md:pt-6 w-full mb-[97px md:px-0">
          <CustomSwiper
            title="Trending Games"
            items={games.slice(7)}
            renderItem={(game) => (
              <GameCard
                image={game.url_thumb}
                id={game.game_id}
                name={game.game_name}
                altText={game.game_name}
                notAvailable={game.status === "ACTIVE" ? false : true}
                showPlayCount={true}
              />
            )}
          />
        </div>

        <p className="text-3xl lg:text-6xl font-black my-8 leading-tight text-white mt-6">
          WHAT THE BETTING ACTION HAPPENING RIGHT NOW
        </p>
        <p className="text-gray-200 text-base font-normal mt-6">
          Check out the live bets on popular casino games and sports to discover
          how much you could win.
        </p>
        <TabsComponent
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          classNames={`bg-[#1D243E] rounded-lg p-2 w-full max-w-[752px] h-12 mt-8 font-black gap-1`}
          buttonWidth={"w-full"}
          activeTabColor="bg-primary"
        />
        <div className="bg-gray-600 rounded-xl mt-11">
          <div className="md:p-4 lg:p-0 pb-0 w-full">
            <div className="block md:hidden">
              <MobileCasinoTable games={[]} showHeader={false} />
            </div>
            <div className="hidden md:block pt-4">
              <CasinoTable showHeader={false} />
            </div>
          </div>
        </div>
      </div>
      {isVIPModalVisible && (
        <PixupVipModal
          isOpen={isVIPModalVisible}
          onClose={handleCloseVIPModal}
        />
      )}
    </>
  );
}

export default LandingPrivate;
