import GatesOfOlympus from "../assets/images/olympos.png";
import SweetBonanza from "../assets/images/sweet-bonanza.png";
import GonzosQuest from "../assets/images/gonzo.png";
import SugarRush from "../assets/images/sugar-rush.png";
import BuffaloPower2 from "../assets/images/buffalo.png";
import EvilEyes from "../assets/images/evil-eyes.png";
import Aviator from "../assets/images/aviator.png";
import BigBassBonanza from "../assets/images/bigbasbonanza.png";
import BloodSuckers from "../assets/images/blood-suckers.png";
import RiseOfAtlantis from "../assets/images/rise-of-atlantis.png";
import MoneyTrain3 from "../assets/images/money-train.png";
import TwinSpin from "../assets/images/twin-spin.png";
import DynamiteMiner from "../assets/images/dynamite-miner.png";
import EnergyCoins from "../assets/images/energy-coins.png";
import RamsesBlitz from "../assets/images/ramses-blitz.png";
import BuildinBucks from "../assets/images/buildin.png";
import PragmaticPlay from "../assets/images/pragmatic-play-logo-white 1.png";
import Evolution from "../assets/images/evolution.png";
import NolimitCity from "../assets/images/nolimit-city.png";
import HacksawGaming from "../assets/images/hacksaw-gaming.png";
import RedTiger from "../assets/images/red-tiger-logo.png";
import RelaxGaming from "../assets/images/relaxgaming.png";
import Octoplay from "../assets/images/Octoplay-logo.png";
import Thunderkick from "../assets/images/thunderkick.png";

const games = [
  {
    id: 1,
    name: "Sweet Bonanza",
    image: SweetBonanza,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 2,
    name: "Gates Of Olympus",
    image: GatesOfOlympus,
    notAvailable: true,
    category: "slots",
  },
  {
    id: 3,
    name: "Gonzos Quest",
    image: GonzosQuest,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 4,
    name: "Sugar Rush",
    image: SugarRush,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 5,
    name: "Buffalo Power 2",
    image: BuffaloPower2,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 6,
    name: "Evil Eyes",
    image: EvilEyes,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 7,
    name: "Aviator",
    image: Aviator,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 8,
    name: "Big Bass Bonanza",
    image: BigBassBonanza,
    notAvailable: true,
    category: "slots",
  },
  {
    id: 9,
    name: "BloodSuckers",
    image: BloodSuckers,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 10,
    name: "Rise Of Atlantis",
    image: RiseOfAtlantis,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 11,
    name: "Money Train 3",
    image: MoneyTrain3,
    notAvailable: true,
    category: "slots",
  },
  {
    id: 12,
    name: "Twin Spin",
    image: TwinSpin,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 13,
    name: "Dynamite Miner",
    image: DynamiteMiner,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 14,
    name: "Energy Coins",
    image: EnergyCoins,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 15,
    name: "Ramses Blitz",
    image: RamsesBlitz,
    notAvailable: false,
    category: "slots",
  },
  {
    id: 16,
    name: "Buildin Bucks",
    image: BuildinBucks,
    notAvailable: false,
    category: "slots",
  },
];

const providers = [
  {
    name: "Pragmatic Play",
    logo: PragmaticPlay,
  },
  {
    name: "Evolution",
    logo: Evolution,
  },
  {
    name: "Nolimit City",
    logo: NolimitCity,
  },
  {
    name: "Hacksaw Gaming",
    logo: HacksawGaming,
  },
  {
    name: "Red Tiger",
    logo: RedTiger,
  },
  {
    name: "Relax Gaming",
    logo: RelaxGaming,
  },
  {
    name: "Octoplay",
    logo: Octoplay,
  },
  {
    name: "Thunderkick",
    logo: Thunderkick,
  },
  {
    name: "Pragmatic Play",
    logo: PragmaticPlay,
  },
  {
    name: "Evolution",
    logo: Evolution,
  },
  {
    name: "Nolimit City",
    logo: NolimitCity,
  },
  {
    name: "Hacksaw Gaming",
    logo: HacksawGaming,
  },
  {
    name: "Red Tiger",
    logo: RedTiger,
  },
  {
    name: "Relax Gaming",
    logo: RelaxGaming,
  },
  {
    name: "Octoplay",
    logo: Octoplay,
  },
  {
    name: "Thunderkick",
    logo: Thunderkick,
  },
  {
    name: "Pragmatic Play",
    logo: PragmaticPlay,
  },
  {
    name: "Evolution",
    logo: Evolution,
  },
  {
    name: "Nolimit City",
    logo: NolimitCity,
  },
  {
    name: "Hacksaw Gaming",
    logo: HacksawGaming,
  },
  {
    name: "Red Tiger",
    logo: RedTiger,
  },
  {
    name: "Relax Gaming",
    logo: RelaxGaming,
  },
  {
    name: "Octoplay",
    logo: Octoplay,
  },
  {
    name: "Thunderkick",
    logo: Thunderkick,
  },
];

export { games, providers };
