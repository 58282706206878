import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { baseService } from "./baseService";

interface BaseQueryProps {
  endpoint: string;
  queryKey?: QueryKey;
  enabled?: boolean;
  options?: UseQueryOptions;
}

export const useBaseQuery = <T>({
  endpoint,
  queryKey,
  enabled = true,
  options,
}: BaseQueryProps) => {
  if (!queryKey) {
    queryKey = [endpoint];
  }

  return useQuery<T>({
    queryKey: queryKey,
    enabled,
    queryFn: async () => {
      try {
        var result = await baseService.getAll<T>(endpoint);
        return result;
      } catch (error: any) {
        console.log("useBaseQuery " + endpoint, error);
        throw new Error(error);
      }
    },
  });
};
