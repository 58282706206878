import React, { useState } from "react";

import Icon from "@components/core-components/icon";
import MatchCardItem from "./MatchCardItem";

interface MatchCardItemProps {
  title: string;
  betData: any[];
  headerTitleSet?: [string, string];
  multipleSets?: boolean;
  likeable?: boolean;
}
export default function MatchCard({
  likeable = false,
  title,
  headerTitleSet,
  multipleSets = false,
  betData,
}: MatchCardItemProps) {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const toggleRowExpansion = (title: string) => {
    setExpandedRow(expandedRow === title ? null : title);
  };

  return (
    <div className="bg-gray-500 p-2 rounded-lg">
      {/* Header Section */}
      <div
        onClick={() => toggleRowExpansion(title)}
        className="flex cursor-pointer items-center bg-gray-700 md:bg-gray-500 rounded-tr-lg rounded-tl-lg justify-between p-0 px-2  md:p-2 pl-[10px] gap-[18px]"
      >
        {" "}
        <div className="flex items-center p-2 pl-[10px] gap-[18px]">
          {" "}
          {likeable ? (
            <Icon iconName="favorite" />
          ) : (
            <div className="w-[24px]"></div>
          )}
          <p className="text-xl text-white ">{title}</p>
        </div>
        <div className=" flex md:hidden gap-2">
          <Icon
            iconName="dollarIcon"
            svgProps={{
              width: "15px",
              height: "15px",
            }}
          />
          {!likeable && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 3.5C5.68629 3.5 3 6.18629 3 9.5C3 12.8137 5.68629 15.5 9 15.5C12.3137 15.5 15 12.8137 15 9.5C15 6.18629 12.3137 3.5 9 3.5ZM1.5 9.5C1.5 5.35786 4.85786 2 9 2C13.1421 2 16.5 5.35786 16.5 9.5C16.5 13.6421 13.1421 17 9 17C4.85786 17 1.5 13.6421 1.5 9.5ZM9 8C9.41423 8 9.75 8.33577 9.75 8.75V13.25C9.75 13.6642 9.41423 14 9 14C8.58577 14 8.25 13.6642 8.25 13.25V8.75C8.25 8.33577 8.58577 8 9 8ZM9 7.25C9.6213 7.25 10.125 6.74632 10.125 6.125C10.125 5.50368 9.6213 5 9 5C8.3787 5 7.875 5.50368 7.875 6.125C7.875 6.74632 8.3787 7.25 9 7.25Z"
                fill="#8B9CCF"
              />
            </svg>
          )}
          <Icon
            iconName="downArrow"
            svgProps={{ width: 14, height: 18 }}
            className={`transform transition-transform duration-200 ${
              expandedRow === title ? "rotate-180" : ""
            }`}
          />
        </div>
        <Icon
          iconName="downArrow"
          svgProps={{ width: 14, height: 18 }}
          className={`transform transition-transform hidden md:block duration-200 ${
            expandedRow === title ? "rotate-180" : ""
          }`}
        />
      </div>
      {expandedRow === title && (
        <>
          {" "}
          <div className="flex justify-end items-center px-[10px] py-1 mt-0  md:mt-[20px] rounded text-sm font-semibold text-gray-300 bg-gray-600">
            <div
              className={`flex justify-end w-full  ${
                likeable
                  ? !multipleSets && "mr-[35px] md:mr-[167px] lg:mr-[274px] "
                  : multipleSets
                  ? " mr-[36%] md:mr-[148px] lg:mr-[237px] hidden md:flex"
                  : " mr-[30px] md:mr-[140px] lg:mr-[248px]"
              } gap-2`}
            >
              {headerTitleSet && (
                <>
                  {headerTitleSet.map((item) => {
                    return (
                      <>
                        <div className="flex flex-col items-center">
                          <span>{item}</span>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
            <div className="flex hidden md:flex gap-2">
              <Icon
                iconName="dollarIcon"
                svgProps={{
                  width: "15px",
                  height: "15px",
                }}
              />
              {!likeable && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 3.5C5.68629 3.5 3 6.18629 3 9.5C3 12.8137 5.68629 15.5 9 15.5C12.3137 15.5 15 12.8137 15 9.5C15 6.18629 12.3137 3.5 9 3.5ZM1.5 9.5C1.5 5.35786 4.85786 2 9 2C13.1421 2 16.5 5.35786 16.5 9.5C16.5 13.6421 13.1421 17 9 17C4.85786 17 1.5 13.6421 1.5 9.5ZM9 8C9.41423 8 9.75 8.33577 9.75 8.75V13.25C9.75 13.6642 9.41423 14 9 14C8.58577 14 8.25 13.6642 8.25 13.25V8.75C8.25 8.33577 8.58577 8 9 8ZM9 7.25C9.6213 7.25 10.125 6.74632 10.125 6.125C10.125 5.50368 9.6213 5 9 5C8.3787 5 7.875 5.50368 7.875 6.125C7.875 6.74632 8.3787 7.25 9 7.25Z"
                    fill="#8B9CCF"
                  />
                </svg>
              )}
            </div>
          </div>
          {/* Content Section */}
          <div className="flex flex-col gap-2 ">
            {betData.map((bet, index) => (
              <MatchCardItem
                key={bet.id}
                multipleSets={multipleSets}
                title={bet.title}
                actionShow={likeable}
                withHeader={index === 0}
                bookable={bet.bookable}
                betData={betData}
                odds={bet.index}
                isSuspended={bet.isSuspended}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
